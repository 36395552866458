import { Button, FormControl, FormControlLabel, FormLabel, MenuItem } from '@mui/material';
import { DenseInput, Pulse } from '../utils';
import { FormStyles, IOSSwitch } from './';
import React, { useEffect, useState } from 'react';

import { Header } from '../';
import { SwitchDeviceNotification } from '../../../api/api';
import { useNotification } from '../../../contexts/ContextProvider';

const Configurations = ({ profileDetail, setUpdateProfile, updateProfile }) => {
    const notify = useNotification();
    const [deviceStatusNotification, setDeviceStatusNotification] = useState();
    // const [open, setOpen] = useState(false);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const [notificationType, setNotificationType] = useState('0');

    const handleChange = (event) => {
        setNotificationType(event.target.value);
        console.log('Selected Notification Type:', event.target.value);
    };

    // const handleClose = () => {
    //     // setDeviceStatusNotification(false);
    //     setOpen(false);
    //     setStartTime();
    //     setEndTime();
    // };
    useEffect(() => {
        setDeviceStatusNotification(profileDetail?.device_online_status_notification === 1 ? true : false);
        if (profileDetail?.device_online_status_notification === 1) {
            setNotificationType(profileDetail?.device_status_notification_option);
            setStartTime(profileDetail?.device_online_status_notification_start_hour);
            setEndTime(profileDetail?.device_online_status_notification_end_hour);
        }
    }, [profileDetail]);

    const handleSave = () => {
        console.log('Configurations => notificationType:', notificationType);
        console.log('Configurations => startTime:', startTime);
        console.log('Configurations => endTime:', endTime);

        handleChangeStatus(
            deviceStatusNotification,
            notificationType,
            parseInt(startTime),
            parseInt(endTime),
        );
        // setOpen(false);
        // setStartTime(profileDetail?.);
        // setEndTime();
    };

    console.log('Configurations => deviceStatusNotification:', deviceStatusNotification);
    const handleSwitchChange = (event) => {
        if (event.target.checked) {
            setDeviceStatusNotification(event.target.checked);
            console.log('handleSwitchChange => event.target.checked:', event.target.checked);
            // handleClickOpen();
        } else {
            handleChangeStatus(0);
        }
    };

    console.log('Configurations => profileDetail:', profileDetail);

    const handleChangeStatus = async (statusCode, notificationType, startHour, endHours) => {
        const { code, msg } = await SwitchDeviceNotification(
            statusCode,
            notificationType,
            startHour,
            endHours,
        );
        console.log('handleChangeStatus => code:', code);
        console.log('handleChangeStatus => msg:', msg);

        if (code === 0) {
            setUpdateProfile(!updateProfile);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: `Device status notification Changed!`,
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Device status notification switched unsuccessfully!',
                },
            });
        }
    };

    const notificationOptions = [
        { value: '0', label: 'Notify on both online and offline status' },
        { value: '1', label: 'Notify only when online' },
        { value: '2', label: 'Notify only when offline' },
    ];

    const handleStartTimeChange = (e) => {
        const value = e.target.value;
        if (value >= 0 && value <= 23) {
            setStartTime(value);
        }
    };

    const handleEndTimeChange = (e) => {
        const value = e.target.value;
        if (value >= 0 && value <= 23) {
            setEndTime(value);
        }
    };
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            {profileDetail ? (
                <>
                    <Header title="Configurations" />
                    <div className="mx-4">
                        <FormControl sx={FormStyles.formControlStyle}>
                            <FormLabel id="current_pwd" sx={FormStyles.labelText}>
                                Device Status Notification
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={deviceStatusNotification}
                                        onChange={handleSwitchChange}
                                    />
                                }
                            />
                        </FormControl>

                        {deviceStatusNotification && (
                            <from>
                                <FormControl sx={FormStyles.formControlStyle}>
                                    <FormLabel id="username" sx={FormStyles.labelText}>
                                        Notification type:
                                    </FormLabel>
                                    <DenseInput
                                        select
                                        id="notification-type"
                                        value={notificationType}
                                        onChange={handleChange}
                                    >
                                        {notificationOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </DenseInput>
                                </FormControl>
                                <FormControl sx={FormStyles.formControlStyle}>
                                    <FormLabel id="username" sx={FormStyles.labelText}>
                                        Start time:
                                    </FormLabel>{' '}
                                    <DenseInput
                                        required
                                        // sx={{ marginTop: '8px' }}
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        placeholder="Start time (0-23)"
                                        name="startTime"
                                        value={startTime}
                                        onChange={(e) => handleStartTimeChange(e)}
                                        inputProps={{ min: 0, max: 23 }}
                                    />
                                </FormControl>
                                <FormControl sx={FormStyles.formControlStyle}>
                                    <FormLabel id="username" sx={FormStyles.labelText}>
                                        End time:
                                    </FormLabel>
                                    <DenseInput
                                        required
                                        // sx={{ marginTop: '8px' }}
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        placeholder="End Time (0-23)"
                                        name="endTime"
                                        value={endTime}
                                        onChange={(e) => handleEndTimeChange(e)}
                                        inputProps={{ min: 0, max: 23 }}
                                    />
                                </FormControl>
                                <div className="flex w-full justify-center mt-3">
                                    <Button variant="contained" onClick={handleSave}>
                                        save
                                    </Button>
                                </div>
                            </from>
                        )}
                    </div>
                </>
            ) : (
                <Pulse />
            )}
        </div>
    );
};

export default Configurations;
