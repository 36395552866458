import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {
    Breadcrumbs,
    Button,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Link,
    MenuItem,
    Select,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from '@mui/material';
import { Doughnut, Line } from 'react-chartjs-2';
import { GetCampaignList, GetCount, GetDeviceList } from '../api/api';
import React, { useEffect, useState } from 'react';

import { Contract } from '../components/dashboard/accounting';
import { FiMonitor } from 'react-icons/fi';
import { Header } from '../components/dashboard';
import { MdCampaign } from 'react-icons/md';
import { useStateContext } from '../contexts/ContextProvider';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, Tooltip, Legend);
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export const pieData = {
    labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
        {
            // label: '# of Votes',
            data: [12, 19, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Line Chart',
        // },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const data = {
    labels,
    datasets: [
        {
            label: 'Data ',
            data: labels.map(() => generateRandomNumber(-1000, 1000)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        // {
        //     label: 'Dataset 2',
        //     data: labels.map(() => generateRandomNumber(-1000, 1000)),
        //     borderColor: 'rgb(53, 162, 235)',
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // },
    ],
};

// import { TbTypography } from 'react-icons/tb';
const Dashboard = () => {
    const { currentUser } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const [activeScreen, setActiveScreen] = useState();
    const [nonActiveScreen, setNonActiveScreen] = useState();
    const getDeviceList = async () => {
        const data = await GetDeviceList(switchUser);
        console.log('getDeviceList => data:', data);

        setActiveScreen(data?.data?.assign_device_list.length);
        setNonActiveScreen(data?.data?.no_assign_device_list.length);
    };
    const [campaignList, setCampaignList] = useState([]);
    console.log('campaignList', campaignList);
    const FetchCampaignList = async () => {
        const { code, data } = await GetCampaignList(switchUser, undefined, 50);
        console.log('data:', data);
        const list = data?.list;
        if (code === 0) {
            Array.isArray(list) ? setCampaignList(list) : setCampaignList([]);
        }
    };
    useEffect(() => {
        getDeviceList();
        FetchCampaignList();
    }, []);

    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            <Header title={`Dashboard`} />
            <div className="w-full flex flex-col p-2">
                <div className="mb-4 grid grid-cols-4 gap-4">
                    <div className="bg-white rounded-lg h-28 p-4 flex items-center">
                        <div className="flex items-center bg-blue-100 px-2 rounded-full h-[40px] w-[40px]">
                            <FiMonitor size={25} color="rgb(30 64 175)" />
                        </div>
                        <div className="px-4">
                            <Typography variant="h6" sx={{ fontWeight: 900 }}>
                                {activeScreen + nonActiveScreen}
                            </Typography>
                            <Typography variant="body2">screens</Typography>
                        </div>
                    </div>
                    <div className="bg-white rounded-lg h-28 p-4 flex items-center">
                        <div className="flex items-center bg-green-100 px-2 rounded-full h-[40px] w-[40px]">
                            <FiMonitor size={25} color="rgb(22 101 52)" />
                        </div>
                        <div className="pl-4">
                            <Typography variant="h6" sx={{ fontWeight: 900 }}>
                                {activeScreen}
                            </Typography>
                            <Typography variant="body2">active screens</Typography>
                        </div>
                    </div>
                    <div className="bg-white rounded-lg h-28 p-4 flex items-center">
                        <div className="flex items-center bg-red-100 px-2 rounded-full h-[40px] w-[40px]">
                            <FiMonitor size={25} color="rgb(153 27 27)" />
                        </div>
                        <div className="px-4">
                            <Typography variant="h6" sx={{ fontWeight: 900 }}>
                                {nonActiveScreen}
                            </Typography>
                            <Typography variant="body2">Non active</Typography>
                        </div>
                    </div>
                    <div className="bg-white rounded-lg h-28 p-4 flex items-center">
                        <div className="flex items-center bg-indigo-100 px-2 rounded-full h-[40px] w-[40px]">
                            <MdCampaign size={25} color="rgb(67 56 202)" />
                        </div>
                        <div className="px-4">
                            <Typography variant="h6" sx={{ fontWeight: 900 }}>
                                {campaignList?.length}
                            </Typography>
                            <Typography variant="body2">Campaign(s)</Typography>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg mb-4  ">
                    <Contract />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
