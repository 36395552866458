import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { UpScale_Result, UpScale_image } from './Api'; // Assuming you have this API function

import AWS from 'aws-sdk';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import config from '../../../config/config';
import { useDropzone } from 'react-dropzone';

const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp'];

const user_id = JSON.parse(localStorage.getItem('userDetails'))?.user_id;
const ImageUpscale = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [s3URL, setS3URL] = useState('');
    const [outputFormat, setOutputFormat] = useState('png');
    const [prompt, setPrompt] = useState(null);
    const [negativePrompt, setNegativePrompt] = useState(null);
    const [creativity, setCreativity] = useState(0.35);
    const [processing, setProcessing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [resultImageURL, setResultImageURL] = useState('');
    const [error, setError] = useState('');
    const [imageInfo, setImageInfo] = useState({ width: 0, height: 0, size: 0 });

    const [job_id, setjob_id] = useState('');

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setUploading(true);
            try {
                const s3url = await uploadToS3(file);
                setS3URL(s3url);
                const imageUrl = URL.createObjectURL(file);
                setSelectedImage(imageUrl);

                const img = new Image();
                img.onload = () => {
                    const { width, height } = img;
                    const size = (file.size / (1024 * 1024)).toFixed(2); // size in MB

                    // Validation rules
                    if (width < 64 || height < 64) {
                        setError('Each side of the image must be at least 64 pixels.');
                        setUploading(false);
                        return;
                    }
                    const totalPixels = width * height;
                    if (totalPixels < 4096 || totalPixels > 9437184) {
                        setError('Total pixel count must be between 4,096 and 9,437,184 pixels.');
                        setUploading(false);
                        return;
                    }
                    const aspectRatio = width / height;
                    if (aspectRatio < 1 / 2.5 || aspectRatio > 2.5) {
                        setError('The aspect ratio must be between 1:2.5 and 2.5:1.');
                        setUploading(false);
                        return;
                    }

                    setImageInfo({ width, height, size });
                    setError(''); // Clear any previous error
                };
                img.src = imageUrl;
            } catch (err) {
                console.error('Error uploading file to S3', err);
                setError('Error uploading file. Please try again.');
            } finally {
                setUploading(false);
            }
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: allowedFileTypes.join(','),
        maxFiles: 1,
    });

    const uploadToS3 = async (file) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;
        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;
        const ACCESS_KEY = config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId;
        const SECRET_KEY = config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey;

        AWS.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_KEY,
            region: REGION,
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: S3_BUCKET,
            Key: user_id + '/upscaled_images/' + file.name,
            Body: file,
            ContentType: file.type,
        };

        return new Promise((resolve, reject) => {
            s3.upload(params, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data.Location);
            });
        });
    };

    const handleUpscale = async () => {
        if (!s3URL) {
            setError('No image uploaded.');
            return;
        }

        setProcessing(true);
        setError('');
        try {
            const body = {
                user_id: user_id,
                s3url: s3URL,
                prompt: prompt,
                negative_prompt: negativePrompt,
                creativity: creativity,
                output_format: outputFormat,
            };

            console.log('body', body);

            const data = await UpScale_image(body);

            console.log('data', data);

            const jobId = data.message;
            setjob_id(jobId);

            const sleep = (ms) => {
                return new Promise((resolve) => setTimeout(resolve, ms));
            };
            // Adding a delay of 5 seconds
            await sleep(15000); // 5000 milliseconds = 5 seconds

            // Polling for result
            const result = await pollForResult(jobId);
            setResultImageURL(result.message);
        } catch (err) {
            console.error('Error upscaling image', err);
            setError('Error upscaling image. Please try again.');
        } finally {
            setProcessing(false);
        }
    };

    const pollForResult = async (jobId) => {
        const maxAttempts = 5; // Maximum number of polling attempts
        const delay = 5000; // Delay between polling attempts (5 seconds)
        const result_body = { job_id: jobId, user_id: user_id };

        console.log('result_body', result_body);

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            try {
                const result = await UpScale_Result(result_body);
                if (result.statusCode === 200) {
                    return result;
                } else if (result.statusCode === 202) {
                    // Job is still processing
                    console.log(`Attempt ${attempt}: Job is still processing...`);
                } else {
                    throw new Error(`Unexpected status code: ${result.statusCode}`);
                }
            } catch (err) {
                console.error(`Attempt ${attempt}: Error fetching result - ${err.message}`);
            }

            // Wait before the next attempt
            await new Promise((resolve) => setTimeout(resolve, delay));
        }

        throw new Error('Timeout: Failed to get the upscale result within the maximum number of attempts.');
    };

    const handleClear = () => {
        setSelectedImage(null);
        setS3URL('');
        setImageInfo({ width: 0, height: 0, size: 0 });
        setResultImageURL('');
        setError('');
        setProcessing(false);
    };

    const handleDownload = () => {
        if (resultImageURL) {
            const link = document.createElement('a');
            link.href = resultImageURL;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Box sx={{ textAlign: 'center', overflowY: 'auto', maxHeight: '80vh' }}>
            <img
                src={
                    'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/Example/upscale_example.png'
                }
                alt="Preview"
                style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '100%',
                    maxHeight: '500px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            />
            <Box
                {...getRootProps()}
                sx={{
                    border: '2px dashed gray',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginBottom: '20px',
                }}
            >
                <input {...getInputProps()} />
                <Typography>
                    Drag and drop your file here, or click to select files (jpeg, png, webp)
                </Typography>
            </Box>
            {uploading && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress />
                </Box>
            )}
            {selectedImage && !error && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Image Preview:</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <img
                            src={selectedImage}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Resolution: {imageInfo.width} x {imageInfo.height} px
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        Size: {imageInfo.size} MB
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={handleClear} sx={{ mt: 2 }}>
                        Clear
                    </Button>
                </Box>
            )}
            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}
            {!error && selectedImage && (
                <>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Optional Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="output-format-label">Output Format</InputLabel>
                                <Select
                                    labelId="output-format-label"
                                    value={outputFormat}
                                    onChange={(e) => setOutputFormat(e.target.value)}
                                    label="Output Format"
                                >
                                    <MenuItem value="png">PNG</MenuItem>
                                    <MenuItem value="jpeg">JPEG</MenuItem>
                                    <MenuItem value="webp">WEBP</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="What You Want To See"
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                                sx={{ mt: 2 }}
                                placeholder="Brighter"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="What You Don't like"
                                value={negativePrompt}
                                onChange={(e) => setNegativePrompt(e.target.value)}
                                sx={{ mt: 2 }}
                                placeholder="Darker"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Box sx={{ mt: 2 }}>
                                <Typography gutterBottom>Creativity</Typography>
                                <Slider
                                    value={creativity}
                                    min={0.2}
                                    max={0.5}
                                    step={0.01}
                                    //marks={[
                                    //{ value: 0.2, label: '0.2' },
                                    //{ value: 0.5, label: '0.5' },
                                    //]}
                                    //valueLabelDisplay="auto"
                                    onChange={(e, newValue) => setCreativity(newValue)}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpscale}
                        sx={{ mt: 2 }}
                        disabled={processing}
                    >
                        Upscale Image
                    </Button>
                </>
            )}
            {processing && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography variant="h6">It may take some minutes</Typography>
                    <LinearProgress />
                </Box>
            )}
            {resultImageURL && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Upscaled Image:</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <img
                            src={resultImageURL}
                            alt="Upscaled Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                    <Typography>
                        <IconButton color="secondary" onClick={handleDownload} sx={{ ml: 2 }}>
                            <DownloadIcon />
                        </IconButton>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ImageUpscale;
