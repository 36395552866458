import {
	Card,
	Fade,
	ImageListItem,
	ImageListItemBar,
} from '@mui/material';

import AnimationWrapper from './AnimationWrapper';
import { useState } from 'react';

function importAll(r) {
	let images = {};
	r.keys().map((item, index) => {
		images[item.replace('./', '')] = r(item);
	});
	return images;
}

const images = importAll(
	require.context('../../data/portfolio/', false, /\.jpg/)
);

const PortfolioListItem = (props) => {
	const [isHovered, setIsHovered] = useState(false);
	const { item } = props;

	return (
		<AnimationWrapper animationType='scale-in' className={props.className}>
			<ImageListItem
				onMouseOver={() => {
					setIsHovered(true);
				}}
				onMouseOut={() => {
					setIsHovered(false);
				}}
				className='transition ease-in-out  hover:-translate-y-6 duration-300'>
				<Card
					sx={{ boxShadow: 0, ':hover': { boxShadow: 10 } }}
					className='border-2 border-white'>
					<img src={images[item.src]} />
					<Fade in={isHovered} timeout={500}>
						<ImageListItemBar
							title={item.title}
							subtitle={item.location}
						/>
					</Fade>
				</Card>
			</ImageListItem>
		</AnimationWrapper>
	);
};

export default PortfolioListItem;
