import 'bootstrap/dist/css/bootstrap.min.css';

import * as Yup from 'yup';

import { AddCampaign, GetCampaignResizeModeList, GetCampaignTransitionEffectList } from '../../../api/api';
import { AddMediaDialog, AddTextDialog, AddURLDialog, MediaWidget, TextWidget, WebWidget } from './';
import { Button, FormControl, MenuItem, Stack, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../';
import { resetMedia } from '../../../redux/action';
import { useFormik } from 'formik';

const ELEMENT_COLORS = {
    image: '#9CA3AF',
    video: '#61AFA3',
    mix: '#61AFA3',
    text: '#FFBD88',
    web: '#88C0FF',
};

const CampaignDesign = ({ currentUser }) => {
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentRef = useRef();
    const showCampaignDesign = JSON.parse(localStorage.getItem('CurrentCampaignDesign' || {}));
    // console.log('showCampaignDesign', showCampaignDesign);
    /**
     *
     *  Get state from redux
     * 	List of media
     *
     */
    const media = useSelector((state) => state.handleMedia);
    // console.log('media', media);

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().max(255).required('Campaign name is required'),
        }),
        onSubmit: (values) => {
            console.log(values.name);
            HandleAddCampaign(values.name);
        },
    });

    /**
     * GetCampaignTransitionEffectList
     */
    const [effectList, setEffectList] = useState([]);
    const GetEffectList = async () => {
        const data = await GetCampaignTransitionEffectList();
        console.log('data', data);
        setEffectList(data?.data?.list);
    };

    useEffect(() => {
        GetEffectList();
        handleGetResizeMode();
    }, []);

    const [openAddMediaDialog, setOpenAddMediaDialog] = useState(false);
    const [openTextDialog, setOpenTextDialog] = useState(false);
    const [openWebDialog, setOpenWebDialog] = useState(false);
    const [currentElement, setCurrentElement] = useState([]);
    const [currentTextJSON, setCurrentTextJSON] = useState();
    const [currentURL, setCurrentURL] = useState([]);
    // console.log('file: CampaignDesign.jsx:96 => CampaignDesign => currentURL:', currentURL);
    // console.log(
    //     'file: CampaignDesign.jsx:97 => CampaignDesign => currentTextJSON:',
    //     currentTextJSON,
    // );
    const [newElement, setNewElement] = useState([]);
    const [currentElementList, setCurrentElementList] = useState([]);
    // console.log(
    //     'file: CampaignDesign.jsx:98 => CampaignDesign => currentElementList:',
    //     currentElementList,
    // );
    const [resizeMode, setResizeMode] = useState([]);
    // console.log('file: CampaignDesign.jsx:109 => CampaignDesign => resizeMode:', resizeMode);
    const [selectedResizeMode, setSelectedResizeMode] = useState(4);
    // console.log('selectedResizeMode:', selectedResizeMode);
    useEffect(() => {
        const tempArr = currentElementList?.map((item) => {
            if (item.element_alias === currentElement.element_alias) {
                return { ...currentElement };
            }
            return item;
        });
        tempArr?.length > 0 && setCurrentElementList([...tempArr]);
    }, [newElement]);

    useEffect(() => {
        setCurrentElementList(showCampaignDesign?.element_list);
        // setAppliedMedia([]);
    }, [localStorage.getItem('CurrentCampaignDesign')]);

    const handleOpenMediaDialog = (item) => {
        console.log('item:', item);
        console.log('CampaignDesign => currentRef:', currentRef);

        setCurrentElement(item);
        localStorage.setItem('current_element', JSON.stringify(item));
        if (
            item.element_type === 'video' ||
            item.element_type === 'audio' ||
            item.element_type === 'image' ||
            item.element_type === 'mix'
        ) {
            setOpenAddMediaDialog(true);
        }
        if (item.element_type === 'text') {
            setOpenTextDialog(true);
            setCurrentTextJSON(
                media?.find((x) => x.element_id === item.element_id)
                    ? media?.find((x) => x.element_id === item.element_id)
                    : null,
            );
        }
        if (item.element_type === 'web') {
            setOpenWebDialog(true);
        }
    };

    const handleCloseTextDialog = () => {
        setOpenTextDialog(false);
    };
    const handleCloseWebDialog = () => {
        setOpenWebDialog(false);
    };

    const handleGetResizeMode = async (item) => {
        const { code, data, msg } = await GetCampaignResizeModeList();
        if (code === 0) {
            console.log('GetCampaignResizeModeList:', data);
            setResizeMode(data?.list);
        }
        // else {
        //     alert(msg);
        // }
    };

    const handleSelectResizeMode = (e) => {
        const { value } = e.target;
        console.log('value:', value);
        setSelectedResizeMode(value);
    };

    const handleNavigation = () => {
        navigate('../dashboard/playlist', { replace: true });
        dispatch(resetMedia());
        localStorage.removeItem('CurrentCampaignDesign' || {});
    };

    const HandleAddCampaign = async (name) => {
        const element_list = showCampaignDesign.element_list;
        console.log('element_list:', element_list);

        const new_list = media.map(
            ({
                effect_duration,
                element_id,
                transition_effect,
                resource_list,
                resource_text,
                resource_website,
                detail_list,
            }) => ({
                effect_duration: effect_duration ? effect_duration : 0,
                element_id,
                transition_effect: transition_effect ? transition_effect : 0,
                content_list: resource_list
                    ? resource_list
                    : resource_text
                    ? [
                          {
                              resource_type: 'text',
                              resource_website,
                          },
                      ]
                    : detail_list
                    ? [
                          {
                              resource_type: 'web',
                              resource_website: detail_list.resource_content[0].resource_website,
                          },
                      ]
                    : [],
            }),
        );
        console.log('file: CampaignDesign.jsx:167 => constnew_list=media.map => new_list:', new_list);

        const reformedList = new_list.map((x) => ({
            ...x,
            resize_mode: selectedResizeMode,
            effect_duration: x.content_list.length > 1 ? x.effect_duration : 0,
            transition_effect: x.content_list.length > 1 ? x.transition_effect : 0,
            content_list: x.content_list.map((i) => {
                if (
                    i.resource_type === 'image' ||
                    i.resource_type === 'video' ||
                    i.resource_type === 'audio'
                ) {
                    return {
                        resource_duration:
                            i.resource_type === 'image' && x.content_list.length > 1
                                ? i.resource_duration
                                : 0,
                        resource_id: i.resource_id,
                        resource_text: JSON.stringify({}),
                    };
                }
                if (i.resource_type === 'text') {
                    return {
                        resource_duration: 0,
                        resource_id: 0,
                        resource_text: JSON.stringify(i.resource_text),
                    };
                }
                if (i.resource_type === 'web') {
                    return {
                        resource_duration: 0,
                        resource_id: 0,
                        resource_website: i.resource_website,
                    };
                }
            }),
        }));
        console.log('file: CampaignDesign.jsx:192 => reformedList => reformedList:', reformedList);
        const final_list = element_list.map((el) => {
            const matched_base = reformedList.find((base) => base.element_id === el.element_id);
            console.log('matched_base:', matched_base);
            if (matched_base) {
                return matched_base;
            } else {
                return {
                    effect_duration: 0,
                    resize_mode: selectedResizeMode,
                    element_id: el.element_id,
                    transition_effect: 0,
                    content_list: [{}],
                };
            }
        });
        console.log('final_list:', final_list);

        const data = await AddCampaign(
            switchUser,
            showCampaignDesign.template_id,
            name,
            // free_campaign,
            paymentRequired ? 0 : 1,
            paymentRequired && campaignFees ? parseFloat(campaignFees) : 0,
            final_list,
        );
        console.log('data', data);
        if (data.code === 0) {
            handleNavigation();
        }
    };

    const filteredResizeMode = useMemo(
        () => resizeMode.filter((x) => x.resize_mode !== 2 && x.resize_mode !== 3),
        [resizeMode],
    );
    console.log('file: CampaignDesign.jsx:280 => CampaignDesign => filteredResizeMode:', filteredResizeMode);

    // payment required
    const [paymentRequired, setPaymentRequired] = useState(false);
    console.log('file: CampaignDesign.jsx:283 => CampaignDesign => paymentRequired:', paymentRequired);
    const handlePaymentRequired = (event) => {
        setPaymentRequired(event.target.checked);
        console.log(
            'file: CampaignDesign.jsx:291 => handlePaymentRequired => event.target.checked:',
            event.target.checked,
        );
        if (event.target.checked === false) {
            setCampaignFees(0);
        }
    };

    const [campaignFees, setCampaignFees] = useState(0);
    const handleCampaignFees = (e) => {
        const { value } = e.target;
        const regex = /^$|^\d+(\.\d{0,2})?$/;

        if (regex.test(value)) {
            // if match the requirements, then update state
            setCampaignFees(value);
        }
    };
    console.log('file: CampaignDesign.jsx:299 => handleCampaignFees => media:', media);

    // const handleCancelCampaignDesign = () => {
    //     handleNavigation();
    // };

    return (
        <div className="my-4 flex flex-col justify-center">
            <div className="p-4">
                {/* <p className="text-lg text-gray-400">{category}</p> */}
                <div className="flex flex-row">
                    <div>
                        <p className="text-xl font-bold tracking-tight text-slate-900">
                            <Link to="/dashboard/playlist/template" className="  hover:underline">
                                Template
                            </Link>{' '}
                            &gt; <span>Campaign Design</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="p-4 pt-0 w-full flex justify-center">
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        error={Boolean(formik.touched.name && formik.errors.name)}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        label="Campaign Name"
                        margin="normal"
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div className="w-full flex flex-col">
                        {showCampaignDesign?.element_layout === 0 ? (
                            <div
                                className="relative"
                                style={{
                                    marginTop: '12px',
                                    //1920x1080
                                    width: 660,
                                    height: 380,
                                    backgroundColor: 'primary.white',
                                    border: '5px solid gray',
                                }}
                            >
                                {currentElementList?.map((item, index) => (
                                    <div
                                        ref={currentRef}
                                        key={item + index}
                                        className={`cursor-pointer absolute border-dashed border-1 border-orange-600`}
                                        style={{
                                            backgroundColor: ELEMENT_COLORS[item.element_type],
                                            width: (item?.element_width / 100) * 650,
                                            height: (item?.element_height / 100) * 370,
                                            top: (item?.element_y / 100) * 370,
                                            left: (item?.element_x / 100) * 650,
                                            zIndex: item?.element_layer * 10,
                                        }}
                                        onClick={() => handleOpenMediaDialog(item)}
                                    >
                                        {/* {media?.resource_list?.length < 1 && ( */}
                                        <div className="widget w-2/3 absolute">
                                            <p className="text-sm mx-1 my-0">{item.element_type}</p>
                                        </div>
                                        {/* )} */}
                                        {item.element_type === 'video' ||
                                        item.element_type === 'audio' ||
                                        item.element_type === 'image' ||
                                        item.element_type === 'mix' ? (
                                            <MediaWidget
                                                item={item}
                                                media={media}
                                                selectedResizeMode={selectedResizeMode}
                                            />
                                        ) : item.element_type === 'text' ? (
                                            <TextWidget media={media} />
                                        ) : (
                                            <WebWidget item={item} media={media} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div
                                className="relative"
                                style={{
                                    marginTop: '12px',
                                    //1920x1080
                                    width: 500,
                                    height: 731,
                                    backgroundColor: 'primary.white',
                                    border: '5px solid gray',
                                }}
                            >
                                {currentElementList?.map((item, index) => (
                                    <div
                                        key={item + index}
                                        className="cursor-pointer absolute border-dashed border-1 border-orange-600"
                                        style={{
                                            backgroundColor: ELEMENT_COLORS[item.element_type],
                                            width: (item?.element_width / 100) * 490,
                                            height: (item?.element_height / 100) * 721,
                                            top: (item?.element_y / 100) * 721,
                                            left: (item?.element_x / 100) * 490,
                                        }}
                                        onClick={() => handleOpenMediaDialog(item)}
                                    >
                                        <div className="widget absolute z-10 ">
                                            <p className="text-sm mx-10 my-0">{item.element_type}</p>
                                        </div>
                                        {item.element_type === 'video' ||
                                        item.element_type === 'image' ||
                                        item.element_type === 'audio' ||
                                        item.element_type === 'mix' ? (
                                            <MediaWidget
                                                item={item}
                                                media={media}
                                                selectedResizeMode={selectedResizeMode}
                                            />
                                        ) : item.element_type === 'text' ? (
                                            <TextWidget media={media} />
                                        ) : (
                                            <WebWidget item={item} media={media} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="md:w-full ">
                            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    label="Image Responsive to Template"
                                    select
                                    margin="normal"
                                    aria-labelledby="resize"
                                    name="resize"
                                    value={selectedResizeMode}
                                    onChange={(e) => handleSelectResizeMode(e)}
                                    variant="outlined"
                                >
                                    {filteredResizeMode?.map((option) => (
                                        <MenuItem key={option.resize_mode} value={option.resize_mode}>
                                            {option.resize_mode_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </div>
                    </div>
                    {/* {currentUser.user_level === 0 && (
                        <div className="w-full flex flex-row">
                            <div className="w-1/2 flex items-center">
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={paymentRequired}
                                                onChange={handlePaymentRequired}
                                                name="paymentRequired"
                                            />
                                        }
                                        label="Payment required"
                                    />
                                </FormControl>
                            </div>
                            <div className={!paymentRequired ? 'invisible' : '' + 'w-1/2'}>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        // sx={{ minWidth: '150px' }}
                                        margin="normal"
                                        aria-labelledby="campaignFees"
                                        name="campaignFees"
                                        type="text"
                                        value={campaignFees}
                                        onChange={(e) => handleCampaignFees(e)}
                                        variant="outlined"
                                        label="Fees"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                    ></TextField>
                                </FormControl>
                            </div>
                        </div>
                    )} */}

                    {openAddMediaDialog && (
                        <AddMediaDialog
                            openAddMediaDialog={openAddMediaDialog}
                            setOpenAddMediaDialog={setOpenAddMediaDialog}
                            currentUser={currentUser}
                            // setAppliedMedia={setAppliedMedia}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                            setNewElement={setNewElement}
                            effectList={effectList}
                            element_id={currentElement.element_id}
                            action={'new'}
                        />
                    )}
                    {openTextDialog && (
                        <AddTextDialog
                            openTextDialog={openTextDialog}
                            handleCloseTextDialog={handleCloseTextDialog}
                            currentTextJSON={currentTextJSON}
                            currentUser={currentUser}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    )}
                    {openWebDialog && (
                        <AddURLDialog
                            openWebDialog={openWebDialog}
                            handleCloseWebDialog={handleCloseWebDialog}
                            currentUser={currentUser}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    )}
                    <Stack direction="row" spacing={2} className="flex justify-end w-full mt-4">
                        <Button variant="contained" onClick={handleNavigation}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">
                            Save
                        </Button>
                    </Stack>
                </form>
            </div>
        </div>
    );
};

export default CampaignDesign;
