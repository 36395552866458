import React, { useEffect } from 'react';

import { Chip } from '@mui/material';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';

const Tabs = ({ children, activeTab, setActiveTab, count }) => {
    console.log('Tabs => children:', children);
    useEffect(() => {
        // if (children.length > 0) {
        setActiveTab((Array.isArray(children) ? children[0] : children)?.props?.label);
        // }
    }, []);
    // const [activeTab, setActiveTab] = useState(children[0].props.label);

    const handleClick = (e, newActiveTab) => {
        e.preventDefault();
        setActiveTab(newActiveTab);
    };

    return (
        <div className="mx-auto ">
            <div className="flex border-b border-gray-300">
                {children &&
                    (Array.isArray(children) ? children : [children])?.map((child) => (
                        <React.Fragment key={child.props.label}>
                            <button
                                key={child.props.label}
                                className={`${
                                    activeTab === child.props.label
                                        ? 'border-b-2 border-blue-300 text-blue-400'
                                        : ''
                                } flex-1 font-large py-2`}
                                onClick={(e) => handleClick(e, child.props.label)}
                            >
                                {child.props.label}
                                {child.props.count > 0 && (
                                    <Chip
                                        label={child.props.count}
                                        sx={{
                                            backgroundColor:
                                                activeTab === child.props.label ? '#0444AB' : '#E5ECF6',
                                            color: activeTab === child.props.label ? '#ffffff' : '#0444AB',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            marginLeft: '10px',
                                        }}
                                        icon={
                                            child.props.label.toLowerCase() === 'locations' ? (
                                                <FaMapMarkerAlt
                                                    style={{
                                                        color:
                                                            activeTab === child.props.label
                                                                ? '#ffffff'
                                                                : '#0444AB',
                                                        marginLeft: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <FiMonitor
                                                    style={{
                                                        color:
                                                            activeTab === child.props.label
                                                                ? '#ffffff'
                                                                : '#0444AB',
                                                        marginLeft: '10px',
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                )}
                            </button>
                        </React.Fragment>
                    ))}
            </div>
            <div className="py-4">
                {children &&
                    (Array.isArray(children) ? children : [children])?.map((child) => {
                        if (child.props.label === activeTab) {
                            return <div key={child.props.label}>{child.props.children}</div>;
                        }
                        return null;
                    })}
            </div>
        </div>
    );
};

const Tab = ({ label, children }) => {
    return (
        <div label={label} className="hidden max-h-screen">
            {children}
        </div>
    );
};
export { Tabs, Tab };
