import * as Yup from 'yup';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SendVerifyCode, SwitchTwoFactorAuthentication } from '../../../api/api';

import { DenseInput } from '../utils';
import { FormStyles } from './';
import { Header } from '../';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useNotification } from '../../../contexts/ContextProvider';
import { useStateContext } from '../../../contexts/ContextProvider';

const style = {
    labelText: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
    label: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
        color: 'black',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
};
export const DenseOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    '& .MuiOutlinedInput-input': {
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        fontSize: 14,
        padding: '4px 8px',
    },
    '& .MuiInputBase-input': {
        padding: '4px 8px',
        height: '20px !important',
    },
    '& fieldset': { border: '1px solid #ced4da' },
}));

const TwoStepVerification = ({ profileDetail, updateProfile, setUpdateProfile }) => {
    const { currentUser } = useStateContext();
    const notify = useNotification();
    const initialValues = {
        password: '',
        double_switch: '',
        verify_code: '',
    };
    const [formData, setFormData] = useState(initialValues);
    const [verifiedPassword, setVerifiedPassword] = useState(false);
    const [hideSetup, setHideSetup] = useState(false);

    console.log('file: ChangePwd.jsx:90 => ChangePwd => formData:', formData);
    const handleVerifyPassword = async () => {
        const { password, double_switch, verify_code } = formData;
        const { code, data, msg } = await SwitchTwoFactorAuthentication(password, 0, verify_code);
        console.log('data:', data);
        console.log('code:', code);
        if (code === 0) {
            // setVerifiedPassword(true);
            if (profileDetail?.double_login_validate === 0) {
                setVerifiedPassword(true);
                handleGetVerifyCode();
            } else {
                setFormData(initialValues);
                setUpdateProfile(!updateProfile);
            }
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg,
                },
            });
        }
    };
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [showAccountPassword, setShowAccountPassword] = useState(false);

    const handleClickShowAccountPassword = () =>
        setShowAccountPassword((showAccountPassword) => !showAccountPassword);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // const [sentVerifyCode, setSentVerifyCode] = useState();
    // // const [sent, setSent] = useState(false);
    // const handleVerifyCodeChange = (e) => {
    //     const { value } = e.target;
    //     setSentVerifyCode(value);
    // };
    const handleGetVerifyCode = async () => {
        const data = await SendVerifyCode('enable_double_login_validate');
        // setSent(true)
        console.log('handleVerifyCode => data:', data);
    };
    const handleSetUp = async () => {
        const { password, double_switch, verify_code } = formData;

        const data = await SwitchTwoFactorAuthentication(password, 1, verify_code);
        console.log('data:', data);
        // console.log('code:', code);
        if (data.code === 0) {
            setUpdateProfile(!updateProfile);
            setVerifiedPassword(false);
            console.log('ssssssssss');
        }
    };
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10 rounded-3xl">
            <Header
                title="Two-step verification"
                badgeText={
                    profileDetail?.double_login_validate === 0
                        ? 'disabled'
                        : // <span className="bg-green-200 text-green-600 font-bold">

                          '2-step verification on'
                    // </span>
                }
            />
            {profileDetail?.double_login_validate !== 1 && (
                <div className="mx-4">
                    <p>
                        Protect your account by adding an extra layer of security. A second login step can
                        keep your account secure, even if your password is compromised. To enable it, all you
                        need is a smart phone.
                    </p>
                </div>
            )}
            <>
                <Header
                    title={`${
                        profileDetail?.double_login_validate !== 1 ? 'Set up' : 'Turn off'
                    } two-step verification`}
                />
                <div className="mx-4">
                    <div className="mb-4">
                        <p>
                            {profileDetail?.double_login_validate !== 1
                                ? "Start by entering your password so that we know it's you. Then we’ll walk you through one simple step."
                                : 'Please enter your password to disable the two steps verification'}
                        </p>
                    </div>
                    <FormControl
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <FormLabel id="password" sx={FormStyles.labelText}>
                            Account password
                        </FormLabel>
                        <DenseOutlinedInput
                            fullWidth
                            // margin="normal"
                            aria-labelledby="password"
                            name="password"
                            variant="outlined"
                            type={showAccountPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            disabled={verifiedPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div
                                        className="cursor-pointer"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowAccountPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showAccountPassword ? <VisibilityOff /> : <Visibility />}
                                    </div>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {verifiedPassword && (
                        <FormControl
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <FormLabel id="password" sx={FormStyles.labelText}>
                                Verify Code
                            </FormLabel>
                            <DenseInput
                                fullWidth
                                margin="normal"
                                aria-labelledby="sentVerifyCode"
                                name="verify_code"
                                variant="outlined"
                                type="text"
                                value={formData.verify_code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    )}
                    <div className="flex w-full justify-center mt-3">
                        {!verifiedPassword && (
                            <Button
                                sx={{ minWidth: '120px' }}
                                variant="contained"
                                onClick={() => handleVerifyPassword()}
                            >
                                {profileDetail?.double_login_validate !== 1 ? 'Verify Password' : 'Turn Off'}
                            </Button>
                        )}
                        {verifiedPassword && (
                            <Button
                                sx={{ minWidth: '120px' }}
                                variant="contained"
                                onClick={() => handleSetUp()}
                            >
                                Set up
                            </Button>
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

export default TwoStepVerification;
