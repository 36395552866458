import * as Yup from 'yup';

import { Box, Button, Container, InputAdornment, Select, TextField, Typography } from '@mui/material';
// import { Field, Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { FaLock } from 'react-icons/fa';
import { doubleLoginValidate } from '../api/api';
// import { GrUser } from 'react-icons/gr';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const VerifyCodePage = () => {
    const { setCurrentUser, setAllUsers, currentColor } = useStateContext();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('currentUser'));

    const [userList, setUserList] = useState([]);

    const [switchUser, setSwitchUser] = useState(user.user_id);
    const [code, setCode] = useState('');
    console.log('VerifyCodePage => code:', code);
    // useEffect(() => {
    //     // handleGetUserList();
    //     // setSwitchUser(user.user_id);
    // }, []);

    // console.log('switchUser:', switchUser);
    // console.log('user:', user);
    const handleChange = (event) => {
        const { value } = event.target;
        setCode(value);
        // console.log('event:', event);
        // setSwitchUser(value);
        // const name = userList.find((x) => x.user_id === value).name;

        // console.log('file: Sidebar.jsx:88 ===> handleChange ===> name:', name);

        // localStorage.setItem('switchUserName', JSON.stringify(name));
    };

    /***
     *
     * Handle get user list
     *
     */
    // const handleGetUserList = async () => {
    //     const { code, data, msg } = await GetUserList(switchUser, 0, 2);
    //     if (code === 0) {
    //         localStorage.setItem('allUsers', JSON.stringify(data.list));
    //         setAllUsers(data.list);
    //         setUserList(data.list);
    //     }
    //     // else {
    //     //     alert(msg);
    //     // }
    // };

    const handleNavigate = () => {
        console.log('handleNavigate:', handleNavigate);
        user.user_id !== switchUser && localStorage.setItem('switchUser', JSON.stringify(switchUser));

        console.log('file: SwitchUser.jsx:81 ===> handleNavigate ===> switchUser.user_id:', switchUser);

        console.log('file: SwitchUser.jsx:81 ===> handleNavigate ===> user.user_id:', user.user_id);

        localStorage.setItem('currentUser', JSON.stringify(user));
        console.log('currentUser:', user);
        setCurrentUser(user);
        navigate('/dashboard/schedule');
    };

    const handleContinue = async () => {
        console.log('continue...');
        const data = await doubleLoginValidate(code);
        console.log('handleContinue => data:', data);
        if (data?.code === 0) handleNavigate();
    };
    return (
        <div className="h-screen">
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    minHeight: '100%',
                }}
            >
                <Container maxWidth="md">
                    <div className="p-12 rounded-3xl shadow-lg">
                        <div className="w-full flex justify-center items-center mb-6">
                            {/* <FaRegUser size={32} color={currentColor} /> */}
                            <p className="w-full pl-3 text-black-500 text-xl text-center font-bold">
                                Verify it's you
                            </p>
                        </div>
                        <div className="w-full flex flex-col mb-6 justify-center ">
                            {/* <FaRegUser size={32} color={currentColor} /> */}
                            <div>
                                <Typography
                                    className="pl-3 text-gray-500 text-12 text-center mb-4"
                                    sx={{ fontWeight: 600 }}
                                >
                                    Enter the verification code we sent to your mobile phone
                                </Typography>
                            </div>
                            <div className="w-full flex justify-center mb-4">
                                <TextField
                                    // fullWidth
                                    id="input-with-icon-textfield"
                                    name="code"
                                    // label="TextField"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FaLock />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleChange}
                                    // variant="contained"
                                />
                            </div>
                            <div className="w-full flex justify-center">
                                <Button variant="contained" onClick={handleContinue}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </Box>
        </div>
    );
};

export default VerifyCodePage;
