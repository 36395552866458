import * as React from 'react';

import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material/';
import { CheckPaymentMethod, CreateSubscription } from './Api';
import { GetBillingPortal, GetSubPriceList, Getalldevice } from './Api';
import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// GetUserDetail;
import moment from 'moment';

const StripeSubscription = ({ currentUser, switchUser, customerType, username }) => {
    console.log('file: StripeSubscription.jsx:31 => StripeSubscription => switchUser:', switchUser);
    console.log('file: StripeSubscription.jsx:98 => StripeSubscription => currentUser:', currentUser);
    const [selectedValue, setSelectedValue] = useState();

    // Paste the stripe-pricing-table snippet in your React component

    const [warning, setwarning] = useState(false);

    const billingportal = async (e) => {
        //const profile = {userId: "1", user_email:"vincent.liu@soniq.com"}

        const bp = await GetBillingPortal(
            JSON.stringify({
                userId: switchUser ? switchUser : currentUser.user_id,
                username: username,
            }),
        );

        console.log('bbbbbbbbbbbbbb', bp);

        if (bp['statusCode'] == 200) {
            window.location.href = bp['message'][0];
        } else {
            //window.location.href = 'https://signinspire.com.au/dashboard/home';
            console.log('warning');
            setwarning(true); // Show the warning message
        }
    };

    ///////////////////////////////////////////
    const [devices, setdevices] = useState([]);
    const getDeviceList = async () => {
        //console.log(abc)
        const body = { userId: switchUser ? switchUser : currentUser.user_id };

        const data = await Getalldevice(body);
        console.log('GetDeviceList:', data.message);

        const total = data.message;
        total?.map((dev) => {
            dev.type = 'Select Products';
            dev.price = '';
            dev.note = false;

            check_expire_time(dev);
        });

        setdevices(total);
    };

    //////////////////////////////
    const [products, setproducts] = useState([]);
    const getSubsriptionPriceList = async () => {
        //console.log(abc)

        const data = await GetSubPriceList();
        console.log('GetSubPriceList:', data.message);

        // const total = data.message;

        setproducts(data.message);
        setSelectedValue(data.message[0].name);
    };

    useEffect(() => {
        //getSubsriptionPriceList();
        getDeviceList();
        getSubsriptionPriceList();
    }, []);

    //////// modual confirmation function

    const [show, setShow] = useState(false);
    const [current, setcurrent] = useState();
    const [haspaymentmethod, sethaspaymentmethod] = useState();
    //const [subscription_error, setsubscription_error] = useState();

    // const handleClose = () => setShow(false);
    const handleShow = async (dev) => {
        //setShow(true);
        console.log('ddddd', dev);
        setcurrent(dev);
        const body = { userId: switchUser ? switchUser : currentUser.user_id, username: username };
        console.log('file: StripeSubscription.jsx:108 => handleShow => body:', body);
        const data = await CheckPaymentMethod(body);
        console.log('cehck', data);

        if (data['statusCode'] == 200) {
            sethaspaymentmethod(true);
            setShow(true);
        } else if (data['statusCode'] == 201) {
            sethaspaymentmethod(false);
            setShow(true);
        }
    };

    const check_expire_time = (dev) => {
        const now = moment();
        if (moment.unix(dev.expire_time).isAfter(now.clone().add(18, 'months'))) {
            console.log('moment1 is greater than now + 18 months');
            dev.note = 'Still a long time to go';
        }
    };

    const createsub = async (dev) => {
        console.log('file: StripeSubscription.jsx:239 => createsub => dev:', dev);
        console.log('file: StripeSubscription.jsx:239 => createsub => selectedValue:', selectedValue);
        //const profile = {userId: "1", user_email:"vincent.liu@soniq.com"}

        const items = {
            price: selectedValue ? selectedValue : products[0].price_id, // Cybercast Subscription test - day
            expire_date: dev.expire_time,
            device_id: dev.id,
            userId: switchUser ? switchUser : currentUser.user_id,
            username: username,
        };

        console.log('request body', items);

        const data = await CreateSubscription(JSON.stringify(items));

        console.log('ssssssssssssss', data);

        if (data['statusCode'] == 200) {
            dev.subscription = 'Processing';
            dev.price = '';
            setShow(false);
            setSelectedValue();
            handleCloseSelectSubDialog();
        }
    };

    // Select subscription type dialog
    const [openSelectSubDialog, setOpenSelectSubDialog] = useState(false);

    const handleOpenSelectSubDialog = (id) => {
        setOpenSelectSubDialog(true);
        const updatedRows = [...devices];
        const rowToUpdate = updatedRows.find((row) => row.id === id);

        setSelectedValue(products[0]?.price_id);

        handleShow(rowToUpdate);
    };

    const handleCloseSelectSubDialog = () => {
        setOpenSelectSubDialog(false);
        setSelectedValue();
    };
    // Select radio

    const handleChange = (event) => {
        console.log('file: StripeSubscription.jsx:302 => handleChange => current:', current);
        let updatedRows = [...devices];
        const rowToUpdate = updatedRows.find((row) => row.id === current.id);
        // console.log('row type update', type);
        rowToUpdate.type = event.target.value;

        const product = products.find((row) => row.price_id === event.target.value);
        console.log('file: StripeSubscription.jsx:198 => handleChange => product:', product);
        rowToUpdate.price = product.price;
        rowToUpdate.price_id = product.price_id;
        console.log('file: StripeSubscription.jsx:318 => handleChange => rowToUpdate:', rowToUpdate);

        // Update the selectedrows state based on the updated renew type
        // setdevices(updatedRows);
        //setShow(true);
        // handleShow(rowToUpdate);
        setSelectedValue(event.target.value);
    };
    // console.log('dev', dev);

    return (
        <div>
            <div align="center">
                <Button sx={{ marginBottom: '20px' }} variant="contained" onClick={billingportal}>
                    Manage My Subscription
                </Button>

                {warning == true && (
                    <div>
                        <Alert severity="error">System busy. Please try again later</Alert>
                    </div>
                )}
            </div>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ padding: '4px' }}>
                                Device id
                            </TableCell>
                            <TableCell align="center">Device Name</TableCell>
                            <TableCell align="center">Expiration Date</TableCell>

                            <TableCell align="center">Subscription Status</TableCell>
                            <TableCell align="center">Next Step</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {devices.map((dev) => (
                            <TableRow
                                key={dev.id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row" align="center" sx={{ padding: '4px' }}>
                                    {dev.id}
                                </TableCell>
                                <TableCell align="center">{dev.device_name}</TableCell>
                                <TableCell align="center">
                                    {moment.unix(dev.expire_time).format('DD/MM/YYYY')}
                                </TableCell>

                                <TableCell align="center">
                                    {dev.subscription === 'true' ? (
                                        <Badge badgeContent={'Subscribed'} color="success"></Badge>
                                    ) : dev.subscription === 'Processing' ? (
                                        <Badge
                                            badgeContent={dev.subscription === 'Processing' && 'Processing'}
                                            color="primary"
                                        ></Badge>
                                    ) : (
                                        <Badge
                                            badgeContent={dev.subscription === 'false' && 'Unsubscribed'}
                                            color="primary"
                                        ></Badge>
                                    )}
                                </TableCell>

                                {dev.subscription === 'false' ? (
                                    <TableCell align="center">
                                        {!dev.note ? (
                                            <div>
                                                <Button
                                                    xs={{ color: '' }}
                                                    variant="contained"
                                                    sx={{ padding: '6px' }}
                                                    onClick={() => handleOpenSelectSubDialog(dev.id)}
                                                >
                                                    Select Subscription Type
                                                </Button>
                                            </div>
                                        ) : (
                                            dev.note
                                        )}
                                    </TableCell>
                                ) : (
                                    <TableCell align="center"></TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openSelectSubDialog} onClose={handleCloseSelectSubDialog}>
                <DialogTitle>Subscription</DialogTitle>
                <DialogContent>
                    {haspaymentmethod ? (
                        <>
                            Are you sure to subscribe this device id {current.id}?
                            <Table className="border-collapse border border-slate-400 mt-4">
                                <TableHead className="p-2">
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="p-2">
                                    {products
                                        .filter((x) => customerType === 0 || x.customer_type === customerType)
                                        .map((product, index) => (
                                            <TableRow
                                                key={product + index}
                                                // selected={index === selectedIndex}
                                                // onClick={(event) =>
                                                // 	console.log(event)
                                                // }
                                            >
                                                <TableCell>
                                                    <RadioGroup
                                                        aria-labelledby="controlled-radio-id"
                                                        name="name"
                                                        value={selectedValue}
                                                        // defaultValue={products[0].name}
                                                    >
                                                        <FormControlLabel
                                                            value={product.price_id}
                                                            control={<Radio />}
                                                            onChange={handleChange}
                                                            // label={product.id}
                                                        />
                                                    </RadioGroup>
                                                </TableCell>
                                                <TableCell>{product.name}</TableCell>
                                                <TableCell>{product.price}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </>
                    ) : (
                        'Please go to Subscription management to add a payment method first.'
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseSelectSubDialog}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => createsub(current)}
                        disabled={!haspaymentmethod}
                    >
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StripeSubscription;
