import {
    AddDeviceDialog,
    DeviceList,
    LocationDialog,
    Locations,
    MapDialog,
} from '../components/dashboard/device';
import { Badge, Box, Button, IconButton, Tooltip } from '@mui/material';
import { GetCount, GetDeviceList, GetLocationList } from '../api/api';
import React, { Suspense, useEffect, useState } from 'react';
import { Tab, Tabs } from '../components/dashboard/Tabs';

import { FaMapMarkedAlt } from 'react-icons/fa';
import { Header } from '../components/dashboard';
import { IoMdAdd } from 'react-icons/io';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../contexts/ContextProvider';

// const StyledBadge = styled(Badge)(({ theme }) => ({
//     '& .MuiBadge-badge': {
//         right: -16,
//         top: 8,
//         border: `2px solid ${theme.palette.background.paper}`,
//         padding: '0 4px',
//     },
// }));
// const StyledTab = styled(Tab)(({ theme }) => ({
//     paddingRight: '30px',
//     textTransform: 'capitalize',
// }));
// const ariaLabel = { 'aria-label': 'description' };
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

const DeviceManager = () => {
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const { currentUser } = useStateContext();
    // Handle Change Tabs
    const [tabValue, setTabValue] = useState(0);

    const handleTabs = (event, newValue) => {
        console.log('handleTabs => newValue:', newValue);
        setTabValue(newValue);
        if (newValue === 1) getDeviceList();
    };

    // get numbers of locations & devices
    const [counts, setCounts] = useState([]);
    const getNumbers = async () => {
        const data = await GetCount(switchUser);
        setCounts(data.data);
    };

    // Handle Add Device Button
    const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
    // const [addedDevice, setAddedDevice] = useState(true);
    const handleOpenDeviceDialog = () => {
        setOpenDeviceDialog(true);
    };

    const handleCloseDeviceDialog = () => {
        setOpenDeviceDialog(false);
    };

    //Get Device List
    const [assignedDeviceList, setAssignedDeviceList] = useState([]);
    const [unassignedDeviceList, setUnassignedDeviceList] = useState([]);
    const getDeviceList = async () => {
        const data = await GetDeviceList(switchUser);
        // console.log('getDeviceList => data:', data);

        setAssignedDeviceList(data?.data?.assign_device_list);
        setUnassignedDeviceList(data?.data?.no_assign_device_list);
    };

    // Get Locations
    const [locationList, setLocationList] = useState([]);

    console.log('locationList', locationList);
    const getLocations = async () => {
        const data = await GetLocationList(switchUser);
        // console.log('getLocations => data:', data);
        const list = data?.data;
        if (data.code === 0) {
            Array.isArray(list) ? setLocationList(list) : setLocationList([]);
        }
    };

    //handle Open Location Dialog
    const [openLocation, setOpenLocation] = useState(false);
    const handleOpenLocationDialog = () => {
        setOpenLocation(true);
    };
    const handleCloseLocationDialog = () => {
        setOpenLocation(false);
    };

    const [triggerUpdate, setTriggerUpdate] = useState(false);

    useEffect(() => {
        console.log('\ntriggerUpdate status=====\n', triggerUpdate);
        getNumbers();
        getLocations();
        getDeviceList();
    }, [triggerUpdate]);

    //handle Open Map Dialog
    const [openMapDialog, setOpenMapDialog] = useState(false);
    const handleOpenMapDialog = () => {
        setOpenMapDialog(true);
    };
    const handleCloseMapDialog = () => {
        setOpenMapDialog(false);
    };
    const [activeTab, setActiveTab] = useState('');

    return (
        <>
            <div className="m-10 p-2 md:m-10 md:p-10">
                <div className="w-full columns-2 flex justify-between">
                    <Header title="Device Manager" />
                    {activeTab.toLowerCase() === 'all devices' ? (
                        <div className="mr-4 p-3 pt-4 ">
                            <Button variant="contained" onClick={handleOpenDeviceDialog}>
                                <IoMdAdd />
                                Add Device
                            </Button>
                            <AddDeviceDialog
                                openDeviceDialog={openDeviceDialog}
                                handleCloseDeviceDialog={handleCloseDeviceDialog}
                                currentUser={currentUser}
                                setTriggerUpdate={setTriggerUpdate}
                                triggerUpdate={triggerUpdate}
                            />
                        </div>
                    ) : (
                        <div className="mr-4 p-3 pt-4 ">
                            <Button variant="contained" onClick={handleOpenLocationDialog}>
                                <IoMdAdd />
                                Add Location
                            </Button>
                            {openLocation && (
                                <LocationDialog
                                    openLocation={openLocation}
                                    handleCloseLocationDialog={handleCloseLocationDialog}
                                    unassignedDeviceList={unassignedDeviceList}
                                    currentUser={currentUser}
                                    setTriggerUpdate={setTriggerUpdate}
                                    triggerUpdate={triggerUpdate}
                                    text={'Add'}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className="pt-0">
                    {/* <p>This area will allow you to manage your groups and devices</p> */}
                    <Tabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        // count={
                        //     activeTab.toLowerCase() === 'locations'
                        //         ? counts.device_group_num
                        //         : counts.device_num
                        // }
                    >
                        <Tab label="Locations" count={counts.device_group_num}>
                            {/* <div className="flex w-full justify-between "> */}
                            {/* <div className="flex flex-row "></div> */}
                            <div className="w-full flex justify-end">
                                <Tooltip title="Map">
                                    <IconButton color="primary" onClick={handleOpenMapDialog}>
                                        <FaMapMarkedAlt />
                                    </IconButton>
                                </Tooltip>
                                {/* <Button
                                    variant="contained"
                                    onClick={handleOpenMapDialog}
                                    // style={{ color: '#0444AB' }}
                                >
                                    <FaMapMarkedAlt />
                                </Button> */}
                                {openMapDialog && (
                                    <MapDialog
                                        openMapDialog={openMapDialog}
                                        handleCloseMapDialog={handleCloseMapDialog}
                                        switchUser={switchUser}
                                        currentUser={currentUser}
                                    />
                                )}
                            </div>
                            {/* </div> */}
                            <div className="mt-4">
                                <Locations
                                    locationList={locationList}
                                    currentUser={currentUser}
                                    setTriggerUpdate={setTriggerUpdate}
                                    triggerUpdate={triggerUpdate}
                                    openLocation={openLocation}
                                    switchUser={switchUser}
                                    // locationListDetail={locationListDetail}
                                />
                            </div>
                        </Tab>
                        <Tab label="All Devices" count={counts.device_num}>
                            <DeviceList
                                assignedDeviceList={assignedDeviceList}
                                currentUser={currentUser}
                                unassignedDeviceList={unassignedDeviceList}
                                locationList={locationList}
                                setTriggerUpdate={setTriggerUpdate}
                                triggerUpdate={triggerUpdate}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default DeviceManager;
