import * as React from 'react';

import { Button, Container, Typography } from '@mui/material';

import AWS from 'aws-sdk';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import { Resize } from './Api';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import config from '../../../config/config';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const marks = [
    {
        value: 100,
        label: '100',
    },
    {
        value: 512,
        label: '512',
    },
    {
        value: 1024,
        label: '1024',
    },
    {
        value: 1600,
        label: '1600',
    },
];

const user_id = JSON.parse(localStorage.getItem('userDetails'))?.user_id;
const ImageResize = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [resizedImage, setResizedImage] = useState(null);
    const [url, seturl] = useState(null);

    const [imageResolution, setImageResolution] = useState({
        width: null,
        height: null,
    });
    const [imagewidth, setimagewidth] = useState(null);
    const [imageheight, setimageheight] = useState(null);
    const [new_width, setnew_width] = useState(null);
    const [new_height, setnew_height] = useState(null);

    const [target_size, settarget_size] = useState(null);

    const [alertPNG, setalertPNG] = useState(false);

    const [loading, setloading] = useState(false);
    const [processing, setprocessing] = useState(null);

    const [S3URL, setS3URL] = useState(null);
    const [progress, setProgress] = React.useState(0);
    const [code500, setcode500] = useState(null);

    const handlewidth = (value) => {
        setnew_width(value);
        return `${value}`;
    };
    const handleheight = (value) => {
        setnew_height(value);
        return `${value}`;
    };

    const [imageSize, setImageSize] = useState(null);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        handleFileChange(file);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (file) => {
        setSelectedFile(file);
        setalertPNG(false);

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 20000000) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setImageSize(file.size);
                console.log({ width: img.width, height: img.height });

                uploadS3(file);
            };
            img.src = URL.createObjectURL(file);
        } else {
            setalertPNG(true);
        }
    };

    const uploadS3 = async (selectedFile) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;

        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;

        const filteredFilename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '');

        AWS.config.update({
            accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
            secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            //ACL: "public-read",
            Body: selectedFile,
            Bucket: S3_BUCKET,
            Key: user_id + '/Resize/' + filteredFilename,
        };
        console.log(params);

        //const data = myBucket.putObject(params);
        //console.log(data);
        setloading(true);

        var upload = myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt) => {
                console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
                setProgress(parseInt((evt.loaded * 100) / evt.total));
            })
            .promise();

        const response = await upload.then((err, data) => {
            console.log(err);
            console.log(upload);

            setloading(false);
            setProgress(0);
            setImagePreview(true);
        });

        setS3URL(
            'https://' +
                S3_BUCKET +
                '.s3.' +
                REGION +
                '.amazonaws.com/' +
                user_id +
                '/Resize/' +
                filteredFilename,
        );
        console.log(
            'https://' +
                S3_BUCKET +
                '.s3.' +
                REGION +
                '.amazonaws.com/' +
                user_id +
                '/Resize/' +
                filteredFilename,
        );
        //return data;
    };

    const handleUpload = async () => {
        if (selectedFile && S3URL) {
            try {
                setprocessing(true);
                setcode500(false);

                const targetSizeMB = parseFloat(target_size);
                const target_size_bytes = Math.round(targetSizeMB * 1024 * 1024); // Convert MB to bytes and round to the nearest integer

                const body = {
                    user_id: user_id,
                    image_url: S3URL,
                    new_height: new_height,
                    new_width: new_width,
                    target_size_bytes: target_size_bytes,
                };

                console.log('body', body);

                const data = await Resize(body);
                setprocessing(false);
                console.log('Resized Image Data:', data);

                if (data.statusCode === 200) {
                    console.log('200');
                    setResizedImage(data.message);
                    seturl(data.message);
                } else {
                    console.log('500');
                    setcode500(true);
                }
            } catch (error) {
                console.error('Error while resizing:', error);
                setprocessing(false);
                setcode500(true);
            }
        }
    };

    const handleDownload = () => {
        if (resizedImage) {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'resized_image.jpg';
            link.click();
        }
    };

    const handleRefresh = () => {
        setImageResolution({
            width: null,
            height: null,
        });
        setSelectedFile(null);
        setImagePreview(null);
        setalertPNG(false);
        setResizedImage(null);
        //console.log(row);
    };

    return (
        <Box sx={{ textAlign: 'center', overflowY: 'auto', maxHeight: '80vh' }}>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Resize
            </Typography>
            <Box
                {...getRootProps()}
                sx={{
                    border: '2px dashed gray',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
            >
                <input {...getInputProps()} />
                <Typography>Drag drop your file here, or click to select files</Typography>
            </Box>

            {selectedFile !== null && (
                <Box>
                    <Button variant="contained" color="error" onClick={handleRefresh}>
                        Clear
                    </Button>
                </Box>
            )}

            <Typography variant="body1" gutterBottom>
                {selectedFile && `Selected File: ${selectedFile.name}`}
            </Typography>
            {alertPNG == true && (
                <div>
                    <Alert severity="error">Please select a PNG/JPG file and size is less than 20MB </Alert>
                </div>
            )}
            {loading == true && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={progress} />
                </Box>
            )}
            {imagePreview && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <img
                            src={S3URL}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                    {imageResolution.width !== null && imageResolution.height !== null && (
                        <Box sx={{ mt: 4, padding: '50px' }}>
                            <Typography variant="body1" gutterBottom>
                                Image Resolution: {imageResolution.width} x {imageResolution.height}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Image Size: {(imageSize / 1024 / 1024).toFixed(2)} MB
                            </Typography>
                            <TextField
                                required
                                id="width-input"
                                label="New Width"
                                type="number"
                                value={new_width || ''}
                                onChange={(e) => setnew_width(e.target.value)}
                                InputProps={{ inputProps: { min: 1 } }}
                                sx={{ mt: 2, mx: 1 }}
                            />
                            <TextField
                                required
                                id="height-input"
                                label="New Height"
                                type="number"
                                value={new_height || ''}
                                onChange={(e) => setnew_height(e.target.value)}
                                InputProps={{ inputProps: { min: 1 } }}
                                sx={{ mt: 2, mx: 1 }}
                            />
                            <TextField
                                required
                                id="outlined-basic"
                                label="Target Size"
                                type="number"
                                value={target_size}
                                defaultValue={(imageSize / 1024 / 1024).toFixed(2)}
                                onChange={(e) => {
                                    let numericValue = e.target.value;

                                    // Allow only two decimal places
                                    if (numericValue.includes('.')) {
                                        const parts = numericValue.split('.');
                                        if (parts[1].length > 2) {
                                            numericValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                        }
                                    }

                                    // Ensure the value does not exceed the image size
                                    const maxImageSizeMB = (imageSize / 1024 / 1024).toFixed(2);
                                    if (parseFloat(numericValue) > parseFloat(maxImageSizeMB)) {
                                        console.log('maxImageSizeMB', maxImageSizeMB);
                                        settarget_size(maxImageSizeMB);
                                    } else {
                                        console.log('numericValue', numericValue);
                                        settarget_size(numericValue);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                                    inputProps: { min: 0, step: 0.01 },
                                }}
                                sx={{ mt: 2 }}
                            />
                        </Box>
                    )}
                    <Button variant="contained" color="primary" onClick={handleUpload}>
                        Edit
                    </Button>
                </>
            )}

            {processing && (
                <Box sx={{ width: '100%', mt: 4 }}>
                    <LinearProgress />
                </Box>
            )}

            {code500 && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    Error resizing image. Please try another parameter again.
                </Alert>
            )}

            {!processing && resizedImage && !code500 && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Resized Image Preview</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <img
                            src={url}
                            alt="Resized Preview"
                            style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px' }}
                        />
                    </Box>

                    <Typography variant="body1" gutterBottom>
                        Image Resolution: {new_width} x {new_height}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={handleDownload} sx={{ mt: 2 }}>
                        Download
                    </Button>
                </Box>
            )}
        </Box>
    );
};
export default ImageResize;
