import * as Yup from 'yup';

import { AddSchedule, UpdateSchedule, getCampaignDetail } from '../../../api/api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
} from '@mui/material';
import { CopyScheduleDialog, PreviewCampaignSec, SelectedCampaignSec } from './';
import React, { useEffect, useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DeleteDialog } from '../utils';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addMedia } from '../../../redux/action';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../contexts/ContextProvider';

const ScheduleCampaignDialog = ({
    locationInfo,
    openScheduleDialog,
    handleCloseScheduleDialog,
    scheduleFrequencyList,
    currentUser,
    campaigns,
    scheduleDetail,
    locationList,
    triggerUpdate,
    setTriggerUpdate,
    selectedDate,
    locationDetail,
}) => {
    console.log('campaigns:', campaigns);
    const notify = useNotification();
    // console.log('target_layout:', locationDetail.group_info.target_layout);
    // console.log('locationDetail:', locationDetail);
    const now = new Date();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const navigate = useNavigate();
    const redux_dispatch = useDispatch();
    // Set the time to the start of the day
    const startOfToday = selectedDate
        ? moment(selectedDate).startOf('day').toDate()
        : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    console.log('startOfToday:', startOfToday);
    // Set the time to the end of the day
    let endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    // Check if startOfToday is greater than endOfToday
    if (startOfToday > endOfToday) {
        // Adjust endOfToday to be the last millisecond of the current day
        endOfToday = new Date(startOfToday);
        endOfToday.setHours(23, 59, 59, 999);
    }

    const detail = JSON.parse(localStorage.getItem('scheduleDetail' || {}));

    const formik = useFormik({
        initialValues: {
            group_name: scheduleDetail?.schedule_name ? scheduleDetail?.schedule_name : '',
            repeat: scheduleDetail?.schedule_frequency ? scheduleDetail?.schedule_frequency : 'oneTimeEvent',
            // select_campaign: scheduleDetail?.detail_list ? scheduleDetail?.detail_list : [],
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            group_name: Yup.string().max(255).required('Group Name is required'),
        }),
        onSubmit: (values) => {
            console.log('campaignDivs', campaignDivs);

            saveSchedule(values.group_name, values.repeat, campaignDivs);

            formik.resetForm();
        },
    });
    const detailList = scheduleDetail?.detail_list;
    console.log('detailList:', detailList);
    const totalPlaybackDuration = detailList?.reduce((sum, item) => {
        return sum + item.playback_duration;
    }, 0);
    console.log('totalPlaybackDuration:', totalPlaybackDuration);
    const hours = Math.floor(totalPlaybackDuration / 3600);
    const minutes = Math.floor((totalPlaybackDuration % 3600) / 60);
    const seconds = totalPlaybackDuration % 60;

    const [campaignDivs, setCampaignDivs] = useState([
        {
            campaign_id: '',
            // TODO:
            play_id: 0,
            option: 0,
            minute: 0,
            second: 0,
            loop: 0,
            detail_id: 0,
        },
    ]);
    const [startDate, setStartDate] = useState(
        scheduleDetail?.start_date
            ? moment(scheduleDetail?.start_date, 'DD/MMM/YYYY hh:mm a').format()
            : moment(startOfToday),
    );
    const [endDate, setEndDate] = useState(
        scheduleDetail?.start_date
            ? moment(scheduleDetail?.start_date, 'DD/MMM/YYYY hh:mm a').format()
            : moment(endOfToday),
    );
    const [untilDate, setUntilDate] = useState(
        scheduleDetail?.start_date
            ? moment(scheduleDetail?.start_date, 'DD/MMM/YYYY hh:mm a').format()
            : moment(endOfToday),
    );

    // one week duration
    const oneWeekDuration = (date) => {
        // convert to new Date()
        const currentDate = new Date(date);
        return new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 1 week
    };
    // one month duration
    const oneMonthDuration = (date) => {
        const currentDate = new Date(date);

        return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()); // Add 1 month
    };

    useEffect(() => {
        if (scheduleDetail) {
            console.log('*****************************');
            console.log('useEffect => scheduleDetail:', scheduleDetail);
            setCampaignDivs(
                scheduleDetail.detail_list?.map((detail) => ({
                    campaign_id: detail.campaign_id,
                    // TODO:
                    play_id: 0,
                    option: detail.option,
                    minute: detail.minute,
                    second: detail.second,
                    loop: detail.loop,
                    detail_id: detail.detail_id,
                })),
            );
            setStartDate(
                scheduleDetail?.start_date
                    ? moment(scheduleDetail?.start_date, 'DD/MMM/YYYY hh:mm a').format()
                    : moment(startOfToday),
            );
            setEndDate(
                scheduleDetail?.end_date
                    ? moment(scheduleDetail?.end_date, 'DD/MMM/YYYY hh:mm a').format()
                    : moment(endOfToday),
            );
            setUntilDate(
                scheduleDetail?.until_date
                    ? moment(scheduleDetail?.until_date, 'DD/MMM/YYYY').format()
                    : moment(endOfToday),
            );
        }
    }, [scheduleDetail]);

    const handleAddCampaignDiv = () => {
        console.log('Add campaign under construction');
        const newCampaignDivs = [...campaignDivs];
        newCampaignDivs.push({
            campaign_id: '',
            // TODO:
            play_id: 0,
            option: 0,
            minute: 0,
            second: 0,
            loop: 0,
            detail_id: 0,
        });

        setCampaignDivs(newCampaignDivs);
    };

    const handleRemoveDiv = (index) => {
        const newDivs = [...campaignDivs];
        newDivs.splice(index, 1);
        setCampaignDivs(newDivs);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newDivs = [...campaignDivs];
        const [removed] = newDivs.splice(result.source.index, 1);
        newDivs.splice(result.destination.index, 0, removed);
        setCampaignDivs(newDivs);
    };

    const handleStartDate = (newValue) => {
        setStartDate(moment(newValue).format('YYYY-MM-DD HH:mm'));
    };

    const handleEndDate = (newValue) => {
        setEndDate(moment(newValue).format('YYYY-MM-DD HH:mm'));
    };
    const handleUntilDate = (newValue) => {
        setUntilDate(moment(newValue).format('YYYY-MM-DD HH:mm'));
    };

    // Handle Add Campaign schedule
    const saveSchedule = async (schedule_name, schedule_frequency, detail_list) => {
        if (!scheduleDetail) {
            const newList = detail_list.map((x) => {
                const { detail_id, ...rest } = x;
                return rest;
            });
            console.log('newList', newList);
            const schedule_type = 1;
            const data = await AddSchedule(
                switchUser,
                schedule_type,
                locationInfo.group_id,
                schedule_name,
                moment(startDate).format('YYYY-MM-DD HH:mm'),
                moment(endDate).format('YYYY-MM-DD HH:mm'),
                schedule_frequency === 'oneTimeEvent' ? null : moment(untilDate).format('YYYY-MM-DD'),
                schedule_frequency,
                newList,
            );
            console.log('data', data);
            if (data.code === 0) {
                setTriggerUpdate(!triggerUpdate);
                notify.dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'success',
                        message: 'The schedule was added successfully!',
                    },
                });
            } else {
                notify.dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: data.msg ? data.msg : 'The schedule was added unsuccessfully!',
                    },
                });
            }
        } else {
            const data = await UpdateSchedule(
                switchUser,
                scheduleDetail.schedule_id,
                schedule_name,
                moment(startDate).format('YYYY-MM-DD HH:mm'),
                moment(endDate).format('YYYY-MM-DD HH:mm'),
                schedule_frequency === 'oneTimeEvent' ? '' : moment(untilDate).format('YYYY-MM-DD'),
                schedule_frequency,
                detail_list,
            );
            console.log('data', data);
            if (data.code === 0) {
                setTriggerUpdate(!triggerUpdate);
                notify.dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'success',
                        message: 'The schedule was updated successfully!',
                    },
                });
            } else {
                notify.dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: data.msg ? data.msg : 'The schedule was updated unsuccessfully!',
                    },
                });
            }
        }
        handleClose();
    };

    const handleClearState = () => {
        formik.resetForm();
        setStartDate(moment(startOfToday));
        setEndDate(moment(endOfToday));
        setUntilDate(moment(endOfToday));
        setCampaignDivs([{ campaign_id: '', option: 0, minute: 0, second: 0, loop: 0 }]);
    };

    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const handleOpenCopyDialog = () => {
        setOpenCopyDialog(true);
    };

    const handleCloseCopyDialog = () => {
        setOpenCopyDialog(false);
    };

    /***
     *
     *
     * Edit campaign
     *
     *
     */
    const handleEditCampaign = (currentCampaign) => {
        console.log('currentCampaign', currentCampaign);
        localStorage.setItem('editCurrentCampaign', JSON.stringify(currentCampaign));
        navigate('../dashboard/playlist/edit-campaign');
        handleAddMedia(currentCampaign.detail_list);
    };
    const handleAddMedia = (media) => {
        const new_list = media.map(
            ({ effect_duration, element_id, transition_effect, resource_content }) => ({
                effect_duration,
                element_id,
                transition_effect,
                resource_list: resource_content,
            }),
        );
        console.log('new_list:', new_list);
        redux_dispatch(addMedia(new_list));
    };

    /***
     *
     *
     * Handle campaign preview
     *
     *
     */
    const [currentCampaign, setCurrentCampaign] = useState();
    const [currentTemplate, setCurrentTemplate] = useState();
    // const [maxDuration, setMaxDuration] = useState();
    let maxDuration = 0;
    useEffect(() => {
        const detailList = currentCampaign?.detail_list;
        // console.log('detailList:', detailList);
        const elementList = [];

        for (let i = 0; i < detailList?.length; i++) {
            elementList.push(detailList[i].template_element);
        }
        // console.log('elementList 1:', elementList);

        const newList = detailList?.map(({ template_element, ...rest }) => rest);
        elementList.map((element) => {
            const matchedElement = newList.find((x) => x.element_id === element.element_id);
            if (matchedElement) {
                element.detail_list = matchedElement;
                return element;
            }
        });

        setCurrentTemplate(elementList);
    }, [currentCampaign]);

    // console.log('file: ScheduleCampaignDialog.jsx:295 ===> currentCampaign:', currentCampaign);

    const handleCampaignDetails = async (campaign_id) => {
        const data = await getCampaignDetail(switchUser, campaign_id);
        console.log('data', data);
        setCurrentCampaign(data.data);
    };

    useEffect(() => {
        if (campaignDivs?.length >= 1 && campaignDivs[0].campaign_id != '') {
            handleSelectedCampaignPreview(campaignDivs[0].campaign_id);
        }
    }, [campaignDivs]);

    const handleSelectedCampaignPreview = (id) => {
        handleCampaignDetails(id);
    };

    // New close dialog function
    const handleClose = () => {
        handleCloseScheduleDialog();
        handleClearState();
        setCurrentCampaign();
    };

    /***
     *
     * Delete dialog
     *
     */
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const [popUpInfo, setPopUpInfo] = useState();
    const [type, setType] = useState('schedule');

    const handleOpenDeleteDialog = (item) => {
        console.log('item', item);
        setPopUpInfo();

        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} open={openScheduleDialog}>
            <DialogTitle>
                {`Location name: ${locationInfo?.locationName}`}{' '}
                {locationDetail && (
                    <span
                        className={`ml-3 inline-block items-center px-1 text-[11px] uppercase rounded-[3px] bg-blue-200 text-white-600 font-bold`}
                    >
                        {locationDetail?.group_info?.target_layout === 1 ? 'Landscape' : 'Portrait'}
                    </span>
                )}
            </DialogTitle>
            <DialogContent style={{ padding: '14px' }}>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        error={Boolean(formik.touched.group_name && formik.errors.group_name)}
                        fullWidth
                        helperText={formik.touched.group_name && formik.errors.group_name}
                        label="Schedule Name"
                        margin="normal"
                        name="group_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.group_name}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div className="grid grid-cols-2 grid-flow-col gap-3">
                        <div
                            className="row-span-1 border-1 border-blue-400 rounded"
                            style={{ minWidth: '400px' }}
                        >
                            <div className="h-8 bg-blue-300 p-1 pl-2 text-center ">
                                <p className="font-bold text-blue-700">Set Schedule</p>
                            </div>
                            <div className="flex flex-row w-full p-3">
                                <div className={formik.values.repeat !== 'oneTimeEvent' ? 'mr-2' : 'w-full'}>
                                    <TextField
                                        sx={{
                                            marginTop: '0',
                                            minWidth: '183px',
                                        }}
                                        error={Boolean(formik.touched.repeat && formik.errors.repeat)}
                                        fullWidth
                                        select
                                        helperText={formik.touched.repeat && formik.errors.repeat}
                                        label="Repeat"
                                        margin="normal"
                                        name="repeat"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.repeat}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        {scheduleFrequencyList?.map((option, index) => (
                                            <MenuItem
                                                key={option.schedule_frequency_name}
                                                value={option.schedule_frequency}
                                            >
                                                {option.schedule_frequency_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                {formik.values.repeat !== 'oneTimeEvent' ? (
                                    <div className="w-full">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DesktopDatePicker
                                                label="Until"
                                                value={untilDate}
                                                minDate={endDate}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={handleUntilDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="flex flex-row px-3 pb-3 w-full">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <DateTimePicker
                                                label="Start"
                                                name="start_date"
                                                fullWidth
                                                inputFormat={
                                                    formik.values.repeat === 'daily'
                                                        ? 'DD/MM/YYYY HH:mm'
                                                        : 'DD/MM/YYYY HH:mm'
                                                }
                                                value={startDate}
                                                onChange={handleStartDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </div>
                                        <div>
                                            <DateTimePicker
                                                label="End"
                                                fullWidth
                                                name="end_date"
                                                inputFormat={
                                                    formik.values.repeat === 'daily'
                                                        ? 'DD/MM/YYYY HH:mm'
                                                        : 'DD/MM/YYYY HH:mm'
                                                }
                                                value={endDate}
                                                disablePast={true}
                                                maxDate={
                                                    formik.values.repeat !== 'oneTimeEvent'
                                                        ? formik.values.repeat === 'daily'
                                                            ? endDate
                                                            : formik.values.repeat === 'weekly'
                                                            ? oneWeekDuration(startDate)
                                                            : formik.values.repeat === 'monthly'
                                                            ? oneMonthDuration(startDate)
                                                            : false
                                                        : false
                                                }
                                                onChange={handleEndDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </div>
                                    </div>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <SelectedCampaignSec
                            // layout={locationDetail?.group_info?.target_layout}
                            handleDragEnd={handleDragEnd}
                            campaignDivs={campaignDivs}
                            setCampaignDivs={setCampaignDivs}
                            campaigns={campaigns}
                            handleSelectedCampaignPreview={handleSelectedCampaignPreview}
                            currentCampaign={currentCampaign}
                            handleAddCampaignDiv={handleAddCampaignDiv}
                            handleRemoveDiv={handleRemoveDiv}
                            layout={locationDetail?.group_info?.target_layout}
                        />

                        <PreviewCampaignSec
                            currentCampaign={currentCampaign}
                            maxDuration={maxDuration}
                            campaignDivs={campaignDivs}
                            setCampaignDivs={setCampaignDivs}
                            currentTemplate={currentTemplate}
                            totalPlaybackDuration={totalPlaybackDuration}
                            hours={hours}
                            minutes={minutes}
                            seconds={seconds}
                        />
                    </div>
                    <DialogActions>
                        <div className={`flex w-full ${scheduleDetail ? 'justify-between' : 'justify-end'}`}>
                            {scheduleDetail && (
                                <div>
                                    <Button
                                        className="mx-1"
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleOpenDeleteDialog(scheduleDetail.schedule_id)}
                                    >
                                        Delete Schedule
                                    </Button>
                                    <Button
                                        className="mx-1"
                                        variant="contained"
                                        onClick={() => handleOpenCopyDialog()}
                                    >
                                        Copy Schedule
                                    </Button>
                                    <CopyScheduleDialog
                                        openCopyDialog={openCopyDialog}
                                        locationList={locationList}
                                        scheduleDetail={scheduleDetail}
                                        currentUser={currentUser}
                                        setTriggerUpdate={setTriggerUpdate}
                                        triggerUpdate={triggerUpdate}
                                        handleCloseCopyDialog={handleCloseCopyDialog}
                                    />
                                    {openDeleteDialog && (
                                        <DeleteDialog
                                            openDialog={openDeleteDialog}
                                            handleCloseDialog={handleCloseDeleteDialog}
                                            setTriggerUpdate={setTriggerUpdate}
                                            text={type}
                                            deleteItem={deleteItem}
                                            triggerUpdate={triggerUpdate}
                                            // expanded={expanded}
                                            switchUser={switchUser}
                                            popUpInfo={popUpInfo}
                                            setPopUpInfo={setPopUpInfo}
                                            handleClose={handleClose}
                                        />
                                    )}
                                </div>
                            )}
                            <div>
                                <Button className="mx-1" variant="contained" onClick={() => handleClose()}>
                                    Cancel
                                </Button>
                                {/* <Button className='mx-1' variant='contained' onClick={() => handleEditCampaign(currentCampaign)}>
									Edit Campaign
								</Button> */}

                                <Button className="mx-1" variant="contained" type="submit">
                                    Save & Close
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ScheduleCampaignDialog;
