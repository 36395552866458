import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useRef, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';

const MediaWidget = ({ item, media, selectedResizeMode }) => {
    const carouselRef = useRef(null);
    const videoRef = useRef(null);
    const intervalIdRef = useRef(null); // 新增这一行
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    console.log('file: MediaWidget.jsx:7 => MediaWidget => item:', item);
    console.log('file: MediaWidget.jsx:7 => MediaWidget => media:', media);
    const getStyle = () => ({
        objectFit:
            selectedResizeMode === 1
                ? 'contain'
                : selectedResizeMode === 2
                ? 'none'
                : selectedResizeMode === 3
                ? 'none'
                : selectedResizeMode === 4
                ? 'fill'
                : 'cover',
        width: selectedResizeMode === 2 ? '100%' : selectedResizeMode === 3 ? 'auto' : '100%',
        height: selectedResizeMode === 2 ? 'auto' : selectedResizeMode === 3 ? '100%' : '100%',
    });

    // Enable auto play the video
    const handleSelect = (selectedIndex, e) => {
        console.log('file: MediaWidget.jsx:30 => handleSelect => selectedIndex:', selectedIndex);
        setActiveIndex(selectedIndex);
        console.log('=============*********** 1 ==========');

        const activeMedia = media?.find((x) => x.element_id === item.element_id);
        console.log('file: MediaWidget.jsx:32 => handleSelect => activeMedia:', activeMedia);
        const selectedResource = activeMedia?.resource_list[selectedIndex];

        console.log('Selected Resource:', selectedResource);
        console.log('=============*********** 2 ==========');

        if (selectedResource && selectedResource.resource_type === 'video') {
            // Play the video
            setCurrentVideoIndex(selectedIndex);
            // const videoElement = document.getElementById(`video-${selectedResource.resource_id}`);
            const videoElement = videoRef.current;
            if (videoElement) {
                console.log('=============*********** 3 ==========');
                console.log('file: MediaWidget.jsx:41 => handleSelect => videoElement:', videoElement);
                selectedIndex >= 1 && videoElement.pause();
                videoElement.currentTime = 0; // Reset video time to start
                videoElement.play();
            }
        }
    };

    const handleVideoEnded = () => {
        console.log('==================================');
        console.log('进入了视频结束block！！！');
        console.log('==================================');
        // Check if the last video has ended before transitioning to the next item
        const nextIndex = currentVideoIndex + 1;
        if (nextIndex < media[0].resource_list.length) {
            const nextResource = media[0].resource_list[nextIndex];

            // Check if the next resource is a video
            if (nextResource.resource_type === 'video') {
                carouselRef.current?.next();
            } else {
                // If the next resource is an image, reset the video index and trigger the autoplay logic
                setCurrentVideoIndex(0);

                // Handle autoplay for images
                const duration =
                    nextResource.resource_type === 'image' ? nextResource.resource_duration * 1000 : null;

                clearInterval(intervalIdRef.current);

                if (duration) {
                    intervalIdRef.current = setInterval(() => {
                        carouselRef.current?.next();
                    }, duration);
                }
            }
        } else {
            // If the last video has ended, reset the index to 0
            setCurrentVideoIndex(0);
        }
    };

    useEffect(() => {
        setActiveIndex(0);
        setCurrentVideoIndex(0);

        // Play the video for the first index
        const firstVideo = media[currentVideoIndex]?.resource_list?.find(
            (resource) => resource.resource_type === 'video',
        );

        if (firstVideo) {
            const firstVideoElement = document.getElementById(
                `video-${firstVideo.resource_id}-${activeIndex}`,
            );
            if (firstVideoElement) {
                console.log('useEffect => firstVideoElement:', firstVideoElement);
                firstVideoElement.pause();
                firstVideoElement.currentTime = 0;
                firstVideoElement.play();
            }
        }
    }, [media]);

    useEffect(() => {
        const activeMedia = media?.find((x) => x.element_id === item.element_id);
        const selectedResource = activeMedia?.resource_list[activeIndex];

        if (selectedResource) {
            if (selectedResource.resource_type === 'video') {
                setCurrentVideoIndex(activeIndex);

                const videoElement = document.getElementById(
                    `video-${selectedResource.resource_id}-${activeIndex}`,
                );
                if (videoElement) {
                    videoElement.pause();
                    videoElement.currentTime = 0;
                    videoElement.play();
                }
            }

            // Check if it's an image, and handle autoplay based on the resource type
            if (selectedResource.resource_type === 'image') {
                const duration = selectedResource.resource_duration * 1000;
                const intervalId = setInterval(() => {
                    carouselRef.current?.next();
                }, duration);

                return () => clearInterval(intervalId);
            }
        }
    }, [activeIndex, media, item.element_id]);

    return (
        <Carousel
            ref={carouselRef}
            onSelect={handleSelect}
            activeIndex={activeIndex}
            className="absolute h-full w-full"
            indicators={false}
            controls={false}
            interval={null}
            fade={
                item.element_type === 'image' || item.element_type === 'mix'
                    ? media.find((x) => x.element_id === item.element_id)?.transition_effect === 1
                    : false
            }
            slide={
                item.element_type === 'image' || item.element_type === 'mix'
                    ? media.find((x) => x.element_id === item.element_id)?.transition_effect !== 1
                    : false
            }
        >
            {media?.length > 0 &&
                media
                    ?.filter((r) =>
                        r?.resource_list?.some(
                            (e) =>
                                e.resource_type === 'audio' ||
                                e.resource_type === 'video' ||
                                e.resource_type === 'image',
                        ),
                    )
                    ?.map((x) =>
                        x?.resource_list?.map(
                            (m, index) =>
                                m.resource_id &&
                                x.element_id === item.element_id && (
                                    <Carousel.Item
                                        key={`${m.resource_id}-${index}`}
                                        className="absolute h-full w-full flex items-center justify-center"
                                        style={{
                                            transition: `transform ${x.effect_duration}s ease`,
                                        }}
                                    >
                                        {m.resource_type === 'image' ? (
                                            <img
                                                className="absolute"
                                                style={getStyle()}
                                                src={m.resource_url}
                                            />
                                        ) : m.resource_type === 'video' ? (
                                            <video
                                                ref={videoRef}
                                                id={`video-${m.resource_id}-${index}`}
                                                loading="lazy"
                                                className="items-center"
                                                style={getStyle()}
                                                controls
                                                muted // Add the muted attribute
                                                onEnded={handleVideoEnded}
                                                src={m.resource_url}
                                            />
                                        ) : m.resource_type === 'audio' ? (
                                            <audio
                                                id={`audio-${m.resource_id}-${index}`}
                                                loading="lazy"
                                                className="items-center"
                                                style={getStyle()}
                                                controls
                                                src={m.resource_url}
                                            />
                                        ) : null}
                                    </Carousel.Item>
                                ),
                        ),
                    )}
        </Carousel>
    );
};

export default MediaWidget;
