import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import React from 'react';

const WebHeader = () => {
    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: 'white', color: 'black' }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '1.25rem', // Adjust size as needed
                                    color: '#0444AB', // Use your primary color
                                }}
                            >
                                <img
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png" // Your image URL
                                    alt="App Icon"
                                    style={{
                                        width: '50px', // Adjust size as needed
                                        height: '50px', // Adjust size as needed
                                        marginRight: '10px', // Space between icon and text
                                    }}
                                />
                                Sign Inspire
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                            <Button sx={{ color: 'black' }} component={Link} to="/">
                                Home
                            </Button>
                            <Button sx={{ color: 'black' }} component={Link} to="/installation">
                                Installation
                            </Button>
                            <Button sx={{ color: 'black' }} component={Link} to="/pricing">
                                Pricing
                            </Button>
                            <Button
                                sx={{ color: 'black', borderColor: 'black' }}
                                component={Link}
                                to="/contact"
                            >
                                Contact Us
                            </Button>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: 'flex' }}>
                            <Button
                                style={{ borderColor: '#0444AB', color: '#0444AB' }}
                                variant="outlined"
                                component={Link}
                                to="/login"
                            >
                                Log In
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/signup"
                                style={{ backgroundColor: '#0444AB', marginLeft: '10px' }}
                            >
                                Free Trial
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};

export default WebHeader;
