import {
    Box,
    Button,
    FormControlLabel,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Switch,
    Typography,
} from '@mui/material';
import { GetCyberpoint, GetPriceList, Getalldevice } from './payment/Api';
import React, { useEffect, useState } from 'react';

import { DeviceSummary } from './payment';
import { Header } from '../';
import PaymentSec from './PaymentSec';
import SelectPlanSec from './SelectPlanSec';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';

const steps = ['Select plan', 'Select device', 'Payment'];

const SubscriptionMain = () => {
    const { currentUser } = useStateContext();
    const navigate = useNavigate();

    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [isBusinessCustomer, setIsBusinessCustomer] = useState(
        userDetails.customer_type === 2 ? true : false,
    );

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const [price_list, setprice_list] = useState({
        BS360: 10,
        BS180: 10,

        GS180: 10,
        GS360: 10,
    });
    const [selectedPlan, setSelectedPlan] = useState('');
    console.log('SubscriptionMain => selectedPlan:', selectedPlan);
    const [selectedType, setSelectedType] = useState('');
    console.log('SubscriptionMain => selectedType:', selectedType);
    const [selectedPrice, setSelectedPrice] = useState('');
    const handleNext = (type, checked) => {
        if (checked) navigate('/dashboard/subscription');
        // console.log('handleNext => price:', price);
        // console.log('handleNext => type:', type);
        setSelectedType(type);
        // setSelectedPrice(price.split('$')[1]);
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [devices, setdevices] = useState([]);
    const getDeviceList = async () => {
        //console.log(abc)

        const data = await Getalldevice(currentUser.user_id);
        console.log('GetDeviceList:', data.message);

        const total = data.message;
        total.map((dev) => {
            dev.type = '1 year';
        });

        setdevices(total);
    };

    const getstartdate = (expire_time) => {
        const x = moment.unix(expire_time);
        const y = moment();

        if (x > y) {
            return x.format('DD/MM/YYYY');
        } else {
            return y.format('DD/MM/YYYY');
        }
    };
    const getenddate = (expire_time, type) => {
        const enddate = () => {
            const a = moment.unix(expire_time);
            const b = moment();
            if (a > b) {
                return a;
            }
            return b;
        };

        if (type === '3 months') {
            return enddate().add(3, 'month').format('DD/MM/YYYY');
        }
        if (type === '6 months') {
            return enddate().add(6, 'month').format('DD/MM/YYYY');
        }
        if (type === '1 year') {
            return enddate().add(1, 'year').format('DD/MM/YYYY');
        }
    };
    const unitprice = (type, dev) => {
        if (type === '6 months') {
            if (isBusinessCustomer == true) {
                dev['price'] = price_list.BS180;
                return dev['price'];
            } else {
                dev['price'] = price_list.GS180;
                return dev['price'];
            }
        }
        if (type === '1 year') {
            if (isBusinessCustomer == true) {
                dev['price'] = price_list.BS360;
                return dev['price'];
            } else {
                dev['price'] = price_list.GS360;
                return dev['price'];
            }
        }
    };

    const [selectedrows, setSelectedrows] = useState([]);
    console.log('file: DeviceSummary.jsx:185 => DeviceSummary => selectedrows:', selectedrows);
    const handleRenewTypeChange = (id, type) => {
        const updatedRows = [...devices];
        const rowToUpdate = updatedRows.find((row) => row.id === id);
        rowToUpdate.type = type;
        rowToUpdate.new_end = getenddate(rowToUpdate.expire_time, type);

        // Update the selectedrows state based on the updated renew type

        setdevices(updatedRows);
        console.log(`Row ${id} renew type changed to ${type}`);
        console.log('row to update', rowToUpdate);
        console.log('update row', updatedRows);
    };

    const [totalPrice, setTotalPrice] = useState();
    // const totalPrice = selectedrows.reduce((accumulator, row) => accumulator + row.price, 0);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        console.log('file: DeviceSummary.jsx:206 => handleChange => checked:', checked);

        if (checked) {
            let tempUser = devices.map((dev) => {
                if (dev.subscription === 'true') {
                    dev['new_start'] = getstartdate(dev.expire_time);
                    dev['new_end'] = getenddate(dev.expire_time, dev.type);

                    return { ...dev, selected: !checked };
                } else {
                    dev['new_start'] = getstartdate(dev.expire_time);
                    dev['new_end'] = getenddate(dev.expire_time, dev.type);

                    return { ...dev, selected: checked };
                }
            });
            setdevices(tempUser);
            if (tempUser.filter((x) => x.selected === true)) {
                setSelectedrows(tempUser.filter((x) => x.selected === true));
            } else {
                setSelectedrows([]);
            }

            console.log('tempUser', tempUser);
        } else {
            let tempUser = devices.map((dev) => ({
                ...dev,
                selected: checked,
            }));
            setdevices(tempUser);
            if (tempUser.filter((x) => x.selected === true)) {
                setSelectedrows(tempUser.filter((x) => x.selected === true));
            } else {
                setSelectedrows([]);
            }

            console.log('tempUser', tempUser);
        }
    };

    const handleRSelect = (dev, allrow) => {
        const updatedRows = [...devices];
        const selectedRow = updatedRows.find((row) => row.id === dev.id);
        selectedRow.selected = !selectedRow.selected;
        setdevices(updatedRows);

        if (selectedRow.selected === true) {
            dev['new_start'] = getstartdate(dev.expire_time);
            dev['new_end'] = getenddate(dev.expire_time, dev.type);

            setSelectedrows([...selectedrows, dev]);
        } else {
            setSelectedrows(selectedrows.filter((r) => r !== dev));
        }
        //console.log("rrrrrrrrrrrrrrrrr", updatedRows);
    };

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
    const [checked, setChecked] = useState(true);

    console.log('SubscriptionMain => selectedPlanIndex:', selectedPlanIndex);

    return (
        <div className="m-10 md:m-10 mt-8 p-2 md:p-10">
            {/* <Header title="Account Manager" /> */}
            <div>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        // if (isStepOptional(index)) {
                        //     labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        // }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                                {/* <StepContent> */}
                                {/* <Typography>aaaa</Typography> */}
                                {/* <Box sx={{ mb: 2 }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                            </Button>
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box> */}
                                {/* </StepContent> */}
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                        <Box>
                            {activeStep === 0 ? (
                                <>
                                    {/* <div className="flex flex-row justify-center mt-2">
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>One Off</Typography>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={checked}
                                                        onChange={handleChangeSwitch}
                                                        name="custom-switch"
                                                    />
                                                }
                                            />
                                            <Typography>Monthly</Typography>
                                        </Stack>
                                    </div> */}
                                    <SelectPlanSec
                                        handleNext={handleNext}
                                        userDetails={userDetails}
                                        setSelectedPlan={setSelectedPlan}
                                        selectedPlan={selectedPlan}
                                        checked={checked}
                                        setChecked={setChecked}
                                        currentUser={currentUser}
                                        switchUser={switchUser}
                                        isSubscribed={isSubscribed}
                                        setIsSubscribed={setIsSubscribed}
                                        selectedPlanIndex={selectedPlanIndex}
                                        setSelectedPlanIndex={setSelectedPlanIndex}
                                    />
                                </>
                            ) : (
                                <div className="w-full">
                                    <DeviceSummary
                                        user_id={switchUser ? switchUser : currentUser.user_id}
                                        customerType={userDetails.customer_type}
                                        username={userDetails.username}
                                        userDetails={userDetails}
                                        handleNext={handleNext}
                                        handleBack={handleBack}
                                        selectedType={selectedPlanIndex}
                                        // selectedPrice={selectedPrice}
                                    />
                                </div>
                            )}
                        </Box>
                        {activeStep === 1 && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginX: '25px' }}>
                                <Button
                                    color={activeStep === 0 ? 'inherit' : 'primary'}
                                    variant={activeStep === 0 ? 'outlined' : 'contained'}
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                            </Box>
                        )}
                        {activeStep === 0 && (
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginX: '25px' }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button
                                        onClick={() => handleNext(selectedPlanIndex, checked)}
                                        variant="contained"
                                        disabled={
                                            isSubscribed && selectedPlanIndex !== userDetails.customer_type
                                        }
                                    >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginX: '25px' }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {isSubscribed && (
                                        <div className="mt-2">
                                            <p className="text-red-500 font-semibold text-xs"></p>
                                        </div>
                                    )}
                                </Box>
                            </>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default SubscriptionMain;
