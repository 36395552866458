import { Autocomplete, Box, Button, Chip, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import { CreateImages } from './Api';
import DownloadIcon from '@mui/icons-material/Download';
import { HexColorPicker } from 'react-colorful';
import LinearProgress from '@mui/material/LinearProgress';

const initialThemes = [
    'Office Environment',
    'Retail Space',
    'Outdoor Advertising',
    'Event Promotion',
    'Healthcare Facilities',
    'Education Institutions',
    'Hospitality Industry',
    'Other',
];

const initialAreas = [
    'Lobby',
    'Conference Room',
    'Reception',
    'Waiting Area',
    'Outdoor Billboard',
    'In-store Display',
    'Other',
];

const initialStyles = ['Modern', 'Minimalist', 'Vibrant', 'Professional', 'Other'];

const initialDesignElements = ['Icons', 'Text Overlays', 'Borders', 'Other'];

const initialAudiences = ['Female', 'Male', 'Children', 'Teenagers', 'Adults', 'Seniors', 'Other'];

const initialImageModes = ['Landscape', 'Portrait', 'Square'];

const ImageGenerate = () => {
    const [value, setValue] = useState(0); // State for the current tab
    const [summaryDescription, setSummaryDescription] = useState('');
    const [primaryColor, setPrimaryColor] = useState('#000000');
    const [secondaryColor, setSecondaryColor] = useState('#ffffff');
    const [tertiaryColor, setTertiaryColor] = useState('#ffffff'); // New state for tertiary color
    const [showPrimaryPicker, setShowPrimaryPicker] = useState(false);
    const [showSecondaryPicker, setShowSecondaryPicker] = useState(false);
    const [showTertiaryPicker, setShowTertiaryPicker] = useState(false); // New state for tertiary picker
    const [themes, setThemes] = useState(initialThemes); // State for themes
    const [areas, setAreas] = useState(initialAreas); // State for areas
    const [styles, setStyles] = useState(initialStyles); // State for styles
    const [designElements, setDesignElements] = useState(initialDesignElements); // State for design elements
    const [audiences, setAudiences] = useState(initialAudiences); // State for audiences
    const [imageModes] = useState(initialImageModes); // State for image modes
    const [selectedThemes, setSelectedThemes] = useState([]); // State for selected themes
    const [selectedArea, setSelectedArea] = useState(null); // State for selected area
    const [selectedStyle, setSelectedStyle] = useState(null); // State for selected style
    const [selectedDesignElements, setSelectedDesignElements] = useState([]); // State for selected design elements
    const [selectedAudiences, setSelectedAudiences] = useState([]); // State for selected audiences
    const [selectedImageMode, setSelectedImageMode] = useState(null); // State for selected image mode
    const [background, setBackground] = useState(''); // State for background
    const [text, setText] = useState(''); // State for text
    const [font, setFont] = useState(''); // State for font
    const [otherInput, setOtherInput] = useState(''); // State for other input
    const [showOtherInput, setShowOtherInput] = useState(false); // State to control display of other input field
    const [otherContext, setOtherContext] = useState(''); // State to track which other input is active
    const [processing, setProcessing] = useState(false);
    const [responseURL, setResponseURL] = useState('');
    const [warning, setWarning] = useState(false);
    const [error, setError] = useState(''); // State for error message

    // const user_id = JSON.parse(localStorage.getItem('userDetails')).user_id;
    const user_id = JSON.parse(localStorage.getItem('userDetails'))?.user_id;
    // Update the handleButtonClick function to include the summary description validation
    const handleButtonClick = async (useCustomColors = false) => {
        if (summaryDescription && (!useCustomColors || (primaryColor && secondaryColor && tertiaryColor))) {
            setProcessing(true);
            setError('');
            setWarning(false);

            const body = {
                user_id: user_id,
                prompt: summaryDescription,
                ...(useCustomColors && {
                    primary_color: primaryColor,
                    secondary_color: secondaryColor,
                    tertiary_color: tertiaryColor,
                }),
                themes: selectedThemes.length ? selectedThemes : [],
                area: selectedArea ? [selectedArea] : [],
                style: selectedStyle ? [selectedStyle] : [],
                design_elements: selectedDesignElements.length ? selectedDesignElements : [],
                background: background,
                audiences: selectedAudiences.length ? selectedAudiences : [],
                text: text,
                font: font,
                image_mode: selectedImageMode,
            };

            console.log('body', body);

            try {
                const data = await CreateImages(body);
                setResponseURL(data['message']);
                setProcessing(false);
            } catch (error) {
                console.error('Error while processing:', error);
                setError('System busy, please try again later.');
                setProcessing(false);
            }
        } else {
            setWarning(true);
        }
    };

    const handleDownload = () => {
        if (responseURL) {
            const link = document.createElement('a');
            link.href = responseURL;
            link.download = 'generated_image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOtherInputChange = (event) => {
        setOtherInput(event.target.value);
    };

    const handleOtherInputSubmit = () => {
        if (showOtherInput) {
            if (otherContext === 'theme') {
                setThemes([...themes.filter((theme) => theme !== 'Other'), otherInput, 'Other']);
                setSelectedThemes([...selectedThemes.filter((theme) => theme !== 'Other'), otherInput]);
            } else if (otherContext === 'area') {
                setAreas([...areas.filter((area) => area !== 'Other'), otherInput, 'Other']);
                setSelectedArea(otherInput);
            } else if (otherContext === 'style') {
                setStyles([...styles.filter((style) => style !== 'Other'), otherInput, 'Other']);
                setSelectedStyle(otherInput);
            } else if (otherContext === 'designElement') {
                setDesignElements([
                    ...designElements.filter((element) => element !== 'Other'),
                    otherInput,
                    'Other',
                ]);
                setSelectedDesignElements([
                    ...selectedDesignElements.filter((element) => element !== 'Other'),
                    otherInput,
                ]);
            } else if (otherContext === 'audience') {
                setAudiences([...audiences.filter((audience) => audience !== 'Other'), otherInput, 'Other']);
                setSelectedAudiences([
                    ...selectedAudiences.filter((audience) => audience !== 'Other'),
                    otherInput,
                ]);
            }
            setOtherInput('');
            setShowOtherInput(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Quick Start" />
                <Tab label="Custom Settings" />
            </Tabs>
            {value === 0 && (
                <Box sx={{ textAlign: 'center' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="A butterfly on the flower"
                        value={summaryDescription}
                        onChange={(e) => {
                            setSummaryDescription(e.target.value);
                            setWarning(false);
                        }}
                        sx={{ my: 2 }}
                    />
                    {warning && <Alert severity="error">Invalid Input</Alert>}
                    <Button variant="contained" color="primary" onClick={() => handleButtonClick(false)}>
                        Generate Image
                    </Button>
                    {processing ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        responseURL && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 4,
                                }}
                            >
                                <img
                                    src={responseURL}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px' }}
                                />
                                <IconButton color="secondary" onClick={handleDownload} sx={{ ml: 2 }}>
                                    <DownloadIcon />
                                </IconButton>
                            </Box>
                        )
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                </Box>
            )}
            {value === 1 && (
                <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h8" sx={{ mb: 1 }}>
                                Primary Color
                            </Typography>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '40px',
                                    backgroundColor: primaryColor,
                                    border: '1px solid #000',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowPrimaryPicker((show) => !show)}
                            />
                            {showPrimaryPicker && (
                                <Box sx={{ position: 'absolute', zIndex: 2 }}>
                                    <Box
                                        sx={{
                                            position: 'fixed',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        onClick={() => setShowPrimaryPicker(false)}
                                    />
                                    <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h8" sx={{ mb: 1 }}>
                                Secondary Color
                            </Typography>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '40px',
                                    backgroundColor: secondaryColor,
                                    border: '1px solid #000',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowSecondaryPicker((show) => !show)}
                            />
                            {showSecondaryPicker && (
                                <Box sx={{ position: 'absolute', zIndex: 2 }}>
                                    <Box
                                        sx={{
                                            position: 'fixed',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        onClick={() => setShowSecondaryPicker(false)}
                                    />
                                    <HexColorPicker color={secondaryColor} onChange={setSecondaryColor} />
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h8" sx={{ mb: 1 }}>
                                Tertiary Color
                            </Typography>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '40px',
                                    backgroundColor: tertiaryColor,
                                    border: '1px solid #000',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowTertiaryPicker((show) => !show)}
                            />
                            {showTertiaryPicker && (
                                <Box sx={{ position: 'absolute', zIndex: 2 }}>
                                    <Box
                                        sx={{
                                            position: 'fixed',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        onClick={() => setShowTertiaryPicker(false)}
                                    />
                                    <HexColorPicker color={tertiaryColor} onChange={setTertiaryColor} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'left',
                            my: 2,
                            '& .MuiTypography-asterisk': {
                                color: 'red', // Change to your desired color
                            },
                        }}
                    >
                        Summary Description of the Advertisement
                        <span className="MuiTypography-asterisk">*</span>
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Summary Description"
                        placeholder="Enter a brief summary of your advertisement"
                        value={summaryDescription}
                        onChange={(e) => setSummaryDescription(e.target.value)}
                        sx={{
                            my: 2,
                            '& .MuiFormLabel-asterisk': {
                                color: 'red', // Change to your desired color
                            },
                        }}
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        What kind of Themes in your advertisement?
                    </Typography>
                    <Autocomplete
                        multiple
                        freeSolo
                        options={themes}
                        value={selectedThemes}
                        onChange={(event, newValue) => {
                            if (newValue.includes('Other')) {
                                setOtherContext('theme');
                                setShowOtherInput(true);
                            } else {
                                setSelectedThemes(newValue);
                            }
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Themes"
                                placeholder="Themes"
                            />
                        )}
                        sx={{ my: 2 }}
                    />
                    {showOtherInput && otherContext === 'theme' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Other Theme"
                                placeholder="Enter other theme"
                                value={otherInput}
                                onChange={handleOtherInputChange}
                                sx={{ mr: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleOtherInputSubmit}>
                                Add
                            </Button>
                        </Box>
                    )}
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        Specify the area or context where the image will be displayed
                    </Typography>
                    <Autocomplete
                        freeSolo
                        options={areas}
                        value={selectedArea}
                        onChange={(event, newValue) => {
                            if (newValue === 'Other') {
                                setOtherContext('area');
                                setShowOtherInput(true);
                            } else {
                                setSelectedArea(newValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Area"
                                placeholder="Area"
                            />
                        )}
                        sx={{ my: 2 }}
                    />
                    {showOtherInput && otherContext === 'area' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Other Area"
                                placeholder="Enter other area"
                                value={otherInput}
                                onChange={handleOtherInputChange}
                                sx={{ mr: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleOtherInputSubmit}>
                                Add
                            </Button>
                        </Box>
                    )}
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        Specify the overall style
                    </Typography>
                    <Autocomplete
                        freeSolo
                        options={styles}
                        value={selectedStyle}
                        onChange={(event, newValue) => {
                            if (newValue === 'Other') {
                                setOtherContext('style');
                                setShowOtherInput(true);
                            } else {
                                setSelectedStyle(newValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Style"
                                placeholder="Style"
                            />
                        )}
                        sx={{ my: 2 }}
                    />
                    {showOtherInput && otherContext === 'style' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Other Style"
                                placeholder="Enter other style"
                                value={otherInput}
                                onChange={handleOtherInputChange}
                                sx={{ mr: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleOtherInputSubmit}>
                                Add
                            </Button>
                        </Box>
                    )}
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        Mention any specific design elements
                    </Typography>
                    <Autocomplete
                        multiple
                        freeSolo
                        options={designElements}
                        value={selectedDesignElements}
                        onChange={(event, newValue) => {
                            if (newValue.includes('Other')) {
                                setOtherContext('designElement');
                                setShowOtherInput(true);
                            } else {
                                setSelectedDesignElements(newValue);
                            }
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Design Elements"
                                placeholder="Design Elements"
                            />
                        )}
                        sx={{ my: 2 }}
                    />
                    {showOtherInput && otherContext === 'designElement' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Other Design Element"
                                placeholder="Enter other design element"
                                value={otherInput}
                                onChange={handleOtherInputChange}
                                sx={{ mr: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleOtherInputSubmit}>
                                Add
                            </Button>
                        </Box>
                    )}
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        Background Description
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Background"
                        placeholder="A modern office with people working at desks"
                        value={background}
                        onChange={(e) => setBackground(e.target.value)}
                        sx={{ my: 2 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        Who will see this advertisement?
                    </Typography>
                    <Autocomplete
                        multiple
                        freeSolo
                        options={audiences}
                        value={selectedAudiences}
                        onChange={(event, newValue) => {
                            if (newValue.includes('Other')) {
                                setOtherContext('audience');
                                setShowOtherInput(true);
                            } else {
                                setSelectedAudiences(newValue);
                            }
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Audience"
                                placeholder="Audience"
                            />
                        )}
                        sx={{ my: 2 }}
                    />
                    {showOtherInput && otherContext === 'audience' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Other Audience"
                                placeholder="Enter other audience"
                                value={otherInput}
                                onChange={handleOtherInputChange}
                                sx={{ mr: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleOtherInputSubmit}>
                                Add
                            </Button>
                        </Box>
                    )}
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        What text will show in this advertisement?
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Text"
                        placeholder="Welcome to Our Office"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        sx={{ my: 2 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
                        Font of the text
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Font"
                        placeholder="Clean sans-serif"
                        value={font}
                        onChange={(e) => setFont(e.target.value)}
                        sx={{ my: 2 }}
                        InputLabelProps={{ shrink: true }}
                    />

                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'left',
                            my: 2,
                            '& .MuiTypography-asterisk': {
                                color: 'red', // Change to your desired color
                            },
                        }}
                    >
                        Select Image Mode
                        <span className="MuiTypography-asterisk">*</span>
                    </Typography>
                    <Autocomplete
                        options={imageModes}
                        value={selectedImageMode}
                        onChange={(event, newValue) => setSelectedImageMode(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Image Mode"
                                placeholder="Image Mode"
                            />
                        )}
                        sx={{ my: 2 }}
                    />
                    {warning && <Alert severity="error">Invalid Input or Missing Colors</Alert>}
                    <Button variant="contained" color="primary" onClick={() => handleButtonClick(true)}>
                        Generate Image
                    </Button>
                    {processing ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        responseURL && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 4,
                                }}
                            >
                                <img
                                    src={responseURL}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px' }}
                                />
                                <IconButton color="secondary" onClick={handleDownload} sx={{ ml: 2 }}>
                                    <DownloadIcon />
                                </IconButton>
                            </Box>
                        )
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                </Box>
            )}
        </Box>
    );
};

export default ImageGenerate;
