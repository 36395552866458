import { Accordion, AccordionDetails, AccordionSummary } from '../Accordion';
import {
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    IconButton,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
// GetCampaignPlaybackGroupList;
import { CopyDialog, DeleteDialog, Pulse } from '../utils';
import { FiCopy, FiTrash2 } from 'react-icons/fi';
import { GrNext, GrSystem } from 'react-icons/gr';
import { HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi';
import React, { useRef, useState } from 'react';
import { deleteCampaign, getCampaignDetail } from '../../../api/api';

import CampaignDetailDialog from './CampaignDetailDialog';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { styled } from '@mui/material/styles';

const CampaignList = ({
    item,
    locationList,
    expanded,
    type,
    currentUser,
    triggerUpdate,
    setTriggerUpdate,
    switchUser,
    // handleDeleteCampaign,
}) => {
    // console.log('type:', type);
    // console.log('expanded:', expanded);
    // Handle Expand area
    const [innerExpanded, setInnerExpanded] = useState('');
    // const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const handleInnerExpand = (panel) => (newExpanded) => {
        if (innerExpanded !== panel) setInnerExpanded(newExpanded ? panel : false);
        else setInnerExpanded();
    };
    /**
     *
     * Function: Open campaign detail dialog that allow to
     * preview, delete & Edit campaign
     * @param {*} item
     */
    const [openCampaignDetail, setOpenCampaignDetail] = useState(false);
    const [currentCampaign, setCurrentCampaign] = useState();

    const handleCampaignDetail = (e, item) => {
        e.preventDefault();

        e.stopPropagation();
        setOpenCampaignDetail(true);
        console.log('detail================================');
        console.log('item', item);
        handleCampaignDetails(item.campaign_id);
        // setCurrentCampaign(item);
    };

    const handleCampaignDetails = async (campaign_id) => {
        const data = await getCampaignDetail(switchUser, campaign_id);
        console.log('data', data);
        setCurrentCampaign(data.data);
    };

    const handleCloseCampaignDetail = (item) => {
        setOpenCampaignDetail(false);
    };

    // Delete campaign
    const handleDeleteCampaign = async (item) => {
        const data = await deleteCampaign(switchUser, item.campaign_id);
        console.log('delete', data);
        if (data.code === 0) setTriggerUpdate(!triggerUpdate);
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (e, item) => {
        e.stopPropagation();

        console.log('item', item);
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [copyItem, setCopyItem] = useState();

    const handleOpenCopyDialog = (e, item) => {
        e.stopPropagation();
        console.log('file: CampaignList.jsx:141 => handleOpenCopyDialog => item:', item);
        setCopyItem(item);
        setOpenCopyDialog(true);
    };

    const handleCloseCopyDialog = () => {
        setOpenCopyDialog(false);
    };

    // Paid campaign
    const [isHovered, setIsHovered] = useState();
    const toggleHover = (item) => {
        setIsHovered(item);
    };
    const toggleLeave = () => {
        setIsHovered('');
    };

    const [anchorEl, setAnchorEl] = useState(null);
    console.log('anchorEl:', anchorEl);
    const [locations, setLocations] = useState([]);
    const handleClick = (event, item) => {
        // event.preventDefault();
        // setAnchorEl(null);
        event.stopPropagation();
        setLocations(item.group_list);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {type === 'list' ? (
                <Accordion expanded={expanded === item}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        sx={{ paddingY: '8px' }}
                        // expandIcon={item.group_num !== 0 && <GrNext size={15} onClick={handleExpand(item)} />}
                    >
                        <div
                            className="flex justify-between w-full"
                            onClick={(e) => handleCampaignDetail(e, item)}
                        >
                            <div className="flex flex-row">
                                {/* <GrStar className="mt-1 mr-1" color="gray" /> */}
                                <Typography
                                    sx={{ marginTop: '4px' }}
                                    // onClick={() => handleCampaignDetail(item)}
                                >
                                    {item.campaign_name}
                                </Typography>
                                <div className="ml-1">
                                    {item.free_campaign === 0 && (
                                        <Tooltip
                                            title="Unlock campaign"
                                            onMouseEnter={() => toggleHover(item)}
                                            onMouseLeave={toggleLeave}
                                            // onClick={() => handleClickOpen(item)}
                                        >
                                            <IconButton size="small" sx={{ paddingTop: '3px' }}>
                                                {isHovered === item ? (
                                                    <HiOutlineLockOpen color="black" />
                                                ) : (
                                                    <HiOutlineLockClosed color="red" />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                                <Chip
                                    label={item.group_num}
                                    sx={{
                                        backgroundColor: '#E5ECF6',
                                        color: '#0444AB',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        zIndex: 9999,
                                        // position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        handleClick(e, item);
                                    }}
                                    icon={
                                        <FaMapMarkerAlt
                                            style={{
                                                color: '#0444AB',
                                                marginLeft: '10px',
                                            }}
                                        />
                                    }
                                />
                            </div>

                            <div className="flex flex-row z-50 divide-x divide-solid">
                                <Tooltip title="Copy">
                                    <div>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleOpenCopyDialog(e, item)}
                                        >
                                            <FiCopy
                                                size={15}
                                                style={{ strokeWidth: '3px', color: 'black' }}
                                            />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                {/* <Tooltip title='Export'>
							<IconButton size='small'>
								<GrShareOption size={15} />
							</IconButton>
						</Tooltip> */}
                                <Tooltip title="Delete">
                                    <div>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleOpenDeleteDialog(e, item)}
                                        >
                                            <FiTrash2
                                                size={15}
                                                style={{ strokeWidth: '3px', color: 'black' }}
                                            />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </AccordionSummary>
                    {locationList?.length > 0 ? (
                        <AccordionDetails sx={{ paddingTop: '0px', paddingBottom: '0px', paddingX: '32px' }}>
                            {locationList?.map((x, index) => (
                                <Accordion
                                    key={x + index}
                                    sx={{ border: '0' }}
                                    expanded={innerExpanded === x}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                        sx={{
                                            backgroundColor: 'rgba(13, 202, 240, 0.2)',
                                            border: '0',
                                            padding: '0',
                                            marginLeft: '12px',
                                            borderRadius: '10px',
                                            margin: '10px',
                                            paddingX: '20px',
                                        }}
                                        expandIcon={<GrNext size={15} onClick={handleInnerExpand(x)} />}
                                    >
                                        <div className="flex justify-between w-full">
                                            <div className="flex flex-row">
                                                <FaMapMarkerAlt className="mt-1 mr-1" color="#0444AB" />
                                                <Typography>{x.group_name}</Typography>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    {(Array.isArray(x?.device_list) ? x?.device_list : [x?.device_list])
                                        ?.length > 0 && (
                                        <AccordionDetails
                                            sx={{
                                                borderTop: '0',
                                                paddingBottom: '0',
                                                marginLeft: '38px',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            {(Array.isArray(x?.device_list)
                                                ? x?.device_list
                                                : [x?.device_list]
                                            )?.map((i, index) => (
                                                <div
                                                    key={i + index}
                                                    className="flex w-full flex-row items-center"
                                                >
                                                    <GrSystem size={15} />
                                                    <p className="m-1 pl-2">{i.device_name}</p>
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    )}
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    ) : (
                        <Pulse />
                    )}
                </Accordion>
            ) : (
                <div className="w-[275px] p-2 bg-white round rounded-[20px] mb-4">
                    <div>
                        <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.primary">
                            {item.campaign_name}
                        </Typography>
                        <div className="w-auto h-[250px] mt-2 bg-blue-100 flex align-middle">
                            <img src={item.thumbnail_url} />
                        </div>
                    </div>
                    <div className="w-full my-2 flex flex-row justify-between">
                        <Chip
                            label={item.group_num}
                            sx={{
                                backgroundColor: '#E5ECF6',
                                color: '#0444AB',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                marginLeft: '10px',
                            }}
                            icon={
                                <FaMapMarkerAlt
                                    style={{
                                        color: '#0444AB',
                                        marginLeft: '10px',
                                    }}
                                />
                            }
                        />
                        <div className=" mr-2 flex flex-row divide-x divide-solid">
                            <Tooltip title="Copy">
                                <div>
                                    <IconButton size="small" onClick={(e) => handleOpenCopyDialog(e, item)}>
                                        <FiCopy size={15} style={{ strokeWidth: '3px', color: 'black' }} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            {/* <Tooltip title='Export'>
							<IconButton size='small'>
								<GrShareOption size={15} />
							</IconButton>
						</Tooltip> */}
                            <Tooltip title="Delete">
                                <div>
                                    <IconButton size="small" onClick={(e) => handleOpenDeleteDialog(e, item)}>
                                        <FiTrash2 size={15} style={{ strokeWidth: '3px', color: 'black' }} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                // </div>
            )}
            {openDeleteDialog && (
                <DeleteDialog
                    openDialog={openDeleteDialog}
                    handleCloseDialog={handleCloseDeleteDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={'campaign'}
                    deleteItem={deleteItem}
                    triggerUpdate={triggerUpdate}
                    switchUser={switchUser}
                />
            )}
            {openCopyDialog && (
                <CopyDialog
                    openCopyDialog={openCopyDialog}
                    handleCloseCopyDialog={handleCloseCopyDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={'campaign'}
                    copyItem={copyItem}
                    triggerUpdate={triggerUpdate}
                />
            )}
            {openCampaignDetail && (
                <CampaignDetailDialog
                    open={openCampaignDetail}
                    handleDeleteCampaign={handleDeleteCampaign}
                    item={item}
                    currentCampaign={currentCampaign}
                    handleCloseCampaignDetail={handleCloseCampaignDetail}
                />
            )}
            <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'right',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'right',
                    horizontal: 'left',
                }}
            >
                <Typography
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Location:
                    {locations?.map((location, index) => (
                        <Stack direction="row" spacing={1} sx={{ marginLeft: '10px' }}>
                            <Chip key={`${location.group_name}-${index}`} label={location.group_name} />
                        </Stack>
                    ))}
                </Typography>
            </Popover>
        </>
    );
};

export default CampaignList;
