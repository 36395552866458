// config.js
// const env = process.env.REACT_APP_ENV || 'development';

const config = {
    development: {
        //REACT_APP_MAIN_PAGE_URL_HEAD: 'http://localhost:3000',
        REACT_APP_S3_APK: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/apk/dev',


        REACT_APP_API_URL: 'http://dev.signinspire.com.au/backstage/',
        REACT_APP_DEBUG: true,
        REACT_APP_IMAGE_API_URL: 'https://53bob3tfb9.execute-api.ap-southeast-2.amazonaws.com/dev',
        REACT_APP_IMAGE_API_KEY: '5bxUMJZhYe25YNghxOyaV54ve41Vg0fx78HSdDIE',
        REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyDNuRDlATE9eKgO3IHcya_fLLJ8XGavx78',

        REACT_APP_S3_BUCKET: 'signinspire-ai-tools-dev',
        REACT_APP_REGION: 'ap-southeast-2',

        REACT_APP_accessKeyId: 'AKIAVRUVS4ZCNGZ7XFE3',
        REACT_APP_secretAccessKey: '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',

        //REACT_APP_RDS_API_URL: 'https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/new',
        REACT_APP_PAYMENT_API_URL: 'https://nbepzpo6v5.execute-api.ap-southeast-2.amazonaws.com/dev',
        REACT_APP_PAYMENT_API_KEY: 'WzecxWOwYDSuDvRqZYBu7tzMfG2bnGl4ZlkL7OK1',
    },
    production: {
        REACT_APP_S3_APK: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/apk/prod',

        REACT_APP_API_URL: 'https://signinspire.com.au/backstage/',
        REACT_APP_DEBUG: false,
        REACT_APP_IMAGE_API_URL: 'https://53bob3tfb9.execute-api.ap-southeast-2.amazonaws.com/prod',
        REACT_APP_IMAGE_API_KEY: '5bxUMJZhYe25YNghxOyaV54ve41Vg0fx78HSdDIE',
        REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyDNuRDlATE9eKgO3IHcya_fLLJ8XGavx78',

        REACT_APP_S3_BUCKET: 'signinspire-ai-tools-prod',
        REACT_APP_REGION: 'ap-southeast-2',

        REACT_APP_accessKeyId: 'AKIAVRUVS4ZCNGZ7XFE3',
        REACT_APP_secretAccessKey: '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',

        //REACT_APP_RDS_API_URL: 'https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/new',
        REACT_APP_PAYMENT_API_URL: 'https://nbepzpo6v5.execute-api.ap-southeast-2.amazonaws.com/prod',
        REACT_APP_PAYMENT_API_KEY: 'WzecxWOwYDSuDvRqZYBu7tzMfG2bnGl4ZlkL7OK1',
    },
};

export default config;
