import axios from 'axios';
import config from '../../../config/config';
// const ImagetURL =
//   "https://jo28hkjks3.execute-api.ap-southeast-2.amazonaws.com/";
// const stage = "dev";

const ImageDevURL = config[process.env.REACT_APP_ENV].REACT_APP_IMAGE_API_URL;
const api_key = config[process.env.REACT_APP_ENV].REACT_APP_IMAGE_API_KEY;

export var Resize = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/resize',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
export var SplitImage = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/split_image',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var ImageStyling = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/image_variation',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var CreateImages = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/create_image',
        headers: {
            'x-api-key': api_key,
        },

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var TextImprove = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/text_enhance',
        headers: {
            'x-api-key': api_key,
        },

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var UpScale_image = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/upscale_image',
        headers: {
            'x-api-key': api_key,
        },

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var UpScale_Result = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/upscale_result',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GenerateVideo = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/image_to_video',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetVideoResult = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/video_result',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var PostDescribeImage = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/describe_image',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetDescribeImage = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/describe_image_result',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
