import { Box, Button, Chip, Tab, Typography } from '@mui/material';
import { DevicesDialog, ScheduleCampaignDialog, ScheduleListDialog } from '../components/dashboard/schedule';
import {
    EditScheduleSort,
    GetCampaignList,
    GetDeviceInfo,
    GetLocationInfo,
    GetLocationList,
    GetScheduleDetail,
    GetScheduleFrequencyList,
    GetScheduleList,
} from '../api/api';
import { FiCalendar, FiMonitor } from 'react-icons/fi';
import React, { useEffect, useRef, useState } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import FullCalendar from '@fullcalendar/react';
import { Header } from '../components/dashboard';
import PropTypes from 'prop-types';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useStateContext } from '../contexts/ContextProvider';

const colors = [
    { number: 0, color: '#FF4D4D' }, // Light Red
    { number: 1, color: '#3377FF' }, // Light Blue
    { number: 2, color: '#33CC33' }, // Light Green
    { number: 3, color: '#FFFF33' }, // Light Yellow
    { number: 4, color: '#FF9933' }, // Light Orange
    { number: 5, color: '#CC66FF' }, // Light Purple
    { number: 6, color: '#FF99CC' }, // Light Pink
    { number: 7, color: '#33CCCC' }, // Light Teal
    { number: 8, color: '#ff9966' }, // Atomic tangerine
    { number: 9, color: '#00CCCC' }, // blue
    { number: 10, color: '#FF66FF' }, // Magenta
    { number: 11, color: '#00FF00' }, // Lime Green
    { number: 12, color: '#5e9dfe' }, // Soft blue
    { number: 13, color: '#808080' }, // Gray
    { number: 14, color: '#d4bdc5' }, // Grayish pink
    { number: 15, color: '#E6E6E6' }, // Light Gray
    { number: 16, color: '#d7bacd' }, // Silver
    { number: 17, color: '#FFCC33' }, // Gold
    { number: 18, color: '#fe6f5e' }, // Bittersweet
    { number: 19, color: '#808000' }, // Olive
    { number: 20, color: '#e96b7e' }, // Soft red
    { number: 21, color: '#00FFFF' }, // Aqua
    { number: 22, color: '#00FF99' }, // Lime Green (Alternate)
    { number: 23, color: '#CC99FF' }, // Violet
    { number: 24, color: '#00FFFF' }, // Turquoise
    { number: 25, color: '#FF6633' }, // Coral
    { number: 26, color: '#FF6666' }, // Salmon
    { number: 27, color: '#6699FF' }, // Sky Blue
    { number: 28, color: '#CC99FF' }, // Lavender
    { number: 29, color: '#CC9933' }, // Peru
    { number: 30, color: '#FF0033' }, // Crimson
    { number: 31, color: '#bde96b' }, // Soft green
    { number: 32, color: '#FF33CC' }, // Hot Pink
    { number: 33, color: '#e9d66b' }, // Arylide yellow
    { number: 34, color: '#FF6347' }, // Tomato
    { number: 35, color: '#D2691E' }, // Chocolate
    { number: 36, color: '#2E8B57' }, // Sea Green
    { number: 37, color: '#4682B4' }, // Steel Blue
    { number: 38, color: '#F4A460' }, // Sandy Brown
    { number: 39, color: '#D8BFD8' }, // Thistle
    { number: 40, color: '#e96bbd' }, // Soft pink
    { number: 41, color: '#6bbde9' }, // Soft blue
    { number: 42, color: '#BC8F8F' }, // Rosy Brown
    { number: 43, color: '#66CDAA' }, // Medium Aqua Marine
    { number: 44, color: '#CD5C5C' }, // Indian Red
    { number: 45, color: '#B8860B' }, // Dark Golden Rod
    { number: 46, color: '#20B2AA' }, // Light Sea Green
    { number: 47, color: '#5F9EA0' }, // Cadet Blue
    { number: 48, color: '#BA55D3' }, // Medium Orchid
    { number: 49, color: '#BDB76B' }, // Dark Khaki
];

const createScheduleData = (locationName, scheduleList, group_id, index, device_count) => {
    return { locationName, device_count, scheduleList, group_id, index };
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const ScheduleCampaign = () => {
    const { currentUser } = useStateContext();
    const [initialRender, setInitialRender] = useState(true);

    const ref = useRef();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    console.log('switchUser:', switchUser);
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const [scheduleList, setScheduleList] = useState([]);
    console.log('file: ScheduleCampaign.jsx:143 => ScheduleCampaign => scheduleList:', scheduleList);

    const FetchScheduleList = async () => {
        const data = await GetScheduleList(switchUser);
        if (data.code === 0) {
            setScheduleList(data?.data?.list);
        }
    };
    // Get Locations
    const [locationList, setLocationList] = useState([]);
    console.log('file: ScheduleCampaign.jsx:150 => ScheduleCampaign => locationList:', locationList);
    const getLocations = async () => {
        const data = await GetLocationList(switchUser);
        if (data.code === 0) {
            setLocationList(data.data);
            if (initialRender) setTabValue(data.data[0].group_id);
            // setTriggerUpdate(!triggerUpdate);
        }
    };

    //GetScheduleFrequencyList
    const [scheduleFrequencyList, setScheduleFrequencyList] = useState([]);

    const fetchScheduleFrequencyList = async () => {
        const data = await GetScheduleFrequencyList();
        setScheduleFrequencyList(data?.data?.list);
    };

    // Get Campaign List
    const [campaigns, setCampaigns] = useState([]);
    const FetchCampaignList = async () => {
        // TODO: pageSize = 50 hardcode
        const { code, data } = await GetCampaignList(switchUser, undefined, 50);
        code === 0 && setCampaigns(data?.list);
    };
    const [scheduleRows, setScheduleRows] = useState([]);

    // Handle Open schedule Dialog
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [locationInfo, setLocationInfo] = useState();
    const [scheduleDetail, setScheduleDetail] = useState();

    const handleOpenScheduleDialog = (item, detail) => {
        console.log('file: ScheduleCampaign.jsx:216 => handleOpenScheduleDialog => detail:', detail);
        console.log('item', item);
        handleLocationInfo(item.group_id);

        if (detail) {
            if (detail?.event) {
                GetDetails(detail.event.id);
            } else {
                GetDetails(detail.schedule_id);
            }
        } else {
            // localStorage.removeItem('scheduleDetail');
            setScheduleDetail(undefined);
        }

        setOpenScheduleDialog(true);
        setLocationInfo(item);
    };

    const GetDetails = async (schedule_id) => {
        const data = await GetScheduleDetail(switchUser, schedule_id);
        console.log('GetDetails:', data);
        localStorage.setItem('scheduleDetail', data.data ? JSON.stringify(data.data) : {});

        setScheduleDetail(data.data);
    };

    const handleCloseScheduleDialog = () => {
        // console.log('handleCloseScheduleDialog', handleCloseScheduleDialog);
        setOpenScheduleDialog(false);
        setSelectedDate();
    };

    // Calendar

    const [currentGroup, setCurrentGroup] = useState();

    const [tabValue, setTabValue] = useState();

    const [currentScheduleList, setCurrentScheduleList] = useState();
    const [currentLocation, setCurrentLocation] = useState();
    const handleChange = (event, newValue) => {
        console.log('file: ScheduleCampaign.jsx:249 => handleChange => newValue:', newValue);
        setTabValue(newValue);
        const current = scheduleRows.find((x) => x.group_id === newValue);
        setCurrentLocation(current);
        setCurrentGroup(current.group_id);
        setCurrentScheduleList(current.scheduleList);
    };

    const handleEditScheduleOrder = async () => {
        const scheduleIds = currentScheduleList.map((item) => item.schedule_id);
        console.log(scheduleIds);
        const data = await EditScheduleSort(switchUser, currentGroup, scheduleIds);
        console.log('file: ScheduleCampaign.jsx:267 => handleEditScheduleOrder => data:', data);
    };

    function getValidLastTwoDigits(number) {
        const lastTwoDigits = number % 100; // Extract the last two digits
        const validDigits = lastTwoDigits >= 0 && lastTwoDigits <= 49 ? lastTwoDigits : lastTwoDigits % 50;
        return validDigits;
    }

    // by Week
    const recurringByWeek = (start, end) => {
        const startDate = moment(moment(start, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'));
        const endDate = moment(moment(end, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'));
        // Get the days of the week as an array
        let weekdays = [];
        let currentDate = startDate.clone();

        while (currentDate.toDate() <= endDate.toDate()) {
            weekdays.push(currentDate.format('dd'));
            currentDate.add(1, 'day');
        }
        return weekdays.map((day) => day.toLowerCase());
    };
    // by Month
    const recurringByMonth = (start, end) => {
        const startDate = moment(moment(start, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'));
        const endDate = moment(moment(end, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'));
        // Get the days of the week as an array
        let monthDays = [];
        let currentDate = startDate.clone();

        while (currentDate.toDate() <= endDate.toDate()) {
            monthDays.push(parseInt(currentDate.format('D')));
            currentDate.add(1, 'day');
        }
        return monthDays;
    };
    // by Year
    const recurringByYear = (start, end) => {
        const startDate = moment(moment(start, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'));
        const endDate = moment(moment(end, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'));
        const years = [];

        while (startDate.year() <= endDate.year()) {
            if (startDate.isBetween('2023-06-10', '2033-08-15', null, '[]')) {
                years.push(startDate.year());
            }
            startDate.add(1, 'year');
        }
        return years;
    };

    const [selectedDate, setSelectedDate] = useState();
    const handleDateClick = (arg) => {
        console.log('handleDateClick => handleDateClick:', arg.startStr);
        setSelectedDate(arg.startStr);
        handleOpenScheduleDialog(currentLocation);
    };
    const [dragged, setDragged] = useState(false);
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newDivs = [...currentScheduleList];
        const [removed] = newDivs.splice(result.source.index, 1);
        newDivs.splice(result.destination.index, 0, removed);
        setDragged(!dragged);
        setCurrentScheduleList(newDivs);
    };

    useEffect(() => {
        FetchScheduleList();
        getLocations();
        FetchCampaignList();
        fetchScheduleFrequencyList();

        // setCurrentScheduleList(scheduleList.find((x) => x.group_id === tabValue)?.scheduleList);
    }, [triggerUpdate, switchUser]);

    useEffect(() => {
        const formData = Array.isArray(locationList)
            ? locationList.map((item) => ({
                  ...item,
                  scheduleList: scheduleList?.find((x) => x.group_id === item.group_id)?.list,
              }))
            : [];
        console.log('useEffect => formData:', formData);

        const rows = Array.isArray(formData)
            ? formData.map((x, index) =>
                  createScheduleData(x.group_name, x.scheduleList, x.group_id, index, x.device_count),
              )
            : [];

        console.log('scheduleRows:', rows);
        setScheduleRows(rows);
        setCurrentLocation(rows[0]);
        if (Array.isArray(rows) && rows.length > 0) {
            setCurrentGroup(rows.find((x) => x.group_id === tabValue)?.group_id);
            setCurrentScheduleList(rows.find((x) => x.group_id === tabValue)?.scheduleList);
        }
        setInitialRender(false);
    }, [locationList, scheduleList]);

    useEffect(() => {
        if (!initialRender) {
            handleEditScheduleOrder();
        } else {
            setInitialRender(false);
        }
    }, [dragged]);

    const removeMeridian = (dateString) => {
        return moment(dateString, 'DD/MMM/YYYY hh:mmA').format('DD/MMM/YYYY HH:mm');
    };

    // Open device dialog
    const [openDevicesDialog, setOpenDevicesDialog] = useState(false);
    const [openScheduleListDialog, setOpenScheduleListDialog] = useState(false);

    const handleOpenDevicesDialog = (e, groupId) => {
        e.stopPropagation();
        handleLocationInfo(groupId);
        setOpenDevicesDialog(true);
    };
    const handleOpenScheduleListDialog = (e, groupId) => {
        e.stopPropagation();
        handleLocationInfo(groupId);
        setOpenScheduleListDialog(true);
    };

    const handleCloseDevicesDialog = () => {
        setOpenDevicesDialog(false);
    };
    const handleCloseScheduleListDialog = () => {
        setOpenScheduleListDialog(false);
    };

    // Get device list
    const [locationDetail, setLocationDetail] = useState();
    const handleLocationInfo = async (group_id) => {
        const data = await GetLocationInfo(switchUser, group_id);
        console.log('data:', data);
        if (data.code === 0) {
            setLocationDetail(data.data);
            // localStorage.setItem('locationDetail', JSON.stringify(data.data));
        }
    };
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deviceDetail, setDeviceDetail] = useState();

    const getDetail = async (item) => {
        const data = await GetDeviceInfo(item.device_id);
        console.log('file: DeviceList.jsx:87 => getDetail => data:', data);
        setDeviceDetail(data.data);
    };
    const handleOpenEditDialog = (item) => {
        console.log('item', item);
        getDetail(item);

        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    return (
        <div className="m-10 md:m-10 mt-24 p-3 md:p-10">
            <div className="w-full columns-2 flex justify-between">
                <Header title="Schedule Campaign" />
                {locationList?.length > 0 && (
                    <div className="mr-4 p-3 pt-3.5">
                        <Button variant="contained" onClick={() => handleOpenScheduleDialog(currentLocation)}>
                            + Add Schedule
                        </Button>
                    </div>
                )}
            </div>
            {/* <Header title="Schedule Campaign" /> */}

            <div className="p-4">
                <div className="w-full grid gap-2 xl:grid-cols-7 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-7">
                    <div className=" xl:col-span-2 lg:col-span-2 sm:col-span-1 md:col-span-1">
                        <Typography variant="h5">Locations</Typography>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleChange}
                            scrollButtons="off"
                            aria-label="Location schedule details"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                                [`& .MuiTabs-indicator`]: {
                                    backgroundColor: 'transparent !important', // Change 'customColor' to your desired color
                                    // Add any other styles you want to change for the indicator
                                },
                                borderRight: 0,
                                height: '79vh',
                                marginTop: '10px',
                            }}
                            // sx={{}}
                        >
                            {Array.isArray(scheduleRows) &&
                                scheduleRows?.map((row) => (
                                    <Tab
                                        label={
                                            <div className="flex flex-wrap items-center">
                                                <p
                                                    title={row.locationName}
                                                    className={`mb-0 mr-1 justify-center w-28 truncate ${
                                                        tabValue === row.group_id
                                                            ? 'text-white'
                                                            : 'text-black'
                                                    } font-semibold`}
                                                >
                                                    {row.locationName}
                                                </p>

                                                <Chip
                                                    label={row.device_count}
                                                    sx={{
                                                        backgroundColor: '#E5ECF6',
                                                        color: '#0444AB',
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        marginRight: '5px',
                                                        zIndex: '1000',
                                                        height: '25px',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(229, 236, 246, 0.8)',
                                                            // backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                                            // color: '#FFFFFF', // 悬停时改变文本颜色为白色
                                                        },
                                                    }}
                                                    icon={
                                                        <FiMonitor
                                                            style={{
                                                                color: '#0444AB',
                                                                marginLeft: '10px',
                                                                // '&:hover': {
                                                                //     color: '#ffffff', // 当悬停时改变图标颜色为白色
                                                                // },
                                                            }}
                                                        />
                                                    }
                                                    onClick={(e) => handleOpenDevicesDialog(e, row.group_id)}
                                                />
                                                <Chip
                                                    label={
                                                        row?.scheduleList?.length
                                                            ? row?.scheduleList?.length
                                                            : 0
                                                    }
                                                    sx={{
                                                        backgroundColor: '#E5ECF6',
                                                        color: '#0444AB',
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        marginRight: '10px',
                                                        zIndex: '1000',
                                                        height: '25px',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(229, 236, 246, 0.8)',
                                                            // backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                                            // color: '#FFFFFF', // 悬停时改变文本颜色为白色
                                                        },
                                                    }}
                                                    icon={
                                                        <FiCalendar
                                                            style={{
                                                                color: '#0444AB',
                                                                marginLeft: '10px',
                                                                // '&:hover': {
                                                                //     color: '#ffffff', // 当悬停时改变图标颜色为白色
                                                                // },
                                                            }}
                                                        />
                                                    }
                                                    onClick={(e) =>
                                                        handleOpenScheduleListDialog(e, row.group_id)
                                                    }
                                                />
                                            </div>
                                        }
                                        key={row.group_id}
                                        value={row.group_id}
                                        sx={{
                                            // '&.${tabClasses.selected}': {
                                            //     color: '#FF6633', // Change this to the color you want for the selected tab
                                            //     backgroundColor: '#E5ECF6', // Optional: background color for the selected tab
                                            // },
                                            backgroundColor:
                                                tabValue === row.group_id ? '#0444AB' : '#ffffff',
                                            textTransform: 'none',
                                            marginBottom: '12px',
                                            marginRight: '12px',
                                            borderRadius: '16px',
                                            // color: tabValue === row.group_id ? '#ffffff' : '#000000',
                                            // minHeight: '30px',
                                        }}
                                    />
                                ))}
                        </Tabs>
                    </div>

                    <div className=" xl:col-span-5 lg:col-span-5 sm:col-span-1">
                        {Array.isArray(scheduleRows) &&
                            scheduleRows?.map((tab, index) => {
                                const newList = currentScheduleList
                                    ?.map((x, newListIndex) => {
                                        const event = {
                                            id: x.schedule_id,
                                            title: x.schedule_name,
                                            start: moment(x.start_date, 'DD/MMM/YYYY hh:mmA').format(
                                                'YYYY-MM-DD',
                                            ),
                                            end: moment(x.end_date, 'DD/MMM/YYYY hh:mmA').format(
                                                'YYYY-MM-DD',
                                            ),
                                            backgroundColor: colors.find(
                                                (c) => c.number === getValidLastTwoDigits(x.schedule_id),
                                            ).color,
                                            textColor: 'black',
                                            priority: newListIndex,
                                        };

                                        if (x.schedule_frequency !== 'One Time Event') {
                                            event.rrule = {
                                                freq: x.schedule_frequency.toLowerCase(),
                                                dtstart: moment(x.start_date, 'DD/MMM/YYYY hh:mmA').format(
                                                    'YYYY-MM-DD',
                                                ),
                                                until: moment(x.until_date, 'DD/MMM/YYYY hh:mmA').format(
                                                    'YYYY-MM-DD',
                                                ),
                                            };
                                            if (x.schedule_frequency.toLowerCase() === 'weekly') {
                                                event.rrule.byweekday = recurringByWeek(
                                                    x.start_date,
                                                    x.end_date,
                                                );
                                            }
                                            if (x.schedule_frequency.toLowerCase() === 'monthly') {
                                                // event.rrule.between =
                                                event.rrule.bymonthday = recurringByMonth(
                                                    x.start_date,
                                                    x.end_date,
                                                );
                                            }
                                            if (x.schedule_frequency.toLowerCase() === 'yearly') {
                                                const years = recurringByYear(x.start_date, x.until_date);

                                                return years
                                                    .map((year, index) => ({
                                                        id: x.schedule_id + year,
                                                        title: x.schedule_name,
                                                        start: moment(x.start_date, 'DD/MMM/YYYY hh:mmA')
                                                            .add(index, 'year')
                                                            .format('YYYY-MM-DD'),
                                                        end: moment(x.end_date, 'DD/MMM/YYYY hh:mmA')
                                                            .add(index, 'year')
                                                            .format('YYYY-MM-DD'),
                                                        backgroundColor: colors.find(
                                                            (c) =>
                                                                c.number ===
                                                                getValidLastTwoDigits(x.schedule_id),
                                                        ).color,
                                                        textColor: 'black',
                                                    }))
                                                    .flat();
                                            }
                                        }

                                        return event;
                                    })
                                    .flat();
                                return Array.isArray(tab.scheduleList) && tab.scheduleList.length > 0 ? (
                                    <TabPanel value={tabValue} index={tab.group_id} key={tab.group_id}>
                                        {/* CALENDAR */}
                                        <div className="col-span-5 bg-white p-3 rounded-[20px]">
                                            <FullCalendar
                                                height="80vh"
                                                plugins={[
                                                    rrulePlugin,
                                                    dayGridPlugin,
                                                    timeGridPlugin,
                                                    interactionPlugin,
                                                    listPlugin,
                                                    // rrulePlugin,
                                                ]}
                                                headerToolbar={{
                                                    left: 'prev next today',
                                                    center: 'title',
                                                    right: 'dayGridMonth,dayGridWeek,dayGridDay',
                                                }}
                                                initialView="dayGridYear"
                                                selectable={true}
                                                selectMirror={true}
                                                select={handleDateClick}
                                                eventClick={(e) => handleOpenScheduleDialog(tab, e)}
                                                // eventsSet={(events) => setCurrentEvents(events)}
                                                allDaySlot={false}
                                                eventOrder="priority"
                                                events={newList}
                                            />
                                        </div>
                                    </TabPanel>
                                ) : (
                                    <TabPanel value={tabValue} index={tab.group_id} key={tab.group_id}>
                                        {/* CALENDAR */}
                                        <div className="col-span-5 bg-white p-3 rounded-[20px]">
                                            <FullCalendar
                                                height="80vh"
                                                plugins={[
                                                    rrulePlugin,
                                                    dayGridPlugin,
                                                    timeGridPlugin,
                                                    interactionPlugin,
                                                    listPlugin,
                                                    // timeGridWeek,
                                                    // rrulePlugin,
                                                ]}
                                                headerToolbar={{
                                                    left: 'prev next today',
                                                    center: 'title',
                                                    right: 'dayGridMonth,dayGridWeek,dayGridDay',
                                                }}
                                                initialView="dayGridYear"
                                                selectable={true}
                                                // selectMirror={true}
                                                select={handleDateClick}
                                                eventClick={(e) => handleOpenScheduleDialog(tab, e)}
                                                // eventsSet={(events) => setCurrentEvents(events)}
                                                // initialEvents={newList}
                                                allDaySlot={false}
                                                eventOrder="priority"
                                                events={newList}
                                            />
                                        </div>
                                    </TabPanel>
                                );
                            })}
                    </div>
                    {/* </Box> */}
                </div>
            </div>
            {openScheduleDialog && (
                <ScheduleCampaignDialog
                    openScheduleDialog={openScheduleDialog}
                    handleCloseScheduleDialog={handleCloseScheduleDialog}
                    locationInfo={locationInfo}
                    scheduleFrequencyList={scheduleFrequencyList}
                    campaigns={campaigns}
                    currentUser={currentUser}
                    locationDetail={locationDetail}
                    scheduleDetail={scheduleDetail}
                    locationList={locationList}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                    selectedDate={selectedDate}
                />
            )}
            {openDevicesDialog && (
                <DevicesDialog
                    handleOpenEditDialog={handleOpenEditDialog}
                    openDevicesDialog={openDevicesDialog}
                    handleCloseDevicesDialog={handleCloseDevicesDialog}
                    locationDetail={locationDetail}
                    openEditDialog={openEditDialog}
                    handleCloseEditDialog={handleCloseEditDialog}
                    deviceDetail={deviceDetail}
                    currentUser={currentUser}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                    locationList={locationList}
                />
            )}
            {openScheduleListDialog && (
                <ScheduleListDialog
                    handleOpenEditDialog={handleOpenEditDialog}
                    openScheduleListDialog={openScheduleListDialog}
                    handleCloseScheduleListDialog={handleCloseScheduleListDialog}
                    locationDetail={locationDetail}
                    openEditDialog={openEditDialog}
                    handleCloseEditDialog={handleCloseEditDialog}
                    deviceDetail={deviceDetail}
                    currentUser={currentUser}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                    locationList={locationList}
                    // ScheduleList
                    colors={colors}
                    handleDragEnd={handleDragEnd}
                    currentScheduleList={currentScheduleList}
                    getValidLastTwoDigits={getValidLastTwoDigits}
                    removeMeridian={removeMeridian}
                />
            )}
            {/* <ScheduleList
                handleOpenScheduleDialog={handleOpenScheduleDialog}
                tab={tab}
                colors={colors}
                handleDragEnd={handleDragEnd}
                currentScheduleList={currentScheduleList}
                getValidLastTwoDigits={getValidLastTwoDigits}
                removeMeridian={removeMeridian}
            /> */}
        </div>
    );
};

export default ScheduleCampaign;
