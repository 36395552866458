import { Box, Button, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { GetBillingPortal, GetSubPriceList, Getalldevice } from './payment/Api';
import React, { useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const PriceData = [
    {
        switch: false,
        bg: '#ffffff',
        text: '#212121',
        icon: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+images/images/icon1.png',
        customer: 'General Edition',
        category: 'Creator',
        slogan: 'Discover the fundamentals of digital signage with our introductory lite plan',
        price1: 14.99,
        price2: 144,
        feature1: 'Digital Signage Essentials (Image,Video,Text)',
        feature2: 'Document Upload (PDF, Word,PowerPoint)',
        feature3: 'Pre-define template',
        feature4: 'Offline notification via email',
    },
    {
        switch: false,
        bg: '#ffffff',
        text: '#212121',
        icon: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+images/images/icon2.png',
        customer: 'General Edition',
        category: 'Inspirer',
        slogan: 'Unleash your creativity with AI-driven content creation and intuitive controls',
        price1: 19.99,
        price2: 192,
        feature1: 'AI Content Inspiration',
        feature2: 'Display Web page',
        feature3: 'Advance Touch Function',
        feature4: 'Online Analytics Report',
    },
    {
        switch: false,
        bg: '#ffffff',
        text: '#212121',
        icon: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+images/images/icon3.png',
        customer: 'General Edition',
        category: 'Designer',
        slogan: 'Elevate your content with enterprise-level design and lock in lifetime pricing',
        price1: 29.99,
        price2: 288,
        feature1: 'Minimum 10 screens',
        feature2: '24/7 Dedicated Support',
        feature3: 'Free Content Design & Development',
        feature4: 'Lifetime Capped Price',
        feature5: 'Multi-screen Discount',
    },
];
function TagColumn({
    bg,
    text,
    icon,
    customer,
    category,
    slogan,
    price1,
    price2,
    feature1,
    feature2,
    feature3,
    feature4,
    feature5,
    index,
    handleNext,
    userDetails,
    selectedPlan,
    handleSelectPlan,
    currentPlan,
    selectedPlanIndex,
    checked,
    isSubscribed,
}) {
    return (
        <div
            className={`shadow-md flex flex-col p-8 rounded-2xl w-[310px] relative ${
                selectedPlan === category ? 'border-1 border-[#0444AB]' : ''
            }`}
            style={{ backgroundColor: `${bg}`, color: `${text}` }}
        >
            {category === 'Inspirer' && (
                <div className="right-0 top-0 absolute py-1 px-3 bg-blue-400 rounded-tr-2xl">
                    <p className="font-bold text-[#0444AB]">popular!</p>
                </div>
            )}

            <div className="flex items-center mb-2 space-x-3">
                <h3 className="font-display text-3xl font-bold text-justify">{category}</h3>
            </div>
            <h3 className="mb-3">{slogan}</h3>
            <div className="flex flex-col  items-center h-[60px] mt-4">
                <p className="text-lg">
                    {category === 'Designer' && 'From '}
                    <span className="font-bold">
                        {`AU$${checked ? price1 + '/month' : price2 + '/year'}`}
                    </span>
                </p>
                {(category === 'Creator' || category === 'Inspirer') && checked === false && (
                    <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                            marginTop: '4px',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        20% OFF
                        <ArrowDownwardIcon style={{ marginLeft: '4px', fontSize: '1rem' }} />
                    </Typography>
                )}

                {category === 'Designer' && (
                    <span className="flex text-md font-bold items-center justify-center mt-2">
                        Contact us for details
                    </span>
                )}
            </div>

            <Stack spacing={2} sx={{ mt: 3, height: '280px' }}>
                <h3 className="font-display text-xl font-semibold">What's included</h3>
                {[feature1, feature2, feature3, feature4, feature5].map(
                    (item) =>
                        item && (
                            <div className="flex items-center space-x-2 ">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="27"
                                        height="27"
                                        viewBox="0 0 27 27"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                                            fill={bg === '#ffffff' ? '#0444AB' : '#ffffff'}
                                        />
                                        <path
                                            d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                                            stroke={bg === '#ffffff' ? '#ffffff' : '#0444AB'}
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <p className="text-sm">{item}</p>
                            </div>
                        ),
                )}
            </Stack>
            <div className="h-8 mb-2">
                {selectedPlan !== currentPlan &&
                    selectedPlan === category &&
                    userDetails?.customer_type != selectedPlanIndex && (
                        <p className="text-xs text-red-500 font-semibold">
                            {isSubscribed
                                ? 'Please cancel your current subscription before changing your plan.'
                                : `You are changing your plan and only one off plan are allowed.`}
                        </p>
                    )}
            </div>
            {category !== 'Designer' ? (
                <Button
                    variant={selectedPlan === category ? 'contained' : 'outlined'}
                    // onClick={() => handleNext(index + 1, price)}
                    onClick={() => handleSelectPlan(category, index)}
                    style={{
                        color: selectedPlan === category ? '#ffffff' : '#0444AB',
                        fontSize: '16px',
                        fontWeight: 500,
                        padding: '20px 32px',
                        marginTop: '20px',
                    }}
                >
                    {selectedPlan === category ? 'Selected' : 'Select this plan'}
                </Button>
            ) : (
                // TODO:
                <Button
                    variant={selectedPlan === category ? 'contained' : 'outlined'}
                    color="primary"
                    style={{
                        color: selectedPlan === category ? '#ffffff' : '#0444AB',
                        fontSize: '16px',
                        fontWeight: 500,
                        padding: '20px 32px',
                        marginTop: '20px',
                    }}
                >
                    Contact Us
                </Button>
            )}
        </div>
    );
}
const SelectPlanSec = ({
    handleNext,
    userDetails,
    selectedPlan,
    setSelectedPlan,
    currentUser,
    switchUser,
    isSubscribed,
    setIsSubscribed,
    selectedPlanIndex,
    setSelectedPlanIndex,
    checked,
    setChecked,
}) => {
    const currentPlan =
        userDetails?.customer_type === 1
            ? 'Creator'
            : userDetails?.customer_type === 2
            ? 'Inspirer'
            : userDetails?.customer_type === 3
            ? 'Designer'
            : '';
    console.log('SelectPlanSec => currentPlan:', currentPlan);

    // const [selectedPlan, setSelectedPlan] = useState('');

    // const [checked, setChecked] = useState(true);
    // True monthly
    // False yearly
    console.log('SubscriptionMain => checked:', checked);

    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
    };
    console.log('SelectPlanSec => userDetails:', userDetails);

    console.log('SelectPlanSec => selectedPlanIndex:', selectedPlanIndex);
    console.log('selectedPlan:', selectedPlan);
    useEffect(() => {
        setSelectedPlan(
            userDetails?.customer_type === 1
                ? 'Creator'
                : userDetails?.customer_type === 2
                ? 'Inspirer'
                : userDetails?.customer_type === 3
                ? 'Designer'
                : '',
        );
        setSelectedPlanIndex(userDetails?.customer_type);
    }, []);

    const handleSelectPlan = (plan, index) => {
        if (selectedPlan != plan) {
            console.log('handleSelectPlan => index:', index);
            setSelectedPlan(plan);
            setSelectedPlanIndex(index + 1);
            if (currentUser.customer_type === index + 1) {
                console.log(
                    'handleSelectPlan => currentUser.customer_type === index + 1:',
                    currentUser.customer_type === index + 1,
                );
                setChecked(true);
            } else {
                setChecked(false);
            }
        }
    };

    // Get device list

    console.log('isSubscribed:', isSubscribed);

    const getDeviceList = async () => {
        //console.log(abc)
        const body = { userId: switchUser ? switchUser : currentUser.user_id };

        const data = await Getalldevice(body);
        console.log('GetDeviceList:', data.message);

        const total = data.message;
        total?.map((dev) => {
            dev.type = 'Select Products';
            dev.price = '';
            dev.note = false;

            // check_expire_time(dev);
        });
        console.log('getDeviceList => total:', total);
        setIsSubscribed(total.some((x) => x.subscription === 'true'));
        console.log(
            "getDeviceList => total.find((x) => (x.subscription === 'true' ? true : false)):",
            total.some((x) => x.subscription === 'true'),
        );
        // setdevices(total);
    };

    useEffect(() => {
        getDeviceList();
    }, []);

    return (
        <div>
            <div className="flex flex-row justify-center mt-2">
                {userDetails?.customer_type === selectedPlanIndex ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>One Off</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={checked}
                                    onChange={handleChangeSwitch}
                                    name="custom-switch"
                                />
                            }
                        />
                        <Typography>Monthly</Typography>
                    </Stack>
                ) : (
                    <div className="h-8"> </div>
                )}
            </div>
            {/* <div className="max-w-8xl mx-auto my-2 flex flex-col md:flex-row md:justify-center">{}</div> */}
            <div className="max-w-8xl mx-auto py-2 flex flex-col md:flex-row md:justify-center gap-6">
                {PriceData.map(
                    (column, index) => (
                        // column.switch === checked ? (
                        <TagColumn
                            key={`${column}-${index}`}
                            index={index}
                            bg={column.bg}
                            text={column.text}
                            icon={column.icon}
                            category={column.category}
                            customer={column.customer}
                            slogan={column.slogan}
                            price1={column.price1}
                            price2={column.price2}
                            timespan={column.timespan}
                            feature1={column.feature1}
                            feature2={column.feature2}
                            feature3={column.feature3}
                            feature4={column.feature4}
                            feature5={column.feature5}
                            handleNext={handleNext}
                            userDetails={userDetails}
                            currentPlan={currentPlan}
                            selectedPlan={selectedPlan}
                            handleSelectPlan={handleSelectPlan}
                            selectedPlanIndex={selectedPlanIndex}
                            checked={checked}
                            isSubscribed={isSubscribed}
                        />
                    ),
                    // ) : null,
                )}
            </div>
        </div>
    );
};

export default SelectPlanSec;
