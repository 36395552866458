import { Grid, Typography, Link, Card, TextField, Button } from '@mui/material';
import { AnimationWrapper, Title } from '../components/website'
import React from 'react';
import WebBreadcrumbs from '../components/website/WebBreadcrumbs';
import { MdPhone } from 'react-icons/md';
import { Form, Formik } from 'formik';
import * as yup from 'yup'

const validationSchema = yup.object({
	email:yup.string()
		.email('Please enter a valid email')
		.required('Please enter your email'),
	name:yup.string()
		.max(40,'Name needs to be less than 40 characters')
		.required('Please enter your name'),
	subject:yup.string()
		.max(40,'Subject needs to be less than 40 characters')
		.required('Please enter the subject'),
	message:yup.string()
		.required('Please leave your message')
})

const ContactUs = () => {
	return(
	<>
	<div className='flex w-full h-3/10-screen text-center align-middle justify-center bg-contact-us bg-no-repeat bg-cover'>
		<div className='my-auto'>
			<Title strong='Contact Us' className='my-auto' textColor='text-white' strongColor='text-white' hasTextShadow/>
		</div>
	</div>
	<div className='w-full from-neutral-100 to-white bg-gradient-to-b'>
		<div className='md:max-w-2xl lg:max-w-7xl mx-auto px-4 lg:px-8'>
			<WebBreadcrumbs sites={[{name:'Contact us',link:'contact'}]}/>
			<Grid container className='justify-center py-10 items-stretch h-full max-w-7xl' spacing={5} columns={{sm:1, lg:4}} alignItems='center'>
				<Grid item xs={1} className='h-full w-full'>
					<AnimationWrapper animationType='slide-right' className='delay w-full h-full flex justify-center'>
						<Card className='flex flex-col text-center p-4 items-center h-full group' sx={{borderRadius:6, boxShadow:2}}>
							<MdPhone size={80} className='fill-neutral-600 m-2 group-hover:animate-shake-5'/>
							<Typography variant='h5' className='text-neutral-500'>Contact Us</Typography>
							<Grid container columns={1} className='w-full h-full'>
								<Grid item xs={1}>
									<Typography variant='h6' className='py-4 text-neutral-500'>Australia Office</Typography>
									<Typography>
										414 Lower Dandenong Road, Braeside, Victoria 3195
										<br/>Call us: 1300 804 669
									</Typography>
									<Link underline='none'
										onClick={() => window.location = 'mailto:sales@cybercast.com.au'}
									>	
										sales@cybercast.com.au
									</Link>
								</Grid>
								<Grid item xs={1} className='pb-8'>
									<Typography variant='h6' className='py-4 text-neutral-500'>New Zealand Office</Typography>
									<Typography>
										4/113 Pavilion Drive, Airport Oaks, Auckland 2022
										<br/>Call us: (09) 2555025
									</Typography>
									<Link underline='none'
											onClick={() => window.location = 'mailto:sales@cybercast.co.nz'}
											
									>	
										sales@cybercast.co.nz
									</Link>
								</Grid>
							</Grid>
						</Card>
					</AnimationWrapper>
				</Grid>
				<Grid item xs={3} className='h-full max-w-4xl'>
					<AnimationWrapper animationType='slide-right' className='delay h-full w-full flex justify-center'>
						<Card className='text-center p-10 h-full max-w-4xl' sx={{borderRadius:6}}>
							<Typography variant='h5' className='text-neutral-500'>Sales and General Enquiries</Typography>
							<Formik
								initialValues={{name:'', email:'',subject:'',message:''}}
								onSubmit={(values)=>console.log(values)}
								validationSchema={validationSchema}
							>
								{props =>
								<Form>
									<Grid container spacing={5} columns={{sm:1, md:3}} className='pt-8 w-full'>
										<Grid item xs={1} className='w-full'>
											<TextField
												fullWidth
												name='name' 
												label='Name'
												placeholder='Your name'
												type='text'
												value={props.values.name}
												onChange={props.handleChange}
												error={props.touched.name && Boolean(props.errors.name)}
          										helperText={props.touched.name && props.errors.name}
											/>
										</Grid>
										<Grid item xs={1} className='w-full'>
											<TextField
												fullWidth
												name='email'
												label='E-mail'
												placeholder='Your email'
												value={props.values.email}
												onChange={props.handleChange}
												error={props.touched.email && Boolean(props.errors.email)}
          										helperText={props.touched.email && props.errors.email}
											/>
										</Grid>
										<Grid item xs={1} className='w-full'>
											<TextField
												fullWidth
												name='subject'
												label='Subject'
												placeholder='Subject'
												type='text'
												value={props.values.subject}
												onChange={props.handleChange}
												error={props.touched.subject && Boolean(props.errors.subject)}
          										helperText={props.touched.subject && props.errors.subject}
											/>
										</Grid>
										<Grid item xs={3} className='w-full'>
											<TextField
												fullWidth
												minRows={10}
												maxRows={10}
												multiline
												name='message'
												label='Message'
												placeholder='Your message'
												type='text'
												value={props.values.message}
												onChange={props.handleChange}
												error={props.touched.message && Boolean(props.errors.message)}
          										helperText={props.touched.message && props.errors.message}
											/>
										</Grid>
										{/* <Grid item xs={1}>
											
										</Grid> */}
									</Grid>
									<Button className='w-full text-white mt-4' variant='contained' sx={{textTransform:'none'}} type='submit'>
										Send Email
									</Button>
								</Form>
								}
							</Formik>
						</Card>
					</AnimationWrapper>
				</Grid>
			</Grid>
		</div>
	</div>
	</>
)};

export default ContactUs;
