import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import * as Yup from 'yup';

import {
    AddTemplate,
    GetTemplateElementTypeList,
    GetTemplateTagList,
    UpdateTemplate,
} from '../../../api/api';
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { Landscape, Portrait } from './layouts';
import React, { useEffect, useState } from 'react';

import { EditElement } from './';
import { useFormik } from 'formik';

const LandscapeWidth = 40;
const landscapeHeight = 67.5;

const sharedButtonStyles = {
    textTransform: 'none',
    minWidth: '120px',
};

const layoutOptions = [
    {
        value: 0,
        label: 'Landscape',
    },
    {
        value: 1,
        label: 'Portrait',
    },
];

const CreateTemplateDialog = ({
    item,
    currentUser,
    openCreateTemplateDialog,
    handleCloseCreateTemplateDialog,
    triggerUpdate,
    setTriggerUpdate,
    currentTemplate,
    setEditText,
    editText,
    setCurrentTemplate,
}) => {
    console.log('file: CreateTemplateDialog.jsx:66 => item:', item);

    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    console.log('file: CreateTemplateDialog.jsx:62 => currentTemplate:', currentTemplate);

    /**
     * TODO:only for general template & customer template
     * General template: 0
     * Customer template: 1
     *
     *  */
    const groupType = item.group_type === 2 ? 1 : 0;
    const formik = useFormik({
        initialValues: {
            name: currentTemplate ? currentTemplate.template_name : '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().max(255).required('Template name is required'),
        }),
        onSubmit: (values) => {
            console.log('values', values);

            const dividedData = widgetArray.map((item) => {
                if (!item.hasOwnProperty('element_id')) {
                    item.element_id = 0;
                }

                return {
                    ...item,
                    element_x: (
                        (parseInt(targetLayout) === 0 ? item.element_x / 1920 : item.element_x / 1080) * 100
                    ).toFixed(1),
                    element_y: (
                        (parseInt(targetLayout) === 0 ? item.element_y / 1080 : item.element_y / 1920) * 100
                    ).toFixed(1),
                    element_width: (
                        (parseInt(targetLayout) === 0
                            ? item.element_width / 1920
                            : item.element_width / 1080) * 100
                    ).toFixed(1),
                    element_height: (
                        (parseInt(targetLayout) === 0
                            ? item.element_height / 1080
                            : item.element_height / 1920) * 100
                    ).toFixed(1),
                };
            });
            // console.log(
            //     'file: CreateTemplateDialog.jsx:159 => dividedData => dividedData:',
            //     dividedData,
            // );

            if (editText) {
                const modifiedData = dividedData.map((item) => {
                    if (Array.isArray(item.element_tag_event_list)) {
                        const modifiedEventList = item.element_tag_event_list?.map((event) => {
                            const parsedControlTags =
                                typeof event.control_tags === 'string'
                                    ? JSON.parse(event.control_tags)
                                    : event.control_tags;

                            return {
                                ...event,
                                control_tags: parsedControlTags,
                            };
                        });

                        return {
                            ...item,
                            element_tag_event_list: JSON.stringify(modifiedEventList),
                        };
                    } else {
                        return item;
                    }
                });
                console.log(
                    'file: CreateTemplateDialog.jsx:138 => modifiedData => modifiedData:',
                    modifiedData,
                );
                handleEditTemplate(
                    currentTemplate.template_id,
                    currentTemplate.element_layout,
                    values.name,
                    targetLayout === 0 ? 1920 : 1080, // Landscape
                    targetLayout === 0 ? 1080 : 1920,
                    modifiedData,
                );
            } else {
                console.log('\n Create template =====================\n:', dividedData);
                handleCreatetemplate(
                    values.name,
                    targetLayout === 0 ? 1920 : 1080, // Landscape
                    targetLayout === 0 ? 1080 : 1920,
                    parseInt(targetLayout),
                    dividedData,
                );
            }
        },
    });

    //handle change layout
    const [targetLayout, setTargetLayout] = useState(editText ? currentTemplate.element_layout : 0);
    console.log('targetLayout:', targetLayout);
    const initialLandscapeState = [
        {
            element_type: 'image',
            element_x: 0,
            element_y: 0,
            element_width: LandscapeWidth * 12,
            element_height: 6 * landscapeHeight,
            element_layer: 1,
            element_effect: 'show',
            element_alias: (1670000000 + Math.floor(Math.random() * 1000000)).toString(),
        },
    ];
    const initialPortraitState = [
        {
            element_type: 'image',
            element_x: 0,
            element_y: 0,
            element_width: 720,
            element_height: 480,
            element_layer: 1,
            element_effect: 'show',
            element_alias: (1670000000 + Math.floor(Math.random() * 1000000)).toString(),
        },
    ];

    const [widgetArray, setWidgetArray] = useState(
        editText
            ? currentTemplate.element_layout === 0
                ? currentTemplate?.element_list.map((element) => ({
                      ...element,
                      element_x: (element.element_x / 100) * 1920,
                      element_y: (element.element_y / 100) * 1080,
                      element_width: (parseFloat(element.element_width) / 100) * 1920,
                      element_height: (parseFloat(element.element_height) / 100) * 1080,
                  }))
                : currentTemplate?.element_list.map((element) => {
                      //   console.log(
                      //       'file: CreateTemplateDialog.jsx:256 => :currentTemplate?.element_list.map => element:',
                      //       element,
                      //   );
                      //   console.log(parseFloat(element.element_width) / 100);
                      //   console.log('width:', (parseFloat(element.element_width) / 100) * 1080);
                      return {
                          ...element,
                          element_x: (element.element_x / 100) * 1920,
                          element_y: (element.element_y / 100) * 1920,
                          element_width: (parseFloat(element.element_width) / 100) * 1920,
                          element_height: (parseFloat(element.element_height) / 100) * 1920,
                      };
                  })
            : initialLandscapeState,
    );
    console.log('file: CreateTemplateDialog.jsx:192 => widgetArray:', widgetArray);

    const [newCounter, setNewCounter] = useState(1);
    const handleChangeLayout = (item) => {
        console.log('item', item);
        setTargetLayout(item.value);
        if (editText) {
            setCurrentTemplate();
            setEditText();
            setWidgetArray(() =>
                item.value === 0
                    ? [
                          {
                              element_type: 'image',
                              element_x: 0,
                              element_y: 0,
                              element_width: LandscapeWidth * 12,
                              element_height: 6 * landscapeHeight,
                              element_layer: 1,
                              element_effect: 'show',
                              element_alias: (1670000000 + Math.floor(Math.random() * 1000000)).toString(),
                          },
                      ]
                    : [
                          {
                              element_type: 'image',
                              element_x: 0,
                              element_y: 0,
                              element_width: 720,
                              element_height: 480,
                              element_layer: 1,
                              element_effect: 'show',
                              element_alias: (1670000000 + Math.floor(Math.random() * 1000000)).toString(),
                          },
                      ],
            );
        } else {
            setWidgetArray(() => (item.value === 0 ? initialLandscapeState : initialPortraitState));
        }
    };

    //Add elements
    const onAddItem = () => {
        console.log('adding', 'n' + newCounter);
        setWidgetArray((prevWidget) =>
            prevWidget.concat({
                element_type: 'image',
                element_x: 0,
                element_y: 0, // puts it at the bottom
                element_width: targetLayout === 0 ? LandscapeWidth * 12 : 720,
                element_height: targetLayout === 0 ? 6 * landscapeHeight : 480,
                element_layer: editText ? newCounter + widgetArray.length : newCounter + 1,
                element_effect: 'show',
                element_alias: 1670000000 + Math.floor(Math.random() * 1000000).toString(),
            }),
        );
        setNewCounter((prevCounter) => prevCounter + 1);
    };

    const onRemoveItem = (i) => {
        setWidgetArray(widgetArray.filter((x) => x.element_alias !== i.element_alias));
    };

    const handleClearElement = () => {
        setWidgetArray([]);
        setNewCounter(0);
    };

    const handleModify = (layouts) => {
        console.log('layouts', layouts);

        const tempArray = widgetArray;
        // setLayouts(layout);
        if (tempArray) {
            console.log('tempArray:', tempArray);
            const newArray = layouts?.map((layout) => {
                const matchedArray = tempArray.find((objLayout) => objLayout.element_alias === layout.i);
                console.log('matchedArray:', matchedArray);
                if (matchedArray) {
                    return {
                        ...matchedArray,
                        element_x: parseInt(layout.x * (targetLayout === 0 ? LandscapeWidth : 22.5)),
                        element_y: parseInt(layout.y * (targetLayout === 0 ? landscapeHeight : 96)),
                        element_width: parseInt(layout.w * (targetLayout === 0 ? LandscapeWidth : 22.5)),
                        element_height: parseInt(layout.h * (targetLayout === 0 ? landscapeHeight : 96)),
                    };
                }
            });
            const filteredArray = newArray.filter((value) => value !== undefined);
            if (filteredArray.length > 0) {
                console.log('newArray', newArray);
                setWidgetArray([...newArray]);
            }
        }
    };
    useEffect(() => {
        // Use map to transform element_tag_event_list arrays into strings
        const transformedData = widgetArray.map((item) => {
            if (Array.isArray(item.element_tag_event_list) && item.element_tag_event_list?.length > 0) {
                // 如果是数组，转换成字符串
                return {
                    ...item,
                    element_tag_event_list: JSON.stringify(item.element_tag_event_list),
                };
            } else {
                // 如果已经是字符串，保持不变
                return item;
            }
        });
        setWidgetArray(widgetArray);
    }, [widgetArray, openCreateTemplateDialog]);

    const handleDragStop = (e) => {
        console.log('e', e);
        const tempArray = widgetArray;
        if (tempArray) {
            console.log('tempArray:', tempArray);
            const newArray = e?.map((layout) => {
                const matchedArray = tempArray.find((objLayout) => objLayout.element_alias === layout.i);
                console.log('matchedArray:', matchedArray);
                if (matchedArray) {
                    return {
                        ...matchedArray,
                        element_x: parseInt(layout.x * (targetLayout === 0 ? LandscapeWidth : 22.5)),
                        element_y: parseInt(layout.y * (targetLayout === 0 ? landscapeHeight : 96)),
                        // element_width: parseInt(layout.w * (targetLayout === 0 ? LandscapeWidth : 22.5)),
                        // element_height: parseInt(layout.h * (targetLayout === 0 ? landscapeHeight : 96)),
                    };
                }
            });
            console.log('newArray', newArray);
            setWidgetArray([...newArray]);
        }
    };

    const handleCreatetemplate = async (
        templateName,
        templateWidth,
        templateHeight,
        elementLayout,
        elementList,
        tag,
    ) => {
        // console.log(
        // 	'file: CreateTemplateDialog.jsx:388 => groupType:',
        // 	groupType
        // );

        const data = await AddTemplate(
            templateName,
            templateWidth,
            templateHeight,
            groupType,
            elementLayout,
            elementList,
            tag,
        );
        console.log('data', data);
        if (data.code === 0) {
            handleCloseCreateTemplateDialog();
            setTriggerUpdate(!triggerUpdate);
        }
    };
    const handleEditTemplate = async (
        templateId,
        templateLayout,
        templateName,
        templateWidth,
        templateHeight,
        elementList,
        tag,
    ) => {
        const data = await UpdateTemplate(
            switchUser,
            templateId,
            templateLayout,
            templateName,
            templateWidth,
            templateHeight,
            elementList,
            tag,
        );
        console.log('data', data);
        if (data.code === 0) {
            handleCloseCreateTemplateDialog();
            setTriggerUpdate(!triggerUpdate);
        }
    };

    const [editElementDialog, setEditElementDialog] = useState(false);
    const [currentElement, setCurrentElement] = useState();
    const handleOpenEdit = (item) => {
        setEditElementDialog(true);
        setCurrentElement(item);
    };

    const handleCloseEdit = () => {
        setEditElementDialog(false);
    };
    const [elementtype, setElementType] = useState([]);
    const [elementTagList, setElementTagList] = useState([]);
    console.log('file: CreateTemplateDialog.jsx:400 => elementTagList:', elementTagList);
    const getElementType = async () => {
        const data = await GetTemplateElementTypeList();
        setElementType(data?.data?.list);
    };

    const handleFetchTagList = async () => {
        const { data } = await GetTemplateTagList();
        // console.log('file: CreateTemplateDialog.jsx:402 => handleFetchTagList => data:', data);
        setElementTagList(data?.list);
    };
    useEffect(() => {
        getElementType();
        handleFetchTagList();
    }, []);

    const [editedElement, setEditedElement] = useState({});
    console.log('editedElement:', editedElement);
    useEffect(() => {
        const tempArr = widgetArray?.map((item) => {
            if (item.element_alias === editedElement.element_alias) {
                console.log('file: CreateTemplateDialog.jsx:396 => tempArr => editedElement:', editedElement);
                console.log('file: CreateTemplateDialog.jsx:396 => tempArr => item:', item);
                return { ...editedElement };
            }
            return item;
        });
        setWidgetArray([...tempArr]);
        console.log('tempArray', widgetArray);
    }, [editedElement]);

    console.log('file: CreateTemplateDialog.jsx:152 => pass to widgetArray:', widgetArray);

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openCreateTemplateDialog}
                onClose={handleCloseCreateTemplateDialog}
            >
                <DialogTitle>{editText || 'Create'} Template</DialogTitle>
                <DialogContent className="flex flex-col p-6 ">
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label="Template Name"
                            margin="normal"
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Card sx={{ borderRadius: '12px', padding: '10px' }}>
                            <FormControl>
                                {/* <FormLabel id='demo-row-radio-buttons-group-label'>Gender</FormLabel> */}
                                <RadioGroup
                                    row
                                    fullWidth
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="targetLayout"
                                    onBlur={formik.handleBlur}
                                    value={targetLayout}
                                    // onChange={handleChangeLayout}
                                >
                                    {layoutOptions?.map((item, index) => (
                                        <FormControlLabel
                                            key={item + index}
                                            value={item.value}
                                            onChange={() => handleChangeLayout(item)}
                                            control={<Radio />}
                                            label={item.label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <Divider />
                            <Typography
                                // className='italic '
                                sx={{ fontSize: '12px' }}
                            >
                                The resolution for both Landscape and Portrait mode will be suitable for Full
                                HD conversion
                            </Typography>
                        </Card>
                        <div className="flex justify-center">
                            {targetLayout === 0 ? (
                                widgetArray && (
                                    <Landscape
                                        handleDragStop={handleDragStop}
                                        handleModify={handleModify}
                                        widgetArray={widgetArray}
                                        handleOpenEdit={handleOpenEdit}
                                        onRemoveItem={onRemoveItem}
                                        setCurrentTemplate={setCurrentTemplate}
                                        setWidgetArray={setWidgetArray}
                                        editedElement={editedElement}
                                        editText={editText}
                                    />
                                )
                            ) : (
                                <Portrait
                                    handleDragStop={handleDragStop}
                                    handleModify={handleModify}
                                    widgetArray={widgetArray}
                                    handleOpenEdit={handleOpenEdit}
                                    onRemoveItem={onRemoveItem}
                                    editedElement={editedElement}
                                    setWidgetArray={setWidgetArray}
                                    editText={editText}
                                />
                            )}
                        </div>

                        <DialogActions>
                            <div className="flex flex-row justify-between w-full">
                                <div className="mr-2 mt-2">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            ...sharedButtonStyles,
                                            minWidth: '140px',
                                        }}
                                        onClick={handleClearElement}
                                    >
                                        Clear element
                                    </Button>
                                </div>
                                <div className="ml-4 flex flex-row flex-wrap justify-end">
                                    <div className="mr-2 mt-2">
                                        <Button
                                            sx={sharedButtonStyles}
                                            variant="contained"
                                            onClick={handleCloseCreateTemplateDialog}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="mr-2 mt-2">
                                        <Button
                                            sx={sharedButtonStyles}
                                            variant="contained"
                                            onClick={onAddItem}
                                        >
                                            Add element
                                        </Button>
                                    </div>
                                    <div className="mr-2 mt-2">
                                        <Button sx={sharedButtonStyles} variant="contained" type="submit">
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            {editElementDialog && (
                <EditElement
                    editElementDialog={editElementDialog}
                    handleCloseEdit={handleCloseEdit}
                    elementtype={elementtype}
                    currentElement={currentElement}
                    targetLayout={targetLayout}
                    elementTagList={elementTagList}
                    setEditedElement={setEditedElement}
                    widgetArray={widgetArray}
                />
            )}
        </div>
    );
};

export default CreateTemplateDialog;
