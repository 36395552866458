import * as Yup from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';

import { AddDevice } from '../../../api/api';
import { useNotification } from '../../../contexts/ContextProvider';

const AddDeviceDialog = ({
    openDeviceDialog,
    handleCloseDeviceDialog,
    currentUser,
    setTriggerUpdate,
    triggerUpdate,
}) => {
    const notify = useNotification();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    // Handle activate device
    //Code you enter could not be found.
    const formik = useFormik({
        initialValues: {
            code: '',
            expire_month: undefined,
            model: undefined,
            // toggle: false,
        },
        validationSchema: Yup.object({
            code: Yup.string().max(255).required('Code you enter could not be found.'),
        }),
        onSubmit: (values) => {
            const { code, expire_month, model } = values;
            console.log('model:', model);
            console.log('expire_month:', expire_month);
            console.log('code:', code);
            formik.resetForm();
            HandleAddDevice(code, expire_month, model);
        },
    });

    const HandleAddDevice = async (code, expire_month, model) => {
        const data = await AddDevice(code, expire_month, model, switchUser);
        console.log(data);
        if (data?.code === 0) {
            handleCloseDeviceDialog();
            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Device was added successfully!!',
                },
            });
        } else {
            handleCloseDeviceDialog();

            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Device was added unsuccessfully!',
                },
            });
        }
    };
    const handleClose = () => {
        formik.resetForm();
        handleCloseDeviceDialog();
    };

    return (
        <>
            <Dialog open={openDeviceDialog} onClose={handleClose}>
                <DialogTitle>Add Device</DialogTitle>
                <DialogContent className="flex flex-col p-6">
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            error={Boolean(formik.touched.code && formik.errors.code)}
                            fullWidth
                            helperText={formik.touched.code && formik.errors.code}
                            label="Activation Code"
                            margin="normal"
                            name="code"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.code}
                            variant="filled"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        />
                        {currentUser.user_level === 0 && (
                            <TextField
                                error={Boolean(formik.touched.expire_month && formik.errors.expire_month)}
                                fullWidth
                                helperText={formik.touched.expire_month && formik.errors.expire_month}
                                label="Expire Month"
                                margin="normal"
                                name="expire_month"
                                type="number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.expire_month}
                                variant="filled"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    disableUnderline: true, // 这里禁用下边框
                                }}
                            />
                        )}
                        {currentUser.user_level === 0 && (
                            <TextField
                                error={Boolean(formik.touched.model && formik.errors.model)}
                                fullWidth
                                helperText={formik.touched.model && formik.errors.model}
                                label="Model"
                                margin="normal"
                                name="model"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.model}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                InputProps={{
                                    disableUnderline: true, // 这里禁用下边框
                                }}
                            />
                        )}
                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                // variant="filled"
                                sx={{ color: 'black' }}
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit">
                                Add
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddDeviceDialog;
