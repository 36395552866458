import * as Yup from 'yup';

import { AddUser, GetPermissionGroupList, GetUserDetail, GetUserList } from '../api/api';
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UpdateUserSec, UserList } from '../components/dashboard/user';
import { useNotification, useStateContext } from '../contexts/ContextProvider';

import { DenseInput } from '../components/dashboard/utils';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Header } from '../components/dashboard';
import { useFormik } from 'formik';

// const Items = styled(ListItem)(({ theme }) => ({}));

const style = {
    labelText: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
    label: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
        color: 'black',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
};

const AccountManager = () => {
    const notify = useNotification();

    const { currentUser } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const [userList, setUserList] = useState([]);
    // console.log('AAAAAAAA========userList:', userList);
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const [userDetail, setUserDetail] = useState({
        user_id: '',
        name: '',
        username: '',
        status: '',
        email: '',
        customer_type: '1',
        permission_id_list: [],
    });
    const [updateUser, setUpdateUser] = useState(false);
    console.log('userDetail:', userDetail);
    /**
     *
     * Save new account
     *
     */
    const formik = useFormik({
        initialValues: {
            name: userDetail.user_id ? userDetail.user_id : '',
            username: userDetail.username ? userDetail.username : '',
            password: userDetail.password ? userDetail.password : '',
            confirm_password: userDetail.confirm_password ? userDetail.confirm_password : '',
            email: userDetail.email ? userDetail.email : '',
            customer_type: userDetail.customer_type ? userDetail.customer_type : 0,
            block_account: userDetail.block_account ? userDetail.block_account : '',
        },
        validationSchema: Yup.object({
            name: Yup.string().max(255).required('Name is required'),
            username: Yup.string().max(255).required('Username is required'),
            password: Yup.string().max(255).required('Password is required'),
            confirm_password: Yup.string().max(255).required('Confirm password is required'),
            email: Yup.string().max(255).required('Email is required'),
            customer_type: Yup.string().max(255).required('Customer type is required'),
            password: Yup.string().max(255).required('Password is required'),
        }),
        onSubmit: (values) => {
            const { name, username, password, confirm_password, email, customer_type, block_account } =
                values;
            console.log('file: AccountManager.jsx:137 => AccountManager => block_account:', block_account);
            console.log('file: AccountManager.jsx:137 => AccountManager => customer_type:', customer_type);
            console.log('file: AccountManager.jsx:137 => AccountManager => email:', email);
            console.log(
                'file: AccountManager.jsx:137 => AccountManager => confirm_password:',
                confirm_password,
            );
            console.log('file: AccountManager.jsx:137 => AccountManager => password:', password);
            console.log('file: AccountManager.jsx:137 => AccountManager => username:', username);
            console.log('file: AccountManager.jsx:137 => AccountManager => name:', name);

            handleAddUser(
                name,
                username,
                password,
                confirm_password,
                email,
                block_account.length > 0 ? 1 : 0,
                parseInt(customer_type),
                selectedItems,
            );
        },
    });

    const [menuList, setMenuList] = useState([]);
    console.log('menuList: GetPermissionGroupList', menuList);

    useEffect(() => {
        handleMenuList();
        HandleGetUserList();
    }, [triggerUpdate]);

    const handleMenuList = async () => {
        const { code, data, msg } = await GetPermissionGroupList(switchUser);
        console.log('GetPermissionGroupList:', data);
        if (code === 0) {
            setMenuList(data?.list.slice(0, 4));
        }
        // else {
        //     alert(msg);
        // }
    };

    /***
     *
     * Select multiple items
     *
     */
    const [selectedItems, setSelectedItems] = useState([]);
    console.log('selectedItems:', selectedItems);

    const handleCheckboxChange = (event) => {
        const item = event.target.value;
        console.log('item:', item);
        const isChecked = event.target.checked;
        console.log('isChecked:', isChecked);

        if (isChecked) {
            setSelectedItems([...selectedItems, item * 1]);
        } else {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item * 1));
        }
    };

    /**
     *
     *
     * Handle Add User
     *
     */
    const handleAddUser = async (
        name,
        username,
        password,
        confirm_password,
        email,
        status,
        customer_type,
        permission_id_list,
    ) => {
        const { code, msg, data } = await AddUser(
            switchUser,
            name,
            username,
            password,
            confirm_password,
            email,
            status,
            customer_type,
            permission_id_list,
        );
        console.log('data:', data);
        console.log('code:', code);
        if (code === 0) {
            formik.resetForm();
            setSelectedItems([]);
            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'User was added successfully!!',
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'User was added unsuccessfully!',
                },
            });
        }
    };

    const handleCreateUser = () => {
        console.log('to create user!');
        setUpdateUser(false);
    };

    /**
     *
     * Get User List
     *
     */

    const HandleGetUserList = async () => {
        const { code, data } = await GetUserList(switchUser, 2, 2);
        console.log('data:', data?.list);
        if (code === 0) {
            setUserList(data?.list);
        }
    };

    /**
     *
     *
     * Handle get User details
     *
     */
    const handleGetUserDetails = async (user_id) => {
        const { code, data } = await GetUserDetail(user_id);
        console.log('code:', code);
        console.log('data:', data);
        if (code === 0) {
            setUpdateUser(true);
            setUserDetail({ ...data });
        }
    };

    return (
        <>
            <div className="md:m-10 mt-24 p-2  md:p-10">
                <Header title="Account Manager" />
                <div
                    className="p-4 pt-0 grid grid-cols-1 md:grid-cols-3 mt-4 w-full mb-4 gap-4 "
                    style={{ height: '100%' }}
                >
                    <UserList
                        handleGetUserDetails={handleGetUserDetails}
                        currentUser={currentUser}
                        switchUser={switchUser}
                        userList={userList}
                    />

                    {!updateUser ? (
                        <div className="col-span-1 md:col-span-2 h-full p-4 rounded-3xl  bg-white">
                            <div className="pl-3 font-semibold text-lg">Account Details</div>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel required id="name" sx={style.labelText}>
                                        Name
                                    </FormLabel>
                                    <DenseInput
                                        error={Boolean(formik.touched.name && formik.errors.name)}
                                        fullWidth
                                        helperText={formik.touched.name && formik.errors.name}
                                        margin="normal"
                                        aria-labelledby="name"
                                        name="name"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel required id="username" sx={style.labelText}>
                                        Username
                                    </FormLabel>
                                    <DenseInput
                                        error={Boolean(formik.touched.username && formik.errors.username)}
                                        fullWidth
                                        helperText={formik.touched.username && formik.errors.username}
                                        margin="normal"
                                        aria-labelledby="username"
                                        name="username"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel required id="password" sx={style.labelText}>
                                        Password
                                    </FormLabel>
                                    <DenseInput
                                        error={Boolean(formik.touched.password && formik.errors.password)}
                                        fullWidth
                                        helperText={formik.touched.password && formik.errors.password}
                                        margin="normal"
                                        aria-labelledby="password"
                                        name="password"
                                        type="password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel required id="confirm_password" sx={style.labelText}>
                                        Confirm password
                                    </FormLabel>
                                    <DenseInput
                                        error={Boolean(
                                            formik.touched.confirm_password && formik.errors.confirm_password,
                                        )}
                                        fullWidth
                                        helperText={
                                            formik.touched.confirm_password && formik.errors.confirm_password
                                        }
                                        margin="normal"
                                        aria-labelledby="confirm_password"
                                        name="confirm_password"
                                        type="password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirm_password}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel required id="email" sx={style.labelText}>
                                        Email
                                    </FormLabel>
                                    <DenseInput
                                        error={Boolean(formik.touched.email && formik.errors.email)}
                                        fullWidth
                                        helperText={formik.touched.email && formik.errors.email}
                                        margin="normal"
                                        aria-labelledby="email"
                                        name="email"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel required id="customer_type" sx={style.labelText}>
                                        Customer type
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="customer_type-radio-buttons-group"
                                        name="customer_type"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.customer_type}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="General customer"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="Cooperate customer"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel id="block_account" sx={style.label}>
                                        Block account
                                    </FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="block_account"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                // value={0}
                                                checked={formik.block_account}
                                                // defaultChecked={formik.is_auto_off === 1}
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                            />
                                        }
                                    />
                                </FormControl>
                                <div>
                                    <div className="pl-3 font-semibold text-lg mb-2">Access Group</div>
                                    <ul className="grid grid-cols-2 gap-2">
                                        {menuList.map((item) => (
                                            <li key={item.permission_id} className="p-2">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedItems.includes(
                                                                item.permission_id,
                                                            )}
                                                            onChange={handleCheckboxChange}
                                                            value={item.permission_id}
                                                        />
                                                    }
                                                    label={item.permission_name}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="flex w-full justify-center">
                                    <Button variant="contained" type="submit">
                                        save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <UpdateUserSec
                            menuList={menuList}
                            selectedItems={selectedItems}
                            handleCheckboxChange={handleCheckboxChange}
                            currentUser={currentUser}
                            switchUser={switchUser}
                            handleCreateUser={handleCreateUser}
                            userDetail={userDetail}
                            setSelectedItems={setSelectedItems}
                            // handleUpdateUser={handleUpdateUser}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default AccountManager;
