
import {
    Button,
    FormControl,
    FormLabel,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import React, { useState } from 'react';
import { useNotification, useStateContext } from '../../../contexts/ContextProvider';

import { ChangePassword } from '../../../api/api';
import { FormStyles } from './';
import { Header } from '../';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';

export const DenseOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    '& .MuiOutlinedInput-input': {
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        fontSize: 14,
        padding: '4px 8px',
    },
    '& .MuiInputBase-input': {
        padding: '4px 8px',
        height: '20px !important',
    },
    '& fieldset': { border: '1px solid #ced4da' },
}));

const ChangePwd = () => {
    const { currentUser } = useStateContext();
    const notify = useNotification();
    const initialValues = {
        current_pwd: '',
        new_pwd: '',
        confirm_pwd: '',
    };
    const [formData, setFormData] = useState(initialValues);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowCurrentPassword = () =>
        setShowCurrentPassword((showCurrentPassword) => !showCurrentPassword);
    const handleClickShowNewPassword = () => setShowNewPassword((showNewPassword) => !showNewPassword);
    const handleClickShowConfirmPassword = () =>
        setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    console.log('file: ChangePwd.jsx:90 => ChangePwd => formData:', formData);
    const handleSubmit = async () => {
        const { current_pwd, new_pwd, confirm_pwd } = formData;
        const { code, data, msg } = await ChangePassword(current_pwd, new_pwd, confirm_pwd);
        console.log('data:', data);
        console.log('code:', code);
        if (code === 0) {
            setFormData(initialValues);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Password was changed successfully!!',
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Password was changed unsuccessfully!',
                },
            });
        }
    };
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            <Header title="Change Password" />
            <div className="mx-4">
                <FormControl sx={FormStyles.formControlStyle}>
                    <FormLabel id="current_pwd" sx={FormStyles.labelText}>
                        Current Password
                    </FormLabel>
                    <DenseOutlinedInput
                        fullWidth
                        aria-labelledby="current_pwd"
                        name="current_pwd"
                        variant="outlined"
                        type={showCurrentPassword ? 'text' : 'password'}
                        value={formData.current_pwd}
                        onChange={handleChange}
                        placeholder="Enter current password"
                        endAdornment={
                            <InputAdornment position="end">
                                <div
                                    className="cursor-pointer"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowCurrentPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                </div>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl sx={FormStyles.formControlStyle}>
                    <FormLabel id="new_pwd" sx={FormStyles.labelText}>
                        New Password
                    </FormLabel>
                    <DenseOutlinedInput
                        fullWidth
                        aria-labelledby="new_pwd"
                        name="new_pwd"
                        type={showNewPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={formData.new_pwd}
                        onChange={handleChange}
                        placeholder="Enter new password"
                        endAdornment={
                            <InputAdornment position="end">
                                <div
                                    className="cursor-pointer"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </div>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl sx={FormStyles.formControlStyle}>
                    <FormLabel id="confirm_pwd" sx={FormStyles.labelText}>
                        Confirm Password
                    </FormLabel>
                    <DenseOutlinedInput
                        fullWidth
                        aria-labelledby="confirm_pwd"
                        name="confirm_pwd"
                        type={showConfirmPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={formData.confirm_pwd}
                        onChange={handleChange}
                        placeholder="Enter confirm password"
                        endAdornment={
                            <InputAdornment position="end">
                                <div
                                    className="cursor-pointer"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </div>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <div className="flex w-full justify-center mt-3">
                    <Button
                        sx={{ minWidth: '120px' }}
                        variant="contained"
                        onClick={() => handleSubmit()}
                        disabled={formData.current_pwd === ''}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChangePwd;
