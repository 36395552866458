import { Accordion, AccordionDetails, AccordionSummary } from '../components/dashboard/Accordion';
import {
    AddFolder,
    EditFolderNameDialog,
    ImageListSection,
    MediaDisplay,
} from '../components/dashboard/media';
import { Box, Button, Drawer, IconButton, Tooltip, Typography } from '@mui/material';
import { DeleteDialog, UploadDialog } from '../components/dashboard/utils';
import { FaEdit, FaFolderOpen, FaRegFolder } from 'react-icons/fa';
import {
    GetMediaCampaignUseNum,
    GetMediaFolderCampaignUseNum,
    GetMediaFolderList,
    GetMediaResourceList,
} from '../api/api';
import React, { useEffect, useState } from 'react';

import { FiTrash2 } from 'react-icons/fi';
import { Header } from '../components/dashboard';
import { ImageTools } from '../components/dashboard/customised-image';
import { useStateContext } from '../contexts/ContextProvider';

const MediaManager = () => {
    const { currentUser, screenSize } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const [folderType, setFolderType] = useState('');
    const [expanded, setExpanded] = useState();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [chosenfolderMedia, setChosenFolderMedia] = useState([]);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);

    //Get media folder list
    const [folderList, setFolderList] = useState([]);
    console.log('file: MediaManager.jsx:180 => MediaManager => folderList:', folderList);
    const getFolderList = async () => {
        const data = await GetMediaFolderList(switchUser, undefined, 100);
        if (data.code === 0) {
            setFolderList(data?.data?.list);
        }
    };

    useEffect(() => {
        getFolderList();
        ChosenFolderMedia(expanded);
    }, [triggerUpdate, switchUser]);

    useEffect(() => {
        setChosenFolderMedia([]);
    }, [expanded]);

    //select folder
    const [currentPage, setCurrentPage] = useState(0); // Current page number
    const [totalMedia, setTotalMedia] = useState();
    // Ensure consistent size for GetMediaResourceList in ChosenFolderMedia & handleLoadMore .
    const pageSize = 15;

    const ChosenFolderMedia = async (folder_id) => {
        const newPage = 1; // Set newPage to 1
        if (expanded !== folder_id) {
            setExpanded(folder_id);
            setFolderType(folderList.find((x) => x.folder_id === folder_id).folder_type);
            const data = await GetMediaResourceList(switchUser, folder_id, undefined, newPage, pageSize);
            console.log('data', data);
            if (data.code === 0) {
                setCurrentPage(newPage + 1);
                setTotalMedia(data.data.count);
                setChosenFolderMedia(data?.data?.list ? data?.data?.list : []);
            }
        } else {
            setExpanded();
        }
    };

    // handleLoadMore
    const handleLoadMore = async (folder_id) => {
        setCurrentPage((prev) => prev + 1);
        const data = await GetMediaResourceList(switchUser, folder_id, undefined, currentPage, pageSize);
        console.log('file: MediaManager.jsx:221 => handleLoadMore => data:', data);
        setChosenFolderMedia([...chosenfolderMedia, ...(data?.data?.list ? data?.data?.list : [])]);
    };

    // Open Edit Dialog
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentFolder, setCurrentFolder] = useState();
    const handleOpenEditDialog = (item) => {
        console.log('item:', item);
        // setFolderId(item.folder_id);
        setCurrentFolder(item);
        // setChangedFolderName(item.folder_name);
        setOpenEditDialog(true);
    };
    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    /**
     *
     * Add media to the folder
     *
     */
    // Add Media Resources to folder
    const handleOpenUploadDialog = () => {
        setOpenUploadDialog(true);
    };
    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
    };

    /***
     *
     * Delete dialog
     *
     */
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const [popUpInfo, setPopUpInfo] = useState();
    const [type, setType] = useState();

    const handleOpenDeleteDialog = (e, item, text) => {
        e.stopPropagation();
        console.log('item', item);
        // setPopUpInfo();
        const lowerCaseText = text?.toLowerCase();

        if (lowerCaseText === 'media') {
            console.log('text:', text);
            setType('media');
            handleGetNumber(item, text);
        } else {
            console.log('text:', text);
            setType('folder');
            handleGetNumber(item, text);
        }
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setPopUpInfo();
        setOpenDeleteDialog(false);
    };

    //  Get number of usage
    const handleGetNumber = async (item, text) => {
        var data;
        const lowerCaseText = text?.toLowerCase();
        if (lowerCaseText === 'media') {
            data = await GetMediaCampaignUseNum(item?.resource_id);
        } else {
            data = await GetMediaFolderCampaignUseNum(item?.folder_id);
        }

        console.log('data:', data.data);
        const total = data.data.total;
        if (total !== 0) {
            setPopUpInfo(
                data.data.total +
                    ' campaign are using media' +
                    (lowerCaseText !== 'media'
                        ? ' files in this folder.'
                        : '. \n Delete this media file will also delete the related campaigns & schedules. Are you sure to continue?'),
            );
        } else setPopUpInfo(' ');
    };

    // Drawer
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <ImageTools />
        </Box>
    );

    const filteredFolderList =
        currentUser.user_level !== 0 ? folderList?.filter((x) => x.folder_type !== 2) : folderList;
    return (
        <div className="m-10 p-2 md:m-10 md:p-10">
            <div className="w-full columns-2 flex justify-between align-middle items-center">
                <Header title="Media Manager" />
                <AddFolder
                    currentUser={currentUser}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                />
            </div>
            {/* <div className="p-4">
                <div className="overflow-auto py-1">
                    <MediaDisplay />
                </div>
            </div> */}
            {/* <div className="w-full columns-2 flex justify-between">
                <Header title="Media Folders" />
                <Button
                    sx={{ marginTop: '15px', marginRight: '22px' }}
                    variant="contained"
                    onClick={toggleDrawer('right', true)}
                >
                    Customise Image
                </Button>
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    // variant="persistent"
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
            </div> */}
            <div className="p-4 pt-0 ">
                {filteredFolderList?.map((item) => (
                    <Accordion
                        key={item.folder_id}
                        expanded={expanded === item.folder_id}
                        onChange={(e) => {
                            e.stopPropagation();
                            setCurrentPage((prev) => (prev = 1));
                            ChosenFolderMedia(item.folder_id);
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={
                                expanded === item.folder_id ? (
                                    <FaFolderOpen
                                        style={{
                                            fontSize: '0.9rem',
                                            color: '#0444AB',
                                        }}
                                    />
                                ) : (
                                    <FaRegFolder
                                        style={{
                                            fontSize: '0.9rem',
                                            // color: '#0444AB',
                                        }}
                                    />
                                )
                            }
                        >
                            <div className="flex justify-between w-full ml-2.5">
                                <div className="flex flex-row">
                                    <Typography>{item.folder_name}</Typography>
                                </div>
                                {item.folder_type !== 2 ||
                                (item.folder_type === 2 && currentUser.user_level === 0) ? (
                                    <div className="flex flex-row z-50 divide-x divide-solid">
                                        {item.folder_type !== 2 && (
                                            <Tooltip
                                                title="Edit folder name"
                                                onClick={(e) => {
                                                    // e.stopPropagation();
                                                    handleOpenEditDialog(item, 'folder');
                                                }}
                                            >
                                                <div>
                                                    <IconButton size="small">
                                                        <FaEdit label="Edit" size={15} color="black" />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        )}
                                        <Tooltip
                                            title="Delete folder"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenDeleteDialog(e, item, 'mediaFolder');
                                            }}
                                        >
                                            <div>
                                                <IconButton size="small">
                                                    <FiTrash2
                                                        size={15}
                                                        style={{ strokeWidth: '3px', color: 'black' }}
                                                    />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            // backgroundColor: 'rgb(248, 249, 249)',
                            sx={{ marginBottom: '0px' }}
                        >
                            <div className=" w-full border-slate-300 mb-8">
                                <ImageListSection
                                    handleOpenUploadDialog={handleOpenUploadDialog}
                                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                                    chosenfolderMedia={chosenfolderMedia}
                                    item={item}
                                    currentUser={currentUser}
                                    screenSize={screenSize}
                                />
                            </div>
                            {chosenfolderMedia?.length != totalMedia && chosenfolderMedia?.length != 0 && (
                                <div className="w-full flex justify-center">
                                    <Button
                                        variant="contained"
                                        onClick={() => handleLoadMore(item.folder_id)}
                                    >
                                        <span className="text-lg">Load More</span>
                                    </Button>
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}

                {openUploadDialog && (
                    <UploadDialog
                        currentUser={currentUser}
                        folderId={expanded}
                        openUploadDialog={openUploadDialog}
                        setOpenUploadDialog={setOpenUploadDialog}
                        handleCloseUploadDialog={handleCloseUploadDialog}
                        setTriggerUpdate={setTriggerUpdate}
                        triggerUpdate={triggerUpdate}
                        folderType={folderType}
                    />
                )}
            </div>
            {openEditDialog && (
                <EditFolderNameDialog
                    currentUser={currentUser}
                    switchUser={switchUser}
                    currentFolder={currentFolder}
                    openEditDialog={openEditDialog}
                    handleCloseEditDialog={handleCloseEditDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    triggerUpdate={triggerUpdate}
                />
            )}

            {openDeleteDialog && popUpInfo && (
                <DeleteDialog
                    openDialog={openDeleteDialog}
                    handleCloseDialog={handleCloseDeleteDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={type}
                    deleteItem={deleteItem}
                    triggerUpdate={triggerUpdate}
                    expanded={expanded}
                    switchUser={switchUser}
                    popUpInfo={popUpInfo}
                    setPopUpInfo={setPopUpInfo}
                    // resourceId={resourceId}
                />
            )}
        </div>
    );
};

export default MediaManager;
