import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';

const PreviewCampaignSec = ({
    currentCampaign,
    maxDuration,
    totalPlaybackDuration,
    campaignDivs,
    setCampaignDivs,
    currentTemplate,
    hours,
    minutes,
    seconds,
}) => {
    console.log('PreviewCampaignSec => campaignDivs:', campaignDivs);
    console.log('PreviewCampaignSec => currentCampaign:', currentCampaign);
    // Calculate total time in seconds
    const totalTimeInSeconds = campaignDivs?.reduce((total, campaign) => {
        return total + campaign.minute * 60 + campaign.second;
    }, 0);
    console.log('totalTimeInSeconds => totalTimeInSeconds:', totalTimeInSeconds);
    const newHours = Math.floor(totalTimeInSeconds / 3600);
    const newMinutes = Math.floor((totalTimeInSeconds % 3600) / 60);
    const newSeconds = totalTimeInSeconds % 60;

    const MediaWidgetElement = ({ element, item }) => {
        const getStyle = (resizeMode) => ({
            objectFit:
                resizeMode === 1
                    ? 'contain'
                    : resizeMode === 2
                    ? 'none'
                    : resizeMode === 3
                    ? 'none'
                    : resizeMode === 4
                    ? 'fill'
                    : 'cover',
            width: resizeMode === 2 ? '100%' : resizeMode === 3 ? 'auto' : '100%',
            height: resizeMode === 2 ? 'auto' : resizeMode === 3 ? '100%' : '100%',
        });
        const carouselRef = useRef(null);
        const videoRef = useRef(null);
        const intervalIdRef = useRef(null); // 新增这一行
        const [activeIndex, setActiveIndex] = useState(0);
        const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
        // console.log('file: CampaignDetailDialog.jsx:92 => MediaWidgetElement => element:', element);
        // console.log('file: CampaignDetailDialog.jsx:92 => CampaignDetailDialog => item:', item);

        const currentDetailList = element?.detail_list;
        const currentMedia = element?.detail_list?.resource_content;

        // Enable auto play the video
        const handleSelect = (selectedIndex, e) => {
            setActiveIndex(selectedIndex);
        };

        const handleVideoEnded = () => {
            // Check if the last video has ended before transitioning to the next item
            const nextIndex = currentVideoIndex + 1;
            if (nextIndex < currentMedia.length) {
                const nextResource = currentMedia[nextIndex];

                // Check if the next resource is a video
                if (nextResource.resource_type === 'video') {
                    carouselRef.current?.next();
                } else {
                    // If the next resource is an image, reset the video index and trigger the autoplay logic
                    setCurrentVideoIndex(0);

                    // Handle autoplay for images
                    const duration =
                        nextResource.resource_type === 'image' ? nextResource.resource_duration * 1000 : null;

                    clearInterval(intervalIdRef.current);

                    if (duration) {
                        intervalIdRef.current = setInterval(() => {
                            carouselRef.current?.next();
                        }, duration);
                    }
                }
            } else {
                // If the last video has ended, reset the index to 0
                setCurrentVideoIndex(0);
            }
        };

        useEffect(() => {
            // const activeMedia = currentDetailList?.find((x) => x.element_id === item.element_id);
            const selectedResource = currentMedia[activeIndex];
            // console.log('useEffect => selectedResource:', selectedResource);

            if (selectedResource) {
                if (selectedResource.resource_type === 'video') {
                    const videoElement = document.getElementById(
                        `video-${selectedResource.resource_id}-${activeIndex}`,
                    );
                    // const videoElement = videoRef.current;
                    if (videoElement) {
                        // console.log('进入了video => videoElement:', videoElement);
                        videoElement.pause();
                        videoElement.currentTime = 0;
                        videoElement.play();
                        // console.log('确保视频加载成功，并成功播放');
                    }
                }

                // Check if it's an image, and handle autoplay based on the resource type
                if (selectedResource.resource_type === 'image') {
                    const duration = selectedResource.resource_duration * 1000;
                    if (duration) {
                        const intervalId = setInterval(() => {
                            // 使用函数式的形式更新 activeIndex
                            carouselRef.current?.next();
                            // setActiveIndex((prevIndex) => (prevIndex + 1) % currentMedia.length);
                        }, duration);

                        return () => {
                            clearInterval(intervalId);
                        };
                    }
                }
            }
        }, [activeIndex, item.element_id]);

        return (
            <Carousel
                ref={carouselRef}
                onSelect={handleSelect}
                activeIndex={activeIndex}
                className="absolute h-full w-full"
                indicators={false}
                controls={false}
                interval={null}
                fade={item?.transition_effect === 1}
                slide={item?.transition_effect !== 1}
            >
                {element?.detail_list?.resource_content?.map(
                    (m, index) =>
                        m.resource_id && (
                            <Carousel.Item
                                className="absolute h-full w-full"
                                interval={
                                    m.resource_duration * 1000 === 0 ? null : m.resource_duration * 1000
                                }
                                key={`${m.resource_id}-${index}`}
                                style={{
                                    transition: `transform ${m.effect_duration}s ease`,
                                }}
                            >
                                {m.resource_type === 'image' ? (
                                    <img
                                        style={getStyle(element?.detail_list?.resize_mode)}
                                        className="absolute"
                                        src={m.resource_url}
                                        time={m.resource_duration}
                                    />
                                ) : (
                                    <video
                                        loading="lazy"
                                        className="max-h-full max-w-full items-center"
                                        style={getStyle(element?.detail_list?.resize_mode)}
                                        onLoadedMetadata={(e) => {
                                            if (e.target.duration > maxDuration) {
                                                maxDuration = e.target.duration;
                                                console.log(
                                                    'file: ScheduleCampaignDialog.jsx:814 => maxDuration:',
                                                    maxDuration,
                                                );
                                                console.log(
                                                    'file: ScheduleCampaignDialog.jsx:813 => e.target.duration:',
                                                    e.target.duration,
                                                );
                                            }

                                            if (currentCampaign.campaign_id === m.campaign_id) {
                                                const newCampaignDivs = [...campaignDivs];
                                                newCampaignDivs[index].minute = Math.floor(
                                                    e.target.duration / 60,
                                                );

                                                newCampaignDivs[index].second = Math.floor(
                                                    e.target.duration % 60,
                                                );

                                                setCampaignDivs(newCampaignDivs);
                                            }
                                        }}
                                        controls
                                        muted // Add the muted attribute
                                        onEnded={handleVideoEnded}
                                        src={m.resource_url}
                                    />
                                )}
                            </Carousel.Item>
                        ),
                )}
            </Carousel>
        );
    };

    const WebWidgetElement = ({ element }) => (
        <div className="w-full h-full">
            {[element?.detail_list?.resource_content.find((x) => x.resource_type === 'web')]?.map(
                (m, index) => (
                    <div className="w-full h-full" key={`${m.resource_text}-${index}`}>
                        <iframe
                            src={m.resource_website}
                            title="Embedded Page"
                            width="100%"
                            height="100%"
                        ></iframe>
                    </div>
                ),
            )}
        </div>
    );

    const applyTextStyle = (styleConfig) => ({
        color: styleConfig.font_colour,
        backgroundColor: styleConfig.background_color,
        textAlign: styleConfig.text_layout,
        fontSize: `${styleConfig.font_size}px !important`,
        fontWeight: styleConfig.font_style.includes('B') ? 'bold !important' : 'normal',
        fontStyle: styleConfig.font_style.includes('I') ? 'italic' : 'normal',
        textShadow: styleConfig.font_style.includes('A') ? '1px 1px 2px black' : 'none',
    });

    const TextWidgetElement = ({ element }) => (
        <div className="w-full h-full">
            {[element?.detail_list?.resource_content.find((x) => x.resource_type === 'text')]?.map(
                (m, index) =>
                    m?.resource_text && m?.resource_text.enable_scrolling === 1 ? (
                        <marquee
                            key={m.element_id}
                            className="w-full h-full"
                            behavior="scroll"
                            direction={m.resource_text.scrolling_direction}
                            scrollamount={m.resource_text.scrolling_speed}
                            style={applyTextStyle(m.resource_text)}
                        >
                            {m.resource_text.text_content}
                        </marquee>
                    ) : (
                        m?.resource_text && (
                            <p className="w-full h-full" style={applyTextStyle(m.resource_text)}>
                                {m.resource_text.text_content}
                            </p>
                        )
                    ),
            )}
        </div>
    );
    return (
        <div className="row-span-2 col-span-1 border-1 border-blue-400 rounded" style={{ minWidth: '400px' }}>
            <div className="h-8 bg-blue-300 p-1 pl-2 text-center">
                <p className="font-bold text-blue-700">Preview: {currentCampaign?.campaign_name}</p>
            </div>
            <div className="flex flex-col w-full justify-center  p-2 mb-2">
                <div className="flex w-full justify-center items-center">
                    <Box
                        className="relative"
                        sx={{
                            margin: '0',
                            marginTop: '12px',
                            //1920x1080
                            width: 490,
                            height: 300,
                            backgroundColor: 'primary.white',
                            border: '5px solid gray',
                        }}
                    >
                        {currentTemplate?.map((element) =>
                            currentCampaign?.detail_list?.map(
                                (item, index) =>
                                    element.element_id &&
                                    item.element_id && (
                                        <div
                                            key={`${element.element_id}-${index}`}
                                            className="absolute border-dashed border-1 border-orange-600"
                                            style={{
                                                width: (element?.element_width / 100) * 480,
                                                height: (element?.element_height / 100) * 290,
                                                top: (element?.element_y / 100) * 290,
                                                left: (element?.element_x / 100) * 480,
                                            }}
                                        >
                                            {element.element_type === 'image' ||
                                            element.element_type === 'video' ||
                                            element.element_type === 'mix' ? (
                                                <MediaWidgetElement element={element} item={item} />
                                            ) : element.element_type === 'text' ? (
                                                <TextWidgetElement element={element} />
                                            ) : element.element_type === 'audio' ? (
                                                <audio
                                                    // ref={audioRef}
                                                    id={`audio-${element.element_id}-${index}`}
                                                    loading="lazy"
                                                    className="items-center"
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        // justifyContent: 'end',
                                                        alignContent: 'end',
                                                    }}
                                                    controls
                                                    // onEnded={handleAudioEnded}
                                                    src={element.resource_url}
                                                />
                                            ) : (
                                                <WebWidgetElement element={element} />
                                            )}
                                        </div>
                                    ),
                            ),
                        )}
                    </Box>
                </div>
                {newSeconds > 0 && (
                    <div className="ml-6 mt-2">
                        <Typography>
                            Total duration: {newHours > 0 ? `${newHours} h` : ''} {newMinutes} mins{' '}
                            {newSeconds} secs
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviewCampaignSec;
