import { DeleteDialog, Pulse } from '../utils';
import { IconButton, List, ListItem, ListItemIcon, Paper, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import EditDeviceDialog from './EditDeviceDialog';
import { FaEdit } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';
import { FiTrash2 } from 'react-icons/fi';
import { GetDeviceInfo } from '../../../api/api';

// import { RiDeleteBin6Fill, RiFolder3Fill, RiSettings2Fill } from 'react-icons/ri';

// const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
//     ({ theme }) => ({
//         borderRadius: '14px',
//         '&:before': {
//             display: 'none',
//         },
//         '&:not(:last-child)': {
//             marginBottom: '16px', // 设置除了最后一个Accordion项之外的每个项的底部外边距，以此来添加间隔
//         },
//     }),
// );

// const AccordionSummary = styled((props) => (
//     <MuiAccordionSummary expandIcon={<GrDown sx={{ fontSize: '0.9rem', color: '#0444AB' }} />} {...props} />
// ))(({ theme }) => ({
//     marginTop: '10px',
//     marginBottom: '10px',
//     // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
//     flexDirection: 'row-reverse',
//     '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//         transform: 'rotate(180deg)',
//     },
//     '& .MuiAccordionSummary-content': {
//         marginLeft: theme.spacing(2),
//     },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//     padding: theme.spacing(2),
//     // borderTop: '1px solid rgba(0, 0, 0, .125)',
// }));
const DeviceList = ({
    assignedDeviceList,
    unassignedDeviceList,
    locationList,
    setTriggerUpdate,
    triggerUpdate,
}) => {
    console.log('unassignedDeviceList', unassignedDeviceList);
    console.log('assignedDeviceList', assignedDeviceList);
    const [assignedExpanded, setAssignedExpanded] = useState('');
    const [unassignedExpanded, setUnassignedExpanded] = useState('');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const handleAssignedExpand = (panel) => (newExpanded) => {
        console.log('panel', panel);
        setAssignedExpanded(newExpanded ? (panel === assignedExpanded ? false : panel) : false);
    };
    const handleUnassignedExpand = (panel) => (newExpanded) => {
        console.log('panel', panel);
        setUnassignedExpanded(newExpanded ? (panel === unassignedExpanded ? false : panel) : false);
    };

    // Fetch detail of all the devices
    const [assignedDeviceDetail, setAssignedDeviceDetail] = useState([]);
    const [unassignedDeviceDetail, setUnassignedDeviceDetail] = useState([]);

    // Open edit device dialog
    const [deviceDetail, setDeviceDetail] = useState();
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const getDetail = async (item) => {
        const data = await GetDeviceInfo(item.device_id);
        console.log('file: DeviceList.jsx:87 => getDetail => data:', data);
        setDeviceDetail(data.data);
    };
    const handleOpenEditDialog = (item) => {
        console.log('item', item);
        getDetail(item);
        // localStorage.setItem(
        // 	'currentDevice',
        // 	JSON.stringify(DeviceDetailList.find((x) => x.device_id === item.device_id))
        // );
        // setDeviceDetail(DeviceDetailList.find((x) => x.device_id === item.device_id));
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (item) => {
        console.log('item', item);
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <div className="grid grid-cols-2 gap-4 ">
            <Paper elevation={0} sx={{ height: '500px', padding: '20px', overflowY: 'auto' }}>
                <Typography sx={{ marginLeft: '15px' }}>Unassigned</Typography>
                <List dense={true} sx={{ marginTop: '35px' }}>
                    {unassignedDeviceList ? (
                        unassignedDeviceList?.map((item, index) => (
                            <ListItem
                                className="hover:bg-blue-100/50 rounded-lg cursor-pointer"
                                key={item.device_name}
                                onClick={() => handleOpenEditDialog(item)}
                                secondaryAction={
                                    <div className="flex flex-row divide-x divide-solid">
                                        <div>
                                            <Tooltip title="Edit">
                                                <IconButton size="small">
                                                    <FaEdit label="Edit" size={15} color="black" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        {currentUser.user_level === 0 && (
                                            <div>
                                                <Tooltip
                                                    title="Delete"
                                                    // onClick={(e) => e.stopPropagation()}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenDeleteDialog(item);
                                                    }}
                                                >
                                                    <IconButton size="small">
                                                        <FiTrash2
                                                            size={15}
                                                            style={{ strokeWidth: '3px', color: 'black' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                }
                            >
                                <ListItemIcon>
                                    <FiMonitor
                                        style={{
                                            color: item?.device_status === 'OFFLINE' ? 'red' : 'green',
                                            fontSize: '20px',
                                        }}
                                    />
                                </ListItemIcon>

                                <div className="flex flex-col">
                                    <div className="w-[200px]">
                                        <p className="m-0  text-ellipsis overflow-hidden">
                                            {item?.device_name}{' '}
                                        </p>
                                    </div>
                                    <span className="text-[10px] text-black-500  rounded pb-1 ">
                                        <span
                                            className={`${
                                                item?.device_status === 'OFFLINE'
                                                    ? 'text-red-500'
                                                    : 'text-green-500'
                                            } text-sx font-bold`}
                                        >
                                            {item?.device_status}
                                        </span>
                                        <span>
                                            {item?.device_status === 'OFFLINE' &&
                                                ` Last online: ${item?.last_online}`}
                                        </span>
                                    </span>
                                </div>
                            </ListItem>
                        ))
                    ) : (
                        <Pulse />
                    )}
                </List>
            </Paper>

            <Paper elevation={0} sx={{ height: '500px', padding: '20px', overflowY: 'auto' }}>
                <Typography sx={{ marginLeft: '15px' }}>Assigned</Typography>
                <List dense={true} sx={{ marginTop: '40px' }}>
                    {assignedDeviceList?.map((item, index) => (
                        <ListItem
                            className="hover:bg-blue-100/50 rounded-lg cursor-pointer"
                            key={item.device_name}
                            onClick={() => handleOpenEditDialog(item)}
                            secondaryAction={
                                <div className="flex flex-row divide-x divide-solid">
                                    <div>
                                        <Tooltip title="Edit">
                                            <IconButton size="small">
                                                <FaEdit label="Edit" size={15} color="black" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {currentUser.user_level === 0 && (
                                        <div>
                                            <Tooltip
                                                title="Delete"
                                                // onClick={(e) => e.stopPropagation()}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenDeleteDialog(item);
                                                }}
                                            >
                                                <IconButton size="small">
                                                    <FiTrash2
                                                        size={15}
                                                        style={{ strokeWidth: '3px', color: 'black' }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            }
                        >
                            <ListItemIcon>
                                <FiMonitor
                                    style={{
                                        color: item?.device_status === 'OFFLINE' ? 'red' : 'green',
                                        fontSize: '20px',
                                    }}
                                />
                            </ListItemIcon>
                            <div className="flex flex-col">
                                <div className="w-[200px]">
                                    <p className="m-0  text-ellipsis overflow-hidden">{item?.device_name} </p>
                                </div>
                                <span className="text-[10px] text-black-500  rounded pb-1 ">
                                    <span
                                        className={`${
                                            item?.device_status === 'OFFLINE'
                                                ? 'text-red-500'
                                                : 'text-green-500'
                                        } text-sx font-bold`}
                                    >
                                        {item?.device_status}
                                    </span>
                                    <span>
                                        {item?.device_status === 'OFFLINE' &&
                                            ` Last online: ${item?.last_online}`}
                                    </span>
                                </span>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </Paper>
            {openEditDialog && (
                <EditDeviceDialog
                    openEditDialog={openEditDialog}
                    handleCloseEditDialog={handleCloseEditDialog}
                    deviceDetail={deviceDetail}
                    currentUser={currentUser}
                    locationList={locationList}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                />
            )}
            {openDeleteDialog && (
                <DeleteDialog
                    openDialog={openDeleteDialog}
                    handleCloseDialog={handleCloseDeleteDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={'device'}
                    deleteItem={deleteItem}
                    triggerUpdate={triggerUpdate}
                />
            )}
        </div>
    );
};

export default DeviceList;
