import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { isTemplateInUse } from '../../../api/api';
import React, { useState } from 'react';


const EditTemplateDialog = ({
    openEditTemplateDialog,
    handleCloseEditTemplateDialog,
    currentTemplate,
    setOpenCreateTemplateDialog,
    setEditText,

    currentUser,
    // triggerUpdate,
    // setTriggerUpdate,
}) => {
    console.log('file: EditTemplateDialog.jsx:19 => currentTemplate:', currentTemplate);
    const [templateInUse, setTemplateInUse] = useState();

    const checkTemplateUsage = async (templateId) => {
        const { data } = await isTemplateInUse(templateId);
        console.log('\n========== data =============\n', data);
        if (data.count > 0) {
            setTemplateInUse(data.list);
        } else {
            setOpenCreateTemplateDialog(true);
            handleCloseEditTemplateDialog();
        }
    };
    const handleEdit = () => {
        setEditText('Edit');
        checkTemplateUsage(currentTemplate.template_id);
        // setOpenCreateTemplateDialog(true);
        // handleCloseEditTemplateDialog();
    };
    return (
        <>
            <Dialog
                open={openEditTemplateDialog}
                // PaperProps={{ sx: { width: '45%' } }}
            >
                <DialogTitle
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        paddingBottom: '0',
                    }}
                >
                    <div className="whitespace-pre-line">
                        {templateInUse
                            ? `This template is in use with Campaign: [${templateInUse}]\nPlease make a copy and edit.`
                            : 'Are you sure you want to edit this template?'}
                    </div>
                </DialogTitle>

                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    {templateInUse ? (
                        <Button variant="contained" onClick={handleCloseEditTemplateDialog}>
                            Close
                        </Button>
                    ) : (
                        <>
                            <Button variant="contained" onClick={handleCloseEditTemplateDialog}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={() => handleEdit()}>
                                Edit
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditTemplateDialog;
