import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
} from '@mui/material';

import { EditDeviceDialog } from '../device';
import { FiMonitor } from 'react-icons/fi';
import { GrEdit } from 'react-icons/gr';
import React from 'react';

const DevicesDialog = ({
    openDevicesDialog,
    handleCloseDevicesDialog,
    locationDetail,
    handleOpenEditDialog,
    openEditDialog,
    handleCloseEditDialog,
    deviceDetail,
    currentUser,
    locationList,
    triggerUpdate,
    setTriggerUpdate,
}) => {
    // const theme = useTheme();
    const locationInfo = locationDetail?.group_info;
    const deviceList = locationDetail?.assign_device_list;
    const onlineDeviceList = deviceList?.filter((x) => x.device_status === 'ONLINE');
    console.log('DevicesDialog => onlineDeviceList:', onlineDeviceList);
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={openDevicesDialog}
            onClose={handleCloseDevicesDialog}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {locationInfo?.group_name +
                    ' - ' +
                    (locationInfo?.target_layout === 1 ? 'Landscape' : 'Portrait')}
            </DialogTitle>
            <DialogContent>
                <List dense={true}>
                    {deviceList?.map((item, index) => (
                        <ListItem
                            className="hover:bg-blue-100/50 rounded-lg cursor-pointer"
                            key={item.device_name}
                            onClick={() => handleOpenEditDialog(item)}
                            secondaryAction={
                                <div className="flex flex-row">
                                    <Tooltip title="Device Settings">
                                        <div>
                                            <IconButton size="small">
                                                <GrEdit />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                        >
                            <ListItemIcon>
                                <FiMonitor
                                    style={{
                                        color: item?.device_status === 'OFFLINE' ? 'red' : 'green',
                                        fontSize: '20px',
                                    }}
                                />
                            </ListItemIcon>
                            <div>
                                <p className="text-center m-0 ">
                                    {item?.device_name}{' '}
                                    <span className="text-[10px] text-black-500  px-1 rounded pb-1 ">
                                        <span
                                            className={`${
                                                item?.device_status === 'OFFLINE'
                                                    ? 'text-red-500'
                                                    : 'text-green-500'
                                            } text-sx font-bold`}
                                        >
                                            {item?.device_status}
                                        </span>
                                        {item?.device_status === 'OFFLINE' &&
                                            ` Last online: ${item?.last_online}`}
                                    </span>
                                </p>
                            </div>
                        </ListItem>
                    ))}
                </List>
                {openEditDialog && (
                    <EditDeviceDialog
                        openEditDialog={openEditDialog}
                        handleCloseEditDialog={handleCloseEditDialog}
                        deviceDetail={deviceDetail}
                        currentUser={currentUser}
                        locationList={locationList}
                        triggerUpdate={triggerUpdate}
                        setTriggerUpdate={setTriggerUpdate}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCloseDevicesDialog} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DevicesDialog;
