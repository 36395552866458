import { Advantage, AnimationWrapper, Portfolio, StatCard, Testimonial, Title } from '../components/website';
import { Card, MobileStepper, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { AiOutlineLineChart } from 'react-icons/ai';
import Button from '@mui/material/Button';
import { FaPuzzlePiece } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { InView } from 'react-intersection-observer';
import { IoLogoGameControllerA } from 'react-icons/io';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { MdDesignServices } from 'react-icons/md';
import { MdLock } from 'react-icons/md';
import { RiShareBoxFill } from 'react-icons/ri';
import SwipeableViews from 'react-swipeable-views';
// import aboutUs from '../data/about-us.png';
import { autoPlay } from 'react-swipeable-views-utils';
// import hardware1 from '../data/hardware1.jpg';
// import hardware2 from '../data/hardware2.jpg';
// import hardware3 from '../data/hardware3.jpg';
import { useStateContext } from '../contexts/ContextProvider';

// const CLIENT_LOGO_PATH = '../data/client-logo/'
// const ClIENTS = ['4WD','9news','Autobarn','Berkowitz','BGS','Breadtop','Callaway','Dulux','endeavour','Epworth','Equifax','Fisher_Paykel','Floorworld','Focuson','Godfrey','Hairhouse','Helloworld','Italktravel','Jo_Mercer','Kennards','m_k','Mitre10','Newcentury','Officeworks','Parliament_of_victoria','PGA','QLS_group','samsonite','Scotch_collge','ShoeHQ','STIHL','SushiHub','Telstra','Tempur','toyota']
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const IMG_NUM = 4;

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
}

const images = importAll(require.context('../data/client-logo/', false, /\.png/));

const Home = () => {
    const { setScreenSize } = useStateContext();

    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % IMG_NUM);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep ? prevActiveStep - 1 : IMG_NUM - 1));
    };

    return (
        <div className="w-full">
            <div className=" dark:bg-main-dark-bg w-full">
                <div className="w-full h-screen justify-center">
                    <AutoPlaySwipeableViews
                        enableMouseEvents
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                    >
                        <div className="bg-carousel-1 bg-60-50 h-screen flex justify-center items-center">
                            <Typography
                                variant="h3"
                                className="text-white"
                                sx={{ textShadow: '1px 1px 3px rgb(0,0,0)' }}
                            >
                                <strong className="text-lg-vw xl:text-md-vw">
                                    DIGITAL SIGNAGE MADE EASY
                                    <div className="pt-4 text-xl-vw xl:text-lg-vw">
                                        Content Creation & Management,
                                        <br /> Hardware Installation & Setup
                                    </div>
                                </strong>
                            </Typography>
                        </div>
                        <div className="bg-carousel-2 bg-center h-screen flex justify-center items-center">
                            <Typography
                                variant="h3"
                                className="text-white text-center"
                                sx={{ textShadow: '1px 1px 3px rgb(0,0,0)' }}
                            >
                                <strong className="text-md-vw xl:text-sm-vw">
                                    With
                                    <div className="text-lg-vw xl:text-md-vw">CYBERCAST</div>
                                    <div className="text-xl-vw xl:text-lg-vw">
                                        Increase your sales growth and profit
                                    </div>
                                </strong>
                            </Typography>
                        </div>
                        <div className="bg-carousel-3 bg-60-50 h-screen flex justify-center items-center">
                            <Typography
                                variant="h3"
                                className="text-white text-right"
                                sx={{ textShadow: '1px 1px 3px rgb(0,0,0)' }}
                            >
                                <strong className="text-lg-vw xl:text-md-vw">
                                    Anywhere, Anytime
                                    <div className="pt-4 text-xl-vw xl:text-lg-vw">
                                        Reach your target audience
                                        <br /> efficiently and effectively
                                    </div>
                                </strong>
                            </Typography>
                        </div>
                        <div className="bg-carousel-4 bg-center h-screen ">
                            <div className="flex max-w-5xl mx-auto justify-start items-center h-screen px-16 lg:px-32">
                                <Typography
                                    variant="h3"
                                    className="text-white"
                                    sx={{
                                        textShadow: '1px 1px 3px rgb(0,0,0)',
                                    }}
                                >
                                    <strong className="text-xl-vw xl:text-lg-vw">
                                        Communicate
                                        <br />
                                        Engage
                                        <br />
                                        Interact
                                    </strong>
                                </Typography>
                            </div>
                        </div>
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        className="relative bottom-10 bg-transparent group"
                        position="static"
                        steps={4}
                        activeStep={activeStep}
                        sx={{
                            justifyContent: 'center',
                            '.MuiMobileStepper-dotActive': {
                                backgroundColor: 'white',
                            },
                            '.MuiMobileStepper-dot': { width: 16, height: 16 },
                        }}
                        nextButton={
                            <IconButton size="small" onClick={handleNext}>
                                <KeyboardArrowRight className="group-hover:fill-white" />
                            </IconButton>
                        }
                        backButton={
                            <IconButton size="small" onClick={handleBack}>
                                <KeyboardArrowLeft className=" group-hover:fill-white" />
                            </IconButton>
                        }
                    />
                </div>

                <div className="w-full pb-10 pt-20 bg-white first-letter:">
                    <div className="max-w-7xl mx-auto px-4 lg:px-8">
                        <Grid
                            className=" items-center h-fit"
                            container
                            spacing={5}
                            columns={{ sm: 6, md: 12 }}
                        >
                            <Grid item xs={6}>
                                <AnimationWrapper animationType="slide-left">
                                    <Title text="WELCOME TO " strong="CYBERCAST" />
                                </AnimationWrapper>
                                <div className="overflow-hidden">
                                    <AnimationWrapper animationType="slide-bottom">
                                        <Typography variant="body1" sx={{ fontSize: 15 }}>
                                            <br />
                                            <br />
                                            Cybercast has brought to market an advanced digital signage
                                            interface allowing content for multiple display devices to be
                                            managed through a web browser and smart app.
                                            <br />
                                            <br />
                                            We are a service provider of business information technology and
                                            communications solutions, committed to making it simple for
                                            businesses to reach their targeted audience through our dynamic
                                            digital signage platform and smart hardware.
                                            <br />
                                            <br />
                                            By providing our customer’s innovative ways to drive sales,
                                            improve customer experiences and reinforce brand awareness,
                                            Cybercast can power the digital communications needs of small
                                            businesses through to large organisations. Cybercast is also a
                                            completely scalable, cost effective solution, which is easy to
                                            manage and dependable .
                                            <br />
                                            <br />
                                            Let us help you take your business to another level.
                                        </Typography>
                                    </AnimationWrapper>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <AnimationWrapper animationType="scale-in">
                                    {/* <Skeleton variant='rectangular' className='w-full pt-full'/> */}
                                    <img
                                        src={
                                            'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+data/data/about-us.png'
                                        }
                                        alt="About Us"
                                    />
                                </AnimationWrapper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className=" bg-light-gray w-full pt-20 pb-20">
                    <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 ">
                        <div className=" text-center">
                            <AnimationWrapper animationType="slide-left">
                                <Title text="WHY " strong="DIGITAL SIGNATURE" />
                                <Typography variant="body1" className="pt-3 pb-10" sx={{ fontSize: 15 }}>
                                    Key Statistics That Prove Digital Signage is an Essential Business Tool*
                                </Typography>
                            </AnimationWrapper>
                        </div>
                        <Grid
                            container
                            spacing={3}
                            columns={{ sm: 6, md: 6, lg: 12 }}
                            className=" justify-center"
                        >
                            <StatCard
                                title="Views"
                                // Icon={MdVisibility}
                                body1="Digital Displays capture"
                                strong=" 400% "
                                body2="more views than static displays."
                                className="delay"
                            />
                            <StatCard
                                title="Recall Rate"
                                //Icon={GiBrain}
                                body1=""
                                strong="83% "
                                body2="of people recalling at least one ad seen on a digital billboard in the past 30 days."
                                delay="sm-delay"
                                className="delay"
                            />
                            <StatCard
                                title="Engaging"
                                //Icon={FaMagnet}
                                body1=""
                                strong="8/10 "
                                body2="customers entered a store because of a sign catching their interest."
                                delay="md-delay"
                                className="delay"
                            />
                            <StatCard
                                title="Sales"
                                //Icon={FaMoneyBillWave}
                                body1=""
                                strong="80% "
                                body2="of brands experienced an increase of up to 33% in additional sales through the use of digital signage."
                                delay="lg-delay"
                                className="delay"
                            />
                        </Grid>
                    </div>
                </div>

                <div className="bg-contact-us w-full bg-no-repeat bg-cover pt-20 pb-20">
                    <div className="max-w-7xl mx-auto px-4 lg:px-8 justify-center text-center">
                        <Title
                            text="ENGAGE"
                            strong=" YOUR AUDIENCE"
                            textColor="text-white"
                            strongColor="text-white"
                        />
                        <div className="w-full pt-3">
                            <Link to={'../contact'} className="no-underline">
                                <Button
                                    variant="contained"
                                    className="px-4 py-2"
                                    sx={{ textTransform: 'none' }}
                                >
                                    <Typography variant="p" className="text-white" sx={{ fontSize: 20 }}>
                                        Contact Us
                                    </Typography>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className=" bg-light-gray w-full pt-20 pb-20">
                    <div className="max-w-7xl mx-auto px-4 lg:px-8 justify-center text-center">
                        <AnimationWrapper animationType="slide-left">
                            <Title text="OUR " strong="DIGITAL SIGNATURE PLATFORM" />
                        </AnimationWrapper>
                        <AnimationWrapper animationType="slide-right">
                            <Typography variant="body1" sx={{ fontSize: 15 }} className="py-10">
                                Cybercast is a visual communications software platform designed to be agile
                                and powerful. With the ability to leverage data coming from multiple sources
                                to deliver a much more relevant customer experience via digital screens,
                                tablets and kiosks. Display dynamic, targeted messages to increase sales,
                                engage your audience and broadcast information in real-time.
                            </Typography>
                        </AnimationWrapper>
                        <Grid container columns={{ sm: 4 }} spacing={5}>
                            <Advantage
                                title="Simplicity by Design"
                                dotPoints={[
                                    'User-friendly software',
                                    'Quick hardware deployment',
                                    'Rock-solid reliable cloud infrastructure',
                                ]}
                                Icon={MdDesignServices}
                            />
                            <Advantage
                                title="Scale & Reach"
                                dotPoints={[
                                    'Broadcast to a single screen or multiple screens',
                                    'From a corner milk bar to a national franchise network',
                                    'Our platform is highly scalable',
                                ]}
                                Icon={RiShareBoxFill}
                                reverse
                            />
                            <Advantage
                                title="Full Total Control"
                                dotPoints={[
                                    'Real-time and scheduled broadcast',
                                    'Engage your audience effectively and timely',
                                    'Update devices, apps, content and monitor remotely',
                                ]}
                                Icon={IoLogoGameControllerA}
                            />
                            <Advantage
                                title="Fully Managed Service"
                                dotPoints={[
                                    'Your one-stop shop for digital signage',
                                    'Software, Hardware, Content Creation, Installation & Support',
                                    'Bespoke solution',
                                ]}
                                Icon={FaPuzzlePiece}
                                reverse
                            />
                            <Advantage
                                title="Security"
                                dotPoints={[
                                    'Securely publish and receive content',
                                    'Lockdown devices remotely',
                                    'Secured cloud-based storage of your media',
                                ]}
                                Icon={MdLock}
                            />
                            <Advantage
                                title="Maximise ROI"
                                dotPoints={[
                                    'Gain and retain customer engagement',
                                    'Attain brand exposure',
                                    'Generate revenue from advertisement',
                                ]}
                                Icon={AiOutlineLineChart}
                                reverse
                            />
                        </Grid>
                    </div>
                </div>
                <div className="w-full pb-20 bg-wave bg-repeat bg-cover bg-bottom">
                    <div className="max-w-7xl mx-auto px-4 lg:px-8 justify-center text-center flex-row flex ">
                        <Grid container columns={{ md: 12 }}>
                            <Grid item xs={7}>
                                <AnimationWrapper
                                    animationType="slide-top"
                                    className="flex place-items-center h-full"
                                >
                                    <div>
                                        <Title text="OUR" strong=" HARDWARE" />
                                        <Typography className="py-10">
                                            We have a wide and varied range of Digital Displays that will meet
                                            all your display requirements. We have displays for Video Walls,
                                            Touchscreen Interactive Displays, Ultra HD Screens and Mobile
                                            Devices for Digital Signage. Indoor and Outdoor Commercial
                                            Displays, Commercial TV's for hospitality and Commercial Displays
                                            and Commercial Monitors for office use. Showcase your Company,
                                            your Products and Services on our Superb Digital Screens. We can
                                            even bespoke your hardware solution.
                                        </Typography>
                                    </div>
                                </AnimationWrapper>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container columns={3} className="justify-center">
                                    {[
                                        {
                                            src: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+data/data/hardware1.jpg',
                                            text: 'Ultra HD Screens & Tablets',
                                            animation: 'slide-right',
                                            translateX: '32',
                                        },
                                        {
                                            src: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+data/data/hardware2.jpg',
                                            text: 'Touch Kiosks & Video Walls',
                                            animation: 'slide-right',
                                            translateX: '10',
                                        },
                                        {
                                            src: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+data/data/hardware3.jpg',
                                            text: 'Stands & Mountss',
                                            animation: 'slide-right',
                                            translateX: '32',
                                            textColor: 'white',
                                        },
                                    ].map((item) => (
                                        <InView
                                            key={item.text}
                                            onChange={(inView, entry) => {
                                                entry.target.classList.toggle(
                                                    'is-inViewport',
                                                    entry.isIntersecting,
                                                );
                                            }}
                                            triggerOnce
                                        >
                                            {({ inView, ref, entry }) => (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    ref={ref}
                                                    data-inviewport={item.animation}
                                                    className={`delay`}
                                                >
                                                    <img src={item.src} className={`h-60 m-auto`} />
                                                    <Typography
                                                        className={`relative} text-${
                                                            item.textColor ? item.textColor : 'black'
                                                        }`}
                                                    >
                                                        {item.text}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </InView>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="bg-testimonial bg-no-repeat bg-cover py-20 w-full h-full">
                    <div className="max-w-7xl mx-auto px-4 lg:px-8 justify-center text-center">
                        <AnimationWrapper animationType="slide-left">
                            <Title text="OUR" strong=" TESTIMONIAL" textColor="text-white" />
                            <Typography className="text-white pb-16">
                                Hear from some our clients about their successful stories.
                            </Typography>
                        </AnimationWrapper>
                        <Grid container columns={{ md: 9 }} spacing={5}>
                            <Testimonial
                                author="Guy Chapple"
                                job="Director - Golf Business Forum"
                                paragraphs={[
                                    'Around 200 golf industry leaders gathered in Melbourne in late July 2016 for the inaugural Golf Business Forum. SONIQ was a valuable partner of the event.',
                                    'The kiosks delivered a contemporary, dynamic and flexible opportunity for sponsors to showcase and bring their company’s product and/or service to life.',
                                    'Our sponsors really embraced the kiosks and most abandoned the traditional and tired-looking pull-up banner! This created some great engagement with delegates.',
                                ]}
                            />
                            <Testimonial
                                author="Melissa Gullifer"
                                job="Marketing Manager - Focus on Furniture"
                                paragraphs={[
                                    'Focus On Furniture and Bedding have used the Kiosks in our smaller store locations to be able to offer our customers in those locations a wider range of products that they would otherwise not be able to view in store. We have found that our customers even on their own accord are drawn to this new technology, often without prompting they will go straight to the units and search for the products they are after. This can also be helpful when the store is busy and staff cannot always attend to all customers at the one time.',
                                ]}
                            />
                            <Testimonial
                                author="Anthony Lau"
                                job="IT Manager - Express Travel Group"
                                paragraphs={[
                                    'Express Travel Group/italktravel has very much enjoyed a working relationship with Cybercast over the past 2 years. The service and online digital signage system provided by them is just great!. Uploading an image to the TV can be done with only a few clicks, much easier and user friendly compare to the other digital signage system we have previously used.',
                                    'It does attract people walking into the store and enquire about the offer being displayed on the Signage. We strongly endorse Cybercast product.',
                                ]}
                            />
                        </Grid>
                    </div>
                </div>
                <div className="bg-white py-20 w-full">
                    <div className="max-w-7xl mx-auto px-4 lg:px-8 text-center">
                        <AnimationWrapper animationType="slide-top" className="w-full">
                            <Title text="OUR " strong="CLIENT" />
                        </AnimationWrapper>
                        <Grid container spacing={5} columns={{ xs: 3, sm: 9, md: 15 }} className="pt-10">
                            {Object.entries(images).map(([key, image]) => {
                                return (
                                    <InView
                                        key={`${key}`}
                                        onChange={(inView, entry) => {
                                            entry.target.classList.toggle(
                                                'is-inViewport',
                                                entry.isIntersecting,
                                            );
                                        }}
                                        triggerOnce
                                    >
                                        {({ inView, ref, entry }) => (
                                            <Grid
                                                item
                                                xs={3}
                                                ref={ref}
                                                data-inviewport="scale-in"
                                                className="delay"
                                            >
                                                <a href={links[key]} target="_blank" rel="noreferrer">
                                                    <Card
                                                        raised
                                                        className="p-3"
                                                        sx={{
                                                            borderRadius: 4,
                                                        }}
                                                    >
                                                        <div className="w-full h-full animate-shrink-and-expand-reverse group hover:animate-shrink-and-expand">
                                                            <img
                                                                src={image}
                                                                className="grayscale group-hover:filter-none"
                                                            />
                                                        </div>
                                                    </Card>
                                                </a>
                                            </Grid>
                                        )}
                                    </InView>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
                <div className="bg-light-gray w-full pt-20 pb-20">
                    <div className="mx-auto px-4 lg:px-8 text-center w-full">
                        <Title text="CHECK OUR AWESOME " strong="PORTFOLIO" />
                        <Portfolio />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

const links = {
    '4WD.png': 'https://www.4wdsupacentre.com.au/',
    '9news.png': 'https://www.9news.com.au/',
    'Autobarn.png': 'https://autobarn.com.au/ab/',
    'BGS.png': 'https://www.brightongrammar.vic.edu.au/',
    'Berkowitz.png': 'https://berkowitz.com.au/',
    'Breadtop.png': 'https://www.breadtop.com.au/',
    'Callaway.png': 'https://au.callawaygolf.com/',
    'Dulux.png': 'https://www.dulux.com.au/',
    'Epworth.png': 'https://www.epworth.org.au//',
    'Equifax.png': 'https://www.equifax.com.au/personal/',
    'Fisher_Paykel.png': 'https://www.fisherpaykel.com/au/',
    'Floorworld.png': 'https://www.floorworld.com.au/',
    'Focuson.png': 'https://focusonfurniture.com.au/',
    'Godfrey.png': 'https://www.godfreyhirst.com/au',
    'Hairhouse.png': 'https://www.hairhouse.com.au/',
    'Helloworld.png': 'https://www.helloworld.com.au/',
    'Italktravel.png': 'http://horsham.italk.travel/',
    'Jo_Mercer.png': 'https://www.jomercer.com.au/',
    'Kennards.png': 'https://www.kss.com.au/',
    'Mitre10.png': 'https://www.mitre10.com.au/',
    'Newcentury.png': 'https://newcenturyresources.com/',
    'Officeworks.png': 'https://www.officeworks.com.au/',
    'PGA.png': 'https://pga.org.au/',
    'Parliament_of_victoria.png': 'https://new.parliament.vic.gov.au/',
    'QLS_group.png': 'http://www.qlslogistics.com.au/',
    'STIHL.png': 'https://www.stihl.com.au/',
    'Scotch_college.png': 'https://www.scotch.vic.edu.au/',
    'ShoeHQ.png': 'https://www.shoehq.com.au/',
    'SushiHub.png': 'https://www.sushihub.com.au/',
    'Telstra.png': 'https://www.telstra.com.au/',
    'Tempur.png': 'https://au.tempur.com/',
    'endeavour.png': 'https://www.endeavour.com.au/',
    'm_k.png': 'https://mk.com.au/',
    'samsonite.png': 'https://www.samsonite.com.au/',
    'toyota.png': 'https://www.pattersoncheneytoyota.com.au/',
};
