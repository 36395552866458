import { List, ListItem, ListItemButton } from '@mui/material';

import { Pulse } from '../utils';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { Header, Navbar } from '../components/dashboard';
import React from 'react';

// import { UpdateUserSec, UserList } from '../components/dashboard/user';

// import { links } from '../../data/dummy';

const UserList = ({ userList, handleGetUserDetails, currentUser }) => {
    // const [userList, setUserList] = useState([]);

    return (
        <div className="col-span-1 h-full p-4 rounded-3xl bg-white">
            <div className="pl-3 font-semibold text-lg">User</div>
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                    maxHeight: 700,
                    '& ul': { padding: 0 },
                }}
                subheader={<li />}
            >
                <ul>
                    {userList?.length > 0 ? (
                        userList.map(
                            (item) =>
                                item.user_level !== currentUser.user_level && (
                                    <ListItemButton
                                        key={item.user_id}
                                        onClick={() => handleGetUserDetails(item.user_id)}
                                    >
                                        <ListItem>
                                            {item.username}
                                            {/* <ListItemText primary={item.username} /> */}
                                        </ListItem>
                                    </ListItemButton>
                                ),
                        )
                    ) : (
                        <Pulse />
                    )}
                </ul>
            </List>
        </div>
    );
};

export default UserList;
