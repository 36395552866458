import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { addMedia, resetMedia } from '../../../redux/action';

import Carousel from 'react-bootstrap/Carousel';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CampaignDetailDialog = ({ handleDeleteCampaign, currentCampaign, open, handleCloseCampaignDetail }) => {
    console.log('currentCampaign', currentCampaign);
    const navigate = useNavigate();
    const [currentTemplate, setCurrentTemplate] = useState();
    console.log('currentTemplate:', currentTemplate);

    const dispatch = useDispatch();
    useEffect(() => {
        const detailList = currentCampaign?.detail_list;
        console.log('detailList:', detailList);
        const elementList = [];

        for (let i = 0; i < detailList?.length; i++) {
            elementList.push(detailList[i].template_element);
        }
        console.log('elementList 1:', elementList);

        const newList = detailList?.map(({ template_element, ...rest }) => rest);
        elementList.map((element) => {
            const matchedElement = newList.find((x) => x.element_id === element.element_id);
            if (matchedElement) {
                element.detail_list = matchedElement;
                return element;
            }
        });

        setCurrentTemplate(elementList);
    }, [currentCampaign]);
    const handleEditCampaign = (currentCampaign) => {
        console.log('currentCampaign', currentCampaign);
        localStorage.setItem('editCurrentCampaign', JSON.stringify(currentCampaign));
        navigate('../dashboard/playlist/edit-campaign');
        handleAddMedia(currentCampaign.detail_list);
    };

    const handleAddMedia = (media) => {
        const new_list = media.map(
            ({ effect_duration, element_id, transition_effect, resource_content }) => ({
                effect_duration,
                element_id,
                transition_effect,
                resource_list: resource_content,
            }),
        );
        console.log('new_list:', new_list);
        dispatch(resetMedia());

        dispatch(addMedia(new_list));
    };

    const applyTextStyle = (styleConfig) => ({
        color: styleConfig.font_colour,
        backgroundColor: styleConfig.background_color,
        textAlign: styleConfig.text_layout,
        fontSize: `${styleConfig.font_size}px !important`,
        fontWeight: styleConfig.font_style.includes('B') ? 'bold !important' : 'normal',
        fontStyle: styleConfig.font_style.includes('I') ? 'italic' : 'normal',
        textShadow: styleConfig.font_style.includes('A') ? '1px 1px 2px black' : 'none',
    });

    const TextWidgetElement = ({ element }) => (
        <div className="w-full h-full">
            {[element?.detail_list?.resource_content.find((x) => x.resource_type === 'text')]?.map(
                (m, index) =>
                    m?.resource_text && m?.resource_text.enable_scrolling === 1 ? (
                        <marquee
                            key={m.element_id}
                            className="w-full h-full"
                            behavior="scroll"
                            direction={m.resource_text.scrolling_direction}
                            scrollamount={m.resource_text.scrolling_speed}
                            style={applyTextStyle(m.resource_text)}
                        >
                            {m.resource_text.text_content}
                        </marquee>
                    ) : (
                        m?.resource_text && (
                            <p className="w-full h-full" style={applyTextStyle(m.resource_text)}>
                                {m.resource_text.text_content}
                            </p>
                        )
                    ),
            )}
        </div>
    );

    const MediaWidgetElement = ({ element, item }) => {
        const getStyle = (resizeMode) => ({
            objectFit:
                resizeMode === 1
                    ? 'contain'
                    : resizeMode === 2
                    ? 'none'
                    : resizeMode === 3
                    ? 'none'
                    : resizeMode === 4
                    ? 'fill'
                    : 'cover',
            width: resizeMode === 2 ? '100%' : resizeMode === 3 ? 'auto' : '100%',
            height: resizeMode === 2 ? 'auto' : resizeMode === 3 ? '100%' : '100%',
        });
        const carouselRef = useRef(null);
        const videoRef = useRef(null);
        const [activeIndex, setActiveIndex] = useState(0);
        const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
        console.log('file: CampaignDetailDialog.jsx:92 => MediaWidgetElement => element:', element);
        console.log('file: CampaignDetailDialog.jsx:92 => CampaignDetailDialog => item:', item);

        const currentMedia = element?.detail_list?.resource_content;
        console.log('MediaWidgetElement => currentMedia:', currentMedia);

        // Enable auto play the video
        const handleSelect = (selectedIndex, e) => {
            console.log('==================================');
            console.log('进入了 handleSelect block！！！');
            console.log('file: MediaWidget.jsx:30 => handleSelect => selectedIndex:', selectedIndex);
            console.log('==================================');
            setActiveIndex(selectedIndex);
        };

        const handleVideoEnded = () => {
            console.log('==================================');
            console.log('进入了视频结束block！！！');
            console.log('==================================');
            // Check if the last video has ended before transitioning to the next item
            const nextIndex = currentVideoIndex + 1;
            if (nextIndex < item.resource_content.length) {
                const nextResource = item.resource_content[nextIndex];

                // Check if the next resource is a video
                if (nextResource.resource_type === 'video') {
                    console.log('进入了视频 next！！！');

                    carouselRef.current?.next();
                } else {
                    // If the next resource is an image, reset the video index and trigger the autoplay logic
                    // setCurrentVideoIndex(0);
                    setActiveIndex(nextIndex);
                }
            } else {
                // If the last video has ended, reset the index to 0
                setCurrentVideoIndex(0);
            }
        };

        useEffect(() => {
            console.log('==================================');
            console.log('进入了 activeIndex block！！！', activeIndex);
            console.log('useEffect => currentMedia:', currentMedia);
            console.log('==================================');
            // const activeMedia = currentTemplate?.find((x) => x.element_id === item.element_id);
            // console.log('useEffect => activeMedia:', activeMedia);
            const selectedResource = currentMedia[activeIndex];
            console.log('useEffect => selectedResource:', selectedResource);

            if (selectedResource) {
                if (selectedResource.resource_type === 'video') {
                    console.log('==================================');
                    console.log('进入了 activeIndex video');
                    console.log('==================================');

                    const videoElement = document.getElementById(
                        `video-${selectedResource.resource_id}-${activeIndex}`,
                    );
                    // const videoElement = videoRef.current;
                    if (videoElement) {
                        console.log('进入了video => videoElement:', videoElement);
                        videoElement.pause();
                        videoElement.currentTime = 0;
                        videoElement.play();
                        console.log('确保视频加载成功，并成功播放');
                    }
                }

                // Check if it's an image, and handle autoplay based on the resource type
                if (selectedResource.resource_type === 'image') {
                    console.log('==================================');
                    console.log('duration 执行了，时间戳开始：', Date.now());
                    console.log('==================================');
                    const duration = selectedResource.resource_duration * 1000;
                    console.log('useEffect => duration:', duration);
                    const intervalId = setInterval(() => {
                        carouselRef.current?.next();
                    }, duration);

                    return () => clearInterval(intervalId);
                }
            }
        }, [activeIndex]);

        return (
            <Carousel
                ref={carouselRef}
                onSelect={currentMedia.length > 1 ? handleSelect : undefined}
                activeIndex={activeIndex}
                className="absolute h-full w-full"
                indicators={false}
                controls={false}
                interval={null}
                fade={item?.transition_effect === 1}
                slide={item?.transition_effect !== 1}
            >
                {currentMedia?.map(
                    (m, index) =>
                        m.resource_id && (
                            <Carousel.Item
                                className="absolute h-full w-full flex items-center justify-center"
                                key={`${m.resource_id}-${index}`}
                                style={{
                                    transition: `transform ${item.effect_duration}s ease`,
                                }}
                            >
                                {m.resource_type === 'image' ? (
                                    <img
                                        className="absolute"
                                        style={getStyle(element?.detail_list?.resize_mode)}
                                        src={m.resource_url}
                                    />
                                ) : m.resource_type === 'video' ? (
                                    <video
                                        ref={videoRef}
                                        id={`video-${m.resource_id}-${index}`}
                                        loading="lazy"
                                        className="items-center"
                                        style={getStyle(element?.detail_list?.resize_mode)}
                                        controls
                                        muted // Add the muted attribute
                                        onEnded={handleVideoEnded}
                                        src={m.resource_url}
                                    />
                                ) : m.resource_type === 'audio' ? (
                                    <audio
                                        // ref={audioRef}
                                        id={`audio-${m.resource_id}-${index}`}
                                        loading="lazy"
                                        className="items-center"
                                        style={getStyle(element?.detail_list?.resize_mode)}
                                        controls
                                        // onEnded={handleAudioEnded}
                                        src={m.resource_url}
                                    />
                                ) : null}
                            </Carousel.Item>
                        ),
                )}
            </Carousel>
        );
    };

    const WebWidgetElement = ({ element }) => (
        <div className="w-full h-full">
            {[element?.detail_list?.resource_content.find((x) => x.resource_type === 'web')]?.map(
                (m, index) => (
                    // m?.resource_text && (
                    <div className="w-full h-full" key={`${m.resource_text}-${index}`}>
                        <iframe
                            src={m?.resource_website}
                            title="Embedded Page"
                            width="100%"
                            height="100%"
                        ></iframe>
                    </div>
                ),
                // ),
            )}
        </div>
    );

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            sx={{ zIndex: 9999 }}
            onClose={handleCloseCampaignDetail}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>Preview: {currentCampaign?.campaign_name}</DialogTitle>
            <DialogContent className="flex flex-col">
                <div className="flex w-full h-full justify-center mb-8">
                    {currentCampaign?.element_layout === 0 ? (
                        <Box
                            className="relative"
                            sx={{
                                margin: '0',
                                marginTop: '12px',
                                //1920x1080
                                width: 650,
                                height: 370,
                                backgroundColor: 'primary.white',
                                border: '5px solid gray',
                            }}
                        >
                            <div className="w-full h-full flex">
                                {currentTemplate?.map((element) =>
                                    currentCampaign?.detail_list?.map(
                                        (item, index) =>
                                            element.element_id &&
                                            item.element_id && (
                                                <div
                                                    // ref={currentRef}
                                                    key={`${element.element_id}-${index}`}
                                                    className="absolute border-dashed border-1 border-orange-600"
                                                    style={{
                                                        width: (element?.element_width / 100) * 640,
                                                        height: (element?.element_height / 100) * 360,
                                                        top: (element?.element_y / 100) * 360,
                                                        left: (element?.element_x / 100) * 640,
                                                    }}
                                                    onClick={() => item.content_type !== 'text'}
                                                >
                                                    {element.element_type === 'image' ||
                                                    element.element_type === 'video' ||
                                                    element.element_type === 'audio' ||
                                                    element.element_type === 'mix' ? (
                                                        <MediaWidgetElement element={element} item={item} />
                                                    ) : element.element_type === 'text' ? (
                                                        <TextWidgetElement element={element} />
                                                    ) : (
                                                        <WebWidgetElement element={element} />
                                                    )}
                                                </div>
                                            ),
                                    ),
                                )}
                            </div>
                        </Box>
                    ) : (
                        <Box
                            className="relative"
                            sx={{
                                // marginTop: '12px',
                                //1920x1080
                                width: 340,
                                height: 521,
                                backgroundColor: 'primary.white',
                                border: '5px solid gray',
                            }}
                        >
                            <div className="w-full h-full flex">
                                {currentTemplate?.map((element) =>
                                    currentCampaign?.detail_list?.map(
                                        (item, index) =>
                                            element.element_id &&
                                            item.element_id && (
                                                <div
                                                    // ref={currentRef}
                                                    key={`${element.element_id}-${index}`}
                                                    className="absolute border-dashed border-1 border-orange-600"
                                                    style={{
                                                        width: (element?.element_width / 100) * 330,
                                                        height: (element?.element_height / 100) * 511,
                                                        top: (element?.element_y / 100) * 511,
                                                        left: (element?.element_x / 100) * 330,
                                                    }}
                                                >
                                                    {element.element_type === 'image' ||
                                                    element.element_type === 'video' ||
                                                    element.element_type === 'mix' ? (
                                                        <MediaWidgetElement element={element} item={item} />
                                                    ) : element.element_type === 'text' ? (
                                                        <TextWidgetElement element={element} />
                                                    ) : (
                                                        <WebWidgetElement element={element} />
                                                    )}
                                                </div>
                                            ),
                                    ),
                                )}
                            </div>
                        </Box>
                    )}
                </div>
                <DialogActions>
                    <div className="flex flex-row w-full justify-between">
                        <div>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                color="error"
                                onClick={() => handleDeleteCampaign(currentCampaign)}
                            >
                                Delete Campaign
                            </Button>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <Button variant="contained" onClick={handleCloseCampaignDetail}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={() => handleEditCampaign(currentCampaign)}>
                                Edit
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default CampaignDetailDialog;
