import {
    FormControl,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
} from '@mui/material';
import React from 'react';

import { DenseInput } from '../utils';

const style = {
    labelText: {
        minWidth: '120px',
        display: 'flex',
        textAlign: 'center',
        fontSize: '14px',
    },
    label: {
        minWidth: '150px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
};
const EffectSection = ({ handleChangeEffect, chosenMedia, effectList, handleChangeEffectDuration }) => {
    console.log('effectList:', effectList);
    return (
        <>
            <Grid item xs={12} sm={6}>
                <FormControl
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <FormLabel id="transition_effect_name" sx={style.labelText}>
                        Transition effect
                    </FormLabel>
                    <DenseInput
                        sx={{ marginTop: '10px' }}
                        select
                        fullWidth
                        margin="normal"
                        name="transition_effect_name"
                        onChange={handleChangeEffect}
                        value={chosenMedia.transition_effect ? chosenMedia.transition_effect : 1}
                        variant="outlined"
                    >
                        {effectList?.map((x) => (
                            <MenuItem value={x.transition_effect} key={x.transition_effect_name}>
                                {x.transition_effect_name}
                            </MenuItem>
                        ))}
                    </DenseInput>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <FormLabel id="effect_duration" sx={style.labelText}>
                        Effect duration
                    </FormLabel>
                    <DenseInput
                        sx={{ marginTop: '10px' }}
                        fullWidth
                        margin="normal"
                        aria-labelledby="effect_duration"
                        name="effect_duration"
                        onChange={handleChangeEffectDuration}
                        value={chosenMedia.effect_duration ? chosenMedia.effect_duration : 2}
                        variant="outlined"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" component="div" disablePointerEvents>
                                    Sec
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
        </>
    );
};

export default EffectSection;
