import { Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ImClock, ImLoop2, ImPlay2 } from 'react-icons/im';
import React, { useState } from 'react';

import { RiArrowUpDownFill } from 'react-icons/ri';
import { TiDeleteOutline } from 'react-icons/ti';

const SelectedCampaignSec = ({
    handleDragEnd,
    campaignDivs,
    setCampaignDivs,
    campaigns,
    handleSelectedCampaignPreview,
    currentCampaign,
    handleAddCampaignDiv,
    handleRemoveDiv,
    layout,
}) => {
    console.log('layout:', layout);
    const [checkSecDuration, setCheckSecDuration] = useState(false);
    console.log(
        'campaignDivs?.filter((x) => x.element_layout === layout):',
        campaignDivs?.filter((x) => x.element_layout === layout),
    );
    return (
        <div className="col-span-1 border-1 border-blue-400 rounded">
            <div className="h-8 bg-blue-300 p-1 pl-2 text-center ">
                <p className="font-bold text-blue-700">Campaign</p>
            </div>
            <div className="pt-3 overflow-auto" style={{ maxHeight: '150px' }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="campaignDivs">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {campaignDivs?.map((campaign, index) => (
                                    <Draggable key={index} draggableId={`div-${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div
                                                    className="flex flex-row p-2 pb-1 mb-1 mx-1 hover:bg-blue-100/50 rounded-lg "
                                                    key={campaign.campaign_name}
                                                >
                                                    <div className="flex flex-row  max-h-10 justify-between align-middle">
                                                        {campaignDivs.length > 1 && (
                                                            <div className="flex self-center">
                                                                <Tooltip
                                                                    sx={{
                                                                        padding: '0',
                                                                    }}
                                                                    title="Move"
                                                                >
                                                                    <span>
                                                                        <IconButton>
                                                                            <RiArrowUpDownFill size={16} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        )}
                                                        <div className="flex self-center">
                                                            <Tooltip
                                                                sx={{
                                                                    padding: '0',
                                                                }}
                                                                title="Delete"
                                                            >
                                                                <span>
                                                                    <IconButton
                                                                        disabled={campaignDivs.length <= 1}
                                                                        onClick={() => handleRemoveDiv(index)}
                                                                    >
                                                                        <TiDeleteOutline
                                                                            size={16}
                                                                            color={
                                                                                campaignDivs.length <= 1
                                                                                    ? ''
                                                                                    : 'red'
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <TextField
                                                        sx={{
                                                            marginTop: '0',
                                                            marginRight: '4px',
                                                            marginLeft: '8px',
                                                        }}
                                                        // error={Boolean(formik.touched.select_campaign && formik.errors.select_campaign)}
                                                        fullWidth
                                                        select
                                                        // helperText={formik.touched.select_campaign && formik.errors.select_campaign}
                                                        label="Select Campaign"
                                                        margin="normal"
                                                        name={`select_campaign-${index}`}
                                                        size="small"
                                                        // onBlur={formik.handleBlur}
                                                        onChange={(event, divId) => {
                                                            const { value } = event.target;
                                                            console.log('value:', value);
                                                            const campaign = campaigns.find(
                                                                (x) => x.campaign_id === value,
                                                            );
                                                            const newCampaignDivs = [...campaignDivs];
                                                            newCampaignDivs[index].campaign_id =
                                                                parseInt(value);
                                                            newCampaignDivs[index].minute = Math.floor(
                                                                campaign.playback_duration / 60,
                                                            );

                                                            newCampaignDivs[index].second =
                                                                Math.floor(campaign.playback_duration % 60) >
                                                                0
                                                                    ? Math.floor(
                                                                          campaign.playback_duration % 60,
                                                                      )
                                                                    : 10;
                                                            console.log(
                                                                'file: ScheduleCampaignDialog.jsx:533 => newCampaignDivs:',
                                                                newCampaignDivs,
                                                            );

                                                            setCampaignDivs(newCampaignDivs);
                                                            handleSelectedCampaignPreview(value);
                                                        }}
                                                        value={campaign?.campaign_id}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                        {campaigns
                                                            ?.filter(
                                                                (x) =>
                                                                    x.element_layout ===
                                                                    (layout === 1 ? 0 : 1),
                                                            )
                                                            ?.map((option, index) => (
                                                                <MenuItem
                                                                    key={option.campaign_id}
                                                                    value={option.campaign_id}
                                                                >
                                                                    {option.campaign_name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    {campaignDivs.length > 1 && (
                                                        <div className="flex flex-row">
                                                            {campaign.option === 0 ? (
                                                                <div className="grid grid-cols-2 gap-2">
                                                                    <div>
                                                                        <TextField
                                                                            sx={{
                                                                                marginTop: '0',
                                                                            }}
                                                                            label="Min"
                                                                            margin="normal"
                                                                            name={`minute-${index}`}
                                                                            type="number"
                                                                            size="small"
                                                                            // onBlur={formik.handleBlur}
                                                                            onChange={(event) => {
                                                                                const newCampaignDivs = [
                                                                                    ...campaignDivs,
                                                                                ];
                                                                                newCampaignDivs[
                                                                                    index
                                                                                ].minute = parseInt(
                                                                                    event.target.value,
                                                                                );
                                                                                setCampaignDivs(
                                                                                    newCampaignDivs,
                                                                                );
                                                                            }}
                                                                            value={campaign.minute}
                                                                            variant="outlined"
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <TextField
                                                                            sx={{
                                                                                marginTop: '0',
                                                                            }}
                                                                            label="Sec"
                                                                            margin="normal"
                                                                            type="number"
                                                                            name="second"
                                                                            size="small"
                                                                            // onBlur={formik.handleBlur}
                                                                            onChange={(event) => {
                                                                                const newCampaignDivs = [
                                                                                    ...campaignDivs,
                                                                                ];
                                                                                newCampaignDivs[
                                                                                    index
                                                                                ].second = parseInt(
                                                                                    event.target.value,
                                                                                );
                                                                                setCampaignDivs(
                                                                                    newCampaignDivs,
                                                                                );
                                                                                if (
                                                                                    parseInt(
                                                                                        event.target.value,
                                                                                    ) === 0
                                                                                ) {
                                                                                    if (
                                                                                        parseInt(
                                                                                            newCampaignDivs[
                                                                                                index
                                                                                            ].minute,
                                                                                        ) === 0
                                                                                    ) {
                                                                                        setCheckSecDuration(
                                                                                            true,
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }}
                                                                            value={campaign.second}
                                                                            variant="outlined"
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            error={
                                                                                campaignDivs[index].minute ===
                                                                                    0 &&
                                                                                (campaignDivs[index].second <=
                                                                                    0 ||
                                                                                    !campaignDivs[index]
                                                                                        .second)
                                                                            }
                                                                            helperText={
                                                                                campaignDivs[index].minute ===
                                                                                    0 &&
                                                                                (campaignDivs[index].second <=
                                                                                    0 ||
                                                                                    !campaignDivs[index]
                                                                                        .second)
                                                                                    ? 'Second should greater than 0'
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <TextField
                                                                    sx={{
                                                                        marginTop: '0',
                                                                    }}
                                                                    label="Loop"
                                                                    margin="normal"
                                                                    type="number"
                                                                    name="loop"
                                                                    size="small"
                                                                    onChange={(event) => {
                                                                        const newCampaignDivs = [
                                                                            ...campaignDivs,
                                                                        ];
                                                                        newCampaignDivs[index].loop =
                                                                            parseInt(event.target.value);
                                                                        setCampaignDivs(newCampaignDivs);
                                                                    }}
                                                                    value={campaign.loop}
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}

                                                            <div className="flex ml-2 flex-row  max-h-10 justify-between align-middle">
                                                                <div className="flex self-center">
                                                                    <Tooltip
                                                                        sx={{
                                                                            padding: '0',
                                                                        }}
                                                                        title="Duration"
                                                                        onClick={(event) => {
                                                                            const newCampaignDivs = [
                                                                                ...campaignDivs,
                                                                            ];
                                                                            newCampaignDivs[index].option = 0;
                                                                            setCampaignDivs(newCampaignDivs);
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            <IconButton size="small">
                                                                                <ImClock size={14} />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="flex self-center">
                                                                    <Tooltip
                                                                        sx={{
                                                                            padding: '0',
                                                                        }}
                                                                        title="Loop"
                                                                        onClick={(event) => {
                                                                            const newCampaignDivs = [
                                                                                ...campaignDivs,
                                                                            ];
                                                                            newCampaignDivs[index].option = 1;
                                                                            setCampaignDivs(newCampaignDivs);
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            <IconButton size="small">
                                                                                <ImLoop2 size={14} />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="flex self-center">
                                                                    <Tooltip
                                                                        sx={{
                                                                            padding: '0',
                                                                        }}
                                                                        title="Preview"
                                                                        onClick={() =>
                                                                            handleSelectedCampaignPreview(
                                                                                campaign.campaign_id,
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>
                                                                            <IconButton
                                                                                size="small"
                                                                                disabled={
                                                                                    !campaignDivs[
                                                                                        campaignDivs.length -
                                                                                            1
                                                                                    ]?.campaign_id &&
                                                                                    campaignDivs[
                                                                                        campaignDivs.length -
                                                                                            1
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <ImPlay2
                                                                                    size={14}
                                                                                    style={{
                                                                                        color:
                                                                                            campaign?.campaign_id ===
                                                                                                currentCampaign?.campaign_id &&
                                                                                            '#0444AB',
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <div className="flex flex-row px-3 mb-3">
                    <Button className="w-full" variant="contained" onClick={handleAddCampaignDiv}>
                        Add Campaign
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SelectedCampaignSec;
