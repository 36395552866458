import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

// import SignUp from '../images/SignUp.png';
import IndustrySelect1 from '../images/IndustrySelect1.svg';
import IndustrySelect2 from '../images/IndustrySelect2.svg';
import IndustrySelect3 from '../images/IndustrySelect3.svg';
import IndustrySelect4 from '../images/IndustrySelect4.svg';
import IndustrySelect5 from '../images/IndustrySelect5.svg';
import IndustrySelect6 from '../images/IndustrySelect6.svg';
import IndustrySelect7 from '../images/IndustrySelect7.svg';
import IndustrySelect8 from '../images/IndustrySelect8.svg';
import { LoginComponent } from '../components/website';

const Activate = () => {
    const [setUp, setSetUp] = useState(false);

    return (
        <div className="h-screen w-full flex justify-between">
            <LoginComponent />
            <div className="md:w-2/3 h-screen shadow-2xl z-50">
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        minHeight: '100%',
                    }}
                >
                    {!setUp ? (
                        <Container maxWidth="sm">
                            <div className="p-16 ">
                                <Stack spacing={3}>
                                    <img
                                        src={
                                            'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+images/images/SignUp.png'
                                        }
                                        alt="logo"
                                        className="w-25 h-25 mx-auto"
                                    />
                                    <Typography
                                        className="text-center "
                                        sx={{ fontFamily: 'Inter', fontWeight: 800, fontSize: '42px' }}
                                    >
                                        Welcome aboard! 🚀{' '}
                                    </Typography>
                                    <Typography className="text-center " variant="body1">
                                        Your account is now activated and ready to roll!
                                    </Typography>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        style={{
                                            color: '#ffffff',
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            padding: '24px 32px',
                                        }}
                                        onClick={() => setSetUp(true)}
                                    >
                                        Set up Account
                                    </Button>
                                </Stack>
                            </div>
                        </Container>
                    ) : (
                        <Container maxWidth="md">
                            <div className="p-16">
                                <Stack spacing={3}>
                                    <Typography
                                        className="text-center "
                                        sx={{ fontFamily: 'Inter', fontWeight: 800, fontSize: '36px' }}
                                    >
                                        Let us get to know you better{' '}
                                    </Typography>
                                    <Typography className="text-center " variant="body1">
                                        Select your industry, and let us customise your Cybercast experience
                                        with you in mind!
                                    </Typography>
                                    <div className="flex flex-wrap justify-center">
                                        <img
                                            src={IndustrySelect1}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect2}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect3}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect4}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect5}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect6}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect7}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                        <img
                                            src={IndustrySelect8}
                                            alt="logo"
                                            className="w-40 cursor-pointer unhover:opacity-100 hover:opacity-80 hover:scale-110"
                                        />
                                    </div>
                                </Stack>
                            </div>
                        </Container>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default Activate;
