import * as Yup from 'yup';

import { AddLocation, EditLocation } from '../../../api/api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi';
import React, { useEffect, useState } from 'react';

import { FiMonitor } from 'react-icons/fi';
import { useNotification } from '../../../contexts/ContextProvider';

const layouts = [
    {
        value: 1,
        label: 'Landscape',
    },
    {
        value: 2,
        label: 'Portrait',
    },
];

const LocationDialog = ({
    setAddedLocation,
    currentUser,
    openLocation,
    handleCloseLocationDialog,
    unassignedDeviceList,
    addedLocation,
    text,
    setTriggerUpdate,
    triggerUpdate,
    locationDetail,
    setLocationDetail,
}) => {
    const notify = useNotification();

    // const locationDetail =
    // 	localStorage.getItem('locationDetail') !== 'undefined'
    // 		? JSON.parse(localStorage.getItem('locationDetail'))
    // 		: undefined;
    console.log('locationDetail:', locationDetail);

    const [assignedList, setAssignedList] = useState([]);
    const [unassignedList, setUnassignedList] = useState([]);
    console.log('file: LocationDialog.jsx:71 => unassignedList:', unassignedList);
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    useEffect(() => {
        setAssignedList(locationDetail ? locationDetail.assign_device_list : []);
        setUnassignedList(locationDetail ? locationDetail.no_assign_device_list : unassignedDeviceList);
    }, [locationDetail, unassignedDeviceList]);

    const formik = useFormik({
        initialValues: {
            name: locationDetail ? locationDetail.group_info?.group_name : '',
            target_layout: locationDetail ? locationDetail.group_info?.target_layout : '',
            // toggle: false,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().max(255).required('Name is required'),
            target_layout: Yup.number().max(255).required('Layout is required'),
        }),
        onSubmit: (values) => {
            console.log(values.name, values.target_layout);
            const id_list = assignedList.map((x) => x.device_id);
            console.log('id_list', id_list);
            console.log('unassignedList', unassignedList, 'assignedList', assignedList);
            if (text.toUpperCase() === 'ADD') {
                handleAddLocation(values.name, values.target_layout, id_list);
                formik.resetForm();
            }
            if (text.toUpperCase() === 'EDIT') {
                handleEditLocation(locationDetail.group_info.id, values.name, values.target_layout, id_list);
            }
        },
    });

    const handleAssigned = (item) => {
        setAssignedList([...assignedList, item]);
        setUnassignedList(unassignedList.filter((x) => x != item));
    };

    const handleUnassigned = (item) => {
        setAssignedList(assignedList.filter((x) => x != item));
        setUnassignedList([...unassignedList, item]);
    };

    const handleAddLocation = async (group_name, target_layout, device_id_list) => {
        const data = await AddLocation(undefined, group_name, target_layout, device_id_list, switchUser);

        handleCloseLocationDialog();
        if (data.code === 0) {
            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Location was added successfully!',
                },
            });
        } else {
            // handleCloseLocationDialog();
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Location was added unsuccessfully!',
                },
            });
        }
    };

    const handleEditLocation = async (group_id, group_name, target_layout, device_id_list) => {
        const data = await EditLocation(group_id, group_name, target_layout, device_id_list);
        console.log(data);
        if (data.code === 0) {
            setTriggerUpdate(!triggerUpdate);
            handleCloseLocationDialog();
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Location was updated successfully!!',
                },
            });
        } else {
            handleCloseLocationDialog();
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Location was updated unsuccessfully!',
                },
            });
        }
    };
    return (
        <div>
            <Dialog fullWidth={true} maxWidth="md" open={openLocation} onClose={handleCloseLocationDialog}>
                <DialogTitle>{text} Location</DialogTitle>
                <DialogContent className="flex flex-col p-6">
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label="Name"
                            margin="normal"
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                        {/* <FormLabel id="rotation" sx={style.labelText}>
								Rotation
							</FormLabel> */}
                        <TextField
                            sx={{ marginTop: '8px' }}
                            error={Boolean(formik.touched.target_layout && formik.errors.target_layout)}
                            select
                            fullWidth
                            label="Display Layout"
                            helperText={formik.touched.target_layout && formik.errors.target_layout}
                            margin="normal"
                            name="target_layout"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.target_layout}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        >
                            {layouts.map((option, index) => (
                                <MenuItem key={option.label} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* </FormControl> */}

                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                style={{ padding: '0', paddingRight: '28px', maxHeight: '400px' }}
                            >
                                <div
                                    className="h-full rounded"
                                    style={{ border: '1px solid #f5f5f5', overflow: 'auto' }}
                                >
                                    <Stack className="p-2 font-semibold ml-4 my-4">
                                        <span className="font-semibold">Assigned Devices </span>
                                    </Stack>
                                    <hr />
                                    <Stack className="p-2">
                                        {assignedList?.length > 0 &&
                                            assignedList?.map((item, index) => (
                                                <ListItem
                                                    key={item?.device_name}
                                                    divider
                                                    onClick={() => handleUnassigned(item)}
                                                    secondaryAction={
                                                        <div className="flex flex-row">
                                                            <div>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={handleAssigned}
                                                                >
                                                                    <HiOutlineLockClosed color="black" />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <ListItemIcon
                                                        style={{ minWidth: '24px', marginRight: '15px' }}
                                                    >
                                                        <FiMonitor
                                                            style={{
                                                                color:
                                                                    item?.device_status === 'OFFLINE'
                                                                        ? 'red'
                                                                        : 'green',
                                                                fontSize: '20px',
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <div>
                                                        <p className="m-0 max-w-[122px] overflow-hidden">
                                                            {item?.device_name}
                                                            <br />
                                                            <span className="text-[10px] text-black-500  rounded pb-1 ">
                                                                <span
                                                                    className={`${
                                                                        item?.device_status === 'OFFLINE'
                                                                            ? 'text-red-500'
                                                                            : 'text-green-500'
                                                                    } text-sx font-bold`}
                                                                >
                                                                    {item?.device_status}
                                                                </span>
                                                                {item?.device_status === 'OFFLINE' &&
                                                                    ` Last online: ${item?.last_online}`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </ListItem>
                                            ))}
                                    </Stack>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{ padding: '0', paddingLeft: '14px', minHeight: '400px' }}
                            >
                                <div
                                    className="h-full"
                                    style={{ border: '1px solid #f5f5f5', overflow: 'auto' }}
                                >
                                    <Stack className="p-2 font-semibold ml-4 my-4">
                                        <span className="font-semibold">Unassigned Devices </span>
                                    </Stack>
                                    <hr />

                                    <Stack className="p-2">
                                        {unassignedList?.length > 0 &&
                                            unassignedList?.map((item, index) => (
                                                <ListItem
                                                    key={item.device_name}
                                                    divider
                                                    secondaryAction={
                                                        <div className="flex flex-row">
                                                            <IconButton size="small">
                                                                <HiOutlineLockOpen color="black" />
                                                            </IconButton>
                                                        </div>
                                                    }
                                                    onClick={() => handleAssigned(item)}
                                                >
                                                    <ListItemIcon
                                                        style={{ minWidth: '24px', marginRight: '15px' }}
                                                    >
                                                        <FiMonitor
                                                            style={{
                                                                color:
                                                                    item?.device_status === 'OFFLINE'
                                                                        ? 'red'
                                                                        : 'green',
                                                                fontSize: '20px',
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <div>
                                                        <p className="m-0 max-w-[122px]">
                                                            {item?.device_name}
                                                            <br />
                                                            <span className="text-[10px] text-black-500 rounded pb-1 ">
                                                                <span
                                                                    className={`${
                                                                        item?.device_status === 'OFFLINE'
                                                                            ? 'text-red-500'
                                                                            : 'text-green-500'
                                                                    } text-sx font-bold`}
                                                                >
                                                                    {item?.device_status}
                                                                </span>
                                                                {item?.device_status === 'OFFLINE' &&
                                                                    ` Last online: ${item?.last_online}`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </ListItem>
                                            ))}
                                    </Stack>
                                </div>
                            </Grid>
                        </Grid>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button sx={{ color: 'black' }} onClick={handleCloseLocationDialog}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit">
                                {text.toUpperCase() === 'EDIT' ? 'Save' : 'Add'}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LocationDialog;
