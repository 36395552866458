import {
    Badge,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';

import { GetDeviceLocation } from '../../../api/api';
import config from '../../../config/config';

const containerStyle = {
    width: '400px',
    height: '400px',
};
// -37.98731814528472, 145.11490926552497
const center = {
    lat: -37.98731814528472,
    lng: 145.11490926552497,
};
const MapDialog = ({ openMapDialog, handleCloseMapDialog, switchUser, currentUser }) => {
    const [locationList, setLocationList] = useState([]);
    console.log('file: MapDialog.jsx:30 => locationList:', locationList);
    const [noPermissionList, setNoPermissionList] = useState([]);
    const [mapCenter, setMapCenter] = useState();
    const [currentCenter, setCurrentCenter] = useState();

    const handleGetLocationList = async () => {
        const { data } = await GetDeviceLocation(switchUser);
        console.log('file: MapDialog.jsx:22 => handleGetLocationList => list:', data);
        // Sort by location_permission
        const sortedLocations = data.sort((a, b) => {
            // 如果 a 的 location_permission 是 1，而 b 的不是，则将 a 放在前面
            if (a.location_permission === 1 && b.location_permission !== 1) {
                return -1;
            }
            // 如果 b 的 location_permission 是 1，而 a 的不是，则将 b 放在前面
            if (b.location_permission === 1 && a.location_permission !== 1) {
                return 1;
            }
            // 否则保持原顺序
            return 0;
        });
        // const permissionList = data.filter((x) => x.location_permission === 1);
        setMapCenter(
            parseFloat(sortedLocations[0]?.latitude)
                ? {
                      lat: parseFloat(sortedLocations[0].latitude),
                      lng: parseFloat(sortedLocations[0].longitude),
                  }
                : center,
        );
        setCurrentCenter(sortedLocations[0].device_id);
        const noLocationPermissionList = data.filter((x) => x.location_permission === 0);
        setLocationList(
            sortedLocations.filter(
                (x) => x.location_permission === 1 || x.location_permission === 0,
            ),
        );
        setNoPermissionList(noLocationPermissionList);
    };

    useEffect(() => {
        handleGetLocationList();
    }, []);
    // MAP
    const api_key = config[process.env.REACT_APP_ENV].REACT_APP_GOOGLE_MAPS_API_KEY;
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: api_key,
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        if (mapCenter) {
            const bounds = new window.google.maps.LatLngBounds(mapCenter);
            map.fitBounds(bounds);

            setMap(map);
        }
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const handleSelectCenter = (center) => {
        console.log('file: MapDialog.jsx:100 => handleSelectCenter => e:', center);

        if (center.location_permission === 1) {
            setMapCenter({
                lat: parseFloat(center.latitude),
                lng: parseFloat(center.longitude),
            });
            setCurrentCenter(center.device_id);
        }
    };

    return (
        <div>
            {mapCenter && (
                <Dialog open={openMapDialog} onClose={handleCloseMapDialog} maxWidth="md">
                    <DialogTitle>Map</DialogTitle>
                    <DialogContent className="flex flex-row p-6" sx={{ maxHeight: '400px' }}>
                        <div>
                            {isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={mapCenter}
                                    zoom={10}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    {/* Child components, such as markers, info windows, etc. */}
                                    {locationList.map((location) => {
                                        return (
                                            <Marker
                                                key={location.device_id}
                                                position={{
                                                    lat: parseFloat(location.latitude),
                                                    lng: parseFloat(location.longitude),
                                                }}
                                                title={`Device: ` + location.device_name}
                                            />
                                        );
                                    })}
                                </GoogleMap>
                            )}
                        </div>
                        <div className="overflow-y-scroll px-2">
                            <Table className=" border-1 ">
                                <TableHead>
                                    <TableRow className="border-1 bg-slate-100">
                                        <TableCell>Device name</TableCell>
                                        <TableCell>Permission</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locationList.map((item) => (
                                        <TableRow
                                            key={item.device_id}
                                            className={`${
                                                currentCenter === item.device_id &&
                                                item.location_permission === 1
                                                    ? 'bg-blue-400'
                                                    : ''
                                            } flex flex-row border-1 ${
                                                item.location_permission === 1 && 'cursor-pointer'
                                            }`}
                                            onClick={() => handleSelectCenter(item)}
                                        >
                                            <TableCell>{item.device_name}</TableCell>
                                            <TableCell>
                                                {item.location_permission === 0 ? (
                                                    <div className="flex flex-row align-middle">
                                                        <div className="pr-1">
                                                            <Badge
                                                                color="error"
                                                                // sx={{
                                                                // 	color: '#FF0000',
                                                                // }}
                                                                variant="dot"
                                                                badgeContent=" "
                                                            ></Badge>
                                                        </div>
                                                        <p className="pl-1">Disabled</p>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-row align-middle">
                                                        <div className="pr-1">
                                                            <Badge
                                                                color="success"
                                                                variant="dot"
                                                            ></Badge>
                                                        </div>
                                                        <p className="pl-1">Enabled</p>
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

export default MapDialog;
